<div class="ui-modal-overlay"
     [style.zIndex]="zIndex"
     [ngStyle]="{'display': (visible && backdrop) ? 'block' : 'none'}">
</div>

<div class="ui-modal" tabindex="-1" role="dialog"
     #modalRoot
     [ngStyle]="{'display': visible ? 'block' : 'none'}"
     [style.width.px]="width"
     [style.minWidth.px]="minWidth"
     [style.zIndex]="contentzIndex"
     (mousedown)="moveOnTop()"
     (touchstart)="moveOnTop()">
    <div class="ui-modal-header" #modalHeader
         (mousedown)="initDrag($event.pageX, $event.pageY)"
         (touchstart)="initDrag($event.touches[0].pageX, $event.touches[0].pageY)">
      <div class="ui-titlebar">
          <span class="ui-modal-title" [ngClass]="closeSymbol ? 'p-2' : ''" *ngIf="modalTitle">{{modalTitle}}</span> 
          <ng-content select=".app-modal-header"></ng-content>
      </div>
      <div class="ui-controlbar" *ngIf="!closeSymbol">
          <!-- <span *ngIf="maximizable" (click)="toggleMaximize($event)">
              <svg *ngIf="!maximized" class="ui-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 28 32">
                <path fill="currentColor" d="M27.55 3.9h-22.6c-0.55 0-1 0.45-1 1v22.3c0 0.55 0.45 1 1 1h22.55c0.55 0 1-0.45 1-1v-22.3c0.050-0.55-0.4-1-0.95-1zM5.95 26.15v-18h20.55v18h-20.55z"></path>
              </svg>
              <svg *ngIf="maximized" class="ui-icon" version="1.1" xmlns="http://www.w3.org/2000/svg" width="28" height="32" viewBox="0 0 28 32">
                <path fill="currentColor" d="M27.9 3.75h-18.8c-0.4 0-0.75 0.35-0.75 0.75v4.3c0 0.1 0 0.2 0.050 0.3h-4.2c-0.55 0-1 0.45-1 1v17.4c0 0.55 0.45 1 1 1h17.65c0.55 0 1-0.45 1-1v-3.7c0.050 0 0.1 0.050 0.2 0.050h4.9c0.4 0 0.75-0.35 0.75-0.75v-18.6c-0.050-0.4-0.4-0.75-0.8-0.75zM5.2 26.5v-12.95c0.050 0 0.1 0 0.15 0h15.4c0.050 0 0.1 0 0.15 0v12.95h-15.7zM27.15 22.35h-4.15c-0.050 0-0.15 0-0.2 0.050v-12.3c0-0.55-0.45-1-1-1h-12c0.050-0.1 0.050-0.2 0.050-0.3v-3.55h17.3v17.1z"></path>
              </svg>
          </span> -->
          <span (click)="hide(true)"
                (mousedown)="onCloseIcon($event)"
                (touchstart)="onCloseIcon($event)">
            <svg class="ui-icon zoom" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32">
              <path fill="currentColor" d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M16.9,15.5l-1.4,1.4L12,13.4l-3.5,3.5   l-1.4-1.4l3.5-3.5L7.1,8.5l1.4-1.4l3.5,3.5l3.5-3.5l1.4,1.4L13.4,12L16.9,15.5z"></path>
            </svg>
          </span>
      </div>
    </div>

    <div class="ui-modal-body" #modalBody>
      <ng-content select=".app-modal-body"></ng-content>
    </div>
    <div class="ui-modal-footer" #modalFooter>
      <ng-content select=".app-modal-footer"></ng-content>
    </div>

  <div class="ui-resizable-s"
       *ngIf="!maximized"
       [style.zIndex]="contentzIndex"
       (mousedown)="initResizeS($event.pageX, $event.pageY)"
       (touchstart)="initResizeS($event.touches[0].pageX, $event.touches[0].pageY)"></div>
  <div class="ui-resizable-e"
       *ngIf="!maximized"
       [style.zIndex]="contentzIndex"
       (mousedown)="initResizeE($event.pageX, $event.pageY)"
       (touchstart)="initResizeE($event.touches[0].pageX, $event.touches[0].pageY)"></div>
  <div class="ui-resizable-se"
       *ngIf="!maximized"
       [style.zIndex]="contentzIndex"
       (mousedown)="initResizeSE($event.pageX, $event.pageY)"
       (touchstart)="initResizeSE($event.touches[0].pageX, $event.touches[0].pageY)"></div>
</div>