import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseService } from 'src/app/services/base.service';
declare var MapLabel: any;
import '../../../../src/js/maplabel.js';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/services/loader.service';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { AgmInfoWindow } from '@agm/core/directives/info-window';

const datePipe = new DatePipe('en-US');
@Component({
  selector: 'app-sim-track-common',
  templateUrl: './sim-track-common.component.html',
  styleUrls: ['./sim-track-common.component.scss']
})
export class SimTrackCommonComponent implements OnInit {

  @ViewChild('mapDiv', { static: true }) gmapElement: ElementRef;
  bounds: any = new google.maps.LatLngBounds();

  @Output('clickmethod') parenttochild = new EventEmitter();
  @Output('clickmethodHistory') backtoCargoTrips = new EventEmitter();
  @Input('simData') simData: any;
  @Input('mobileNumber') mobileNumber: any;
  @Input('vehicleNo') vehicleNo: any;
  @Input('mapIcon') mapIcon: any;
  @Input('vehicleName') vehicleName: any;
  @Input('isHistotyData') isHistotyData: any;

  //for map 
  map: google.maps.Map;
  source: any;
  font: any;

  ticks = 0;
  private timer;
  Time: Number;
  private sub: Subscription;
  markerslist: any = [];
  simObject: any = {};
  sliderShow: boolean = true;
  intervalId: any;
  selectedTimer: any = '900';
  directionsService = new google.maps.DirectionsService();
  simMarkersList: any = [];
  tripStartDate: Date;
  tripEndDate: Date;
  epochStartDate: any;
  epochEndDate: any;
  todayDate: any;
  DateRange: any;
  isbuttonDisable: boolean = false;
  isFormSubmitted: boolean = false;
  currentIW: AgmInfoWindow;
  previousIW: AgmInfoWindow;
  constructor(
    private baseService: BaseService,
    private toaster: ToastrService,
    private loaderService: LoaderService,
    private datepipe: DatePipe
  ) {
    this.currentIW = null;
    this.previousIW = null;
  }
  originIcon: any = {
    url: './assets/images/origin.svg',
    scaledSize: {
      width: 35,
      height: 35
    }
  };

  destIcon: any = {
    url: './assets/images/destination.svg',
    scaledSize: {
      width: 35,
      height: 35
    }
  };

  waypointIcon: any = {
    url: './assets/images/pause.svg',//'./assets/images/waypoints.svg'
    scaledSize: {
      width: 35,
      height: 35
    }
  }

  direction: any = {
    origin: {
      lat: null,
      lng: null
    },
    destination: {
      lat: null,
      lng: null
    }
  };

  public renderOptions = {
    suppressMarkers: true,
  }
  waypoints: any = [];
  widthBuffer: any;//number = 1000; //m
  listenChanges: boolean = true;
  visible = false;
  directionBuffer: any = [];
  tripStart: any;
  tripEnd: any;
  directionsDisplay: any;
  markerslistCargo: any = [];
  polylineData: any;
  globalZoomValue: any = 5;
  polyLineArray: any = [];
  markersArray: any = [];
  latLangs: any = []; // Route dash polyline
  // isgeoreversecoding: boolean = false;
  isgeoreversecoding_latlng: boolean = false;
  // isgeoreversecoding_address: boolean = false;
  // isgeoreversecoding_placeId: boolean = false;
  onMapReady(map: any) {
    this.map = map;
  }

  ngOnInit(): void {
    let data = {
      "type": this.baseService.lookTypeIds.loc_enable,  //PortableKey Box Models lookup keys
      "userid": sessionStorage.getItem('LOGIN_USER_ID')
    }
    this.baseService.doRequest('fleetmaster/common/gettype', 'post', data).subscribe((result: any) => {
      if (result?.status == 200) {
        if (result?.data.length > 0) {
          if (result?.data[0]?.lvdata.length > 0) {

            // this.isgeoreversecoding = this.baseService.booleanify(result?.data[0].lvdata[0].lkpval);
            this.isgeoreversecoding_latlng = this.baseService.booleanify(result?.data[0].lvdata[1].lkpval);
            // this.isgeoreversecoding_address = this.baseService.booleanify(result?.data[0].lvdata[2].lkpval);
            // this.isgeoreversecoding_placeId = this.baseService.booleanify(result?.data[0].lvdata[3].lkpval);
            console.log("value is : " + this.baseService.booleanify(result?.data[0].lvdata[0].lkpval));
          }
        }
      }

    });
    this.simObject = {};
    if (this.isHistotyData) {
      this.mobileNumber = this.simData.driver.mno;
    }
    this.timerCallData();
  }

  /** Timer call default 15 minutes */
  timerCallData() {
    try {
      this.displaySimMap(this.simData);
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.intervalId = undefined;
      if (this.selectedTimer !== undefined && Number(this.selectedTimer) !== 0) {
        this.intervalId = setInterval(() => {
          this.displaySimMap(this.simData);
        }, Number(this.selectedTimer) * 1000);
      }
    }
    catch (ex) {
      //console.log(ex.message)
    }
  }
  markerClick(infoWindow) {
    if (this.previousIW) {
      this.currentIW = infoWindow;
      this.previousIW.close();
    }
    this.previousIW = infoWindow;
  }

  /** display points on map */
  displayMap(arraylist: any) {
    if (this.simData.type == "03") {
      setTimeout(() => {
        let lat = arraylist[0].lat;
        let lng = arraylist[0].lng;
        let cenLatLng = new google.maps.LatLng(Number(lat), Number(lng))
        this.map.setCenter(cenLatLng);
        this.addMarkersToMapCargo(arraylist);
      }, 1000);
    } else {

      let mrList = [];
      let latlng1 = [];
      arraylist.forEach(element => {

        if (element.lat && element.lng) {
          let latlng = { id: element.seqno, lat: Number(element.lat), lng: Number(element.lng) };
          latlng1.push(latlng); // for dotline display
          this.latLangs = latlng1;
          // The below Code for to bind Vehicle as marker on the map //element.location + " , " +
          let stringObj: string = "# " + element.seqno
            + "\n" + element.drivername + " - " + element.drivermobile
            + "\n" + element.simtrackdatetime
            + "\n" + element.lat + " , " + element.lng;
          try {
            mrList.push({
              id: element.seqno,
              lat: parseFloat(element.lat),
              lng: parseFloat(element.lng),
              //loc: stringObj,
              iconUrl: "./assets/images/map-pin.svg",
              type: "marker",
              dName: element.drivername + " - " + element.drivermobile,
              time: element.simtrackdatetime,//datePipe.transform(element.simtrackdatetime, 'dd/MM/yyyy hh:mm:ss a'),
              latlng: element.lat + " , " + element.lng
            });
          } catch (e) {
            e = null;
          }
        }
      });

      this.markersArray = [...this.waypointMarker, ...this.originDestinationArray, ...mrList];
    }

  }

  /** display markers on map */
  addMarkersToMapCargo(arrayList: any) {

    this.markerslistCargo = [];
    this.polyLineArray = [];
    if (this.markersArray) {
      try {
        if (this.markersArray.length > 0) {
          for (var i = 0; i < this.markersArray.length; i++) {
            this.markersArray[i].setMap(null);
          }
        }
      } catch (e) {
        e = null;
      }
      this.markersArray.length = 0;
    }

    this.markersArray = [];
    let latLangs1 = [];
    let bounds = new google.maps.LatLngBounds();
    arrayList.forEach(element => {
      // this.markersArray = [];
      if (element.lat && element.lng) {
        if (element.lat != null && element.lng != null) {
          let latlng = new google.maps.LatLng(Number(element.lat), Number(element.lng))
          element.vlLatLng = latlng;
          latLangs1.push(latlng); // for dotline display
          // element.location = element.location ? element.location : "";
          if (arrayList.length > 1) {
            var point = new google.maps.LatLng(Number(element.lat), Number(element.lng))
            this.map.panTo(point);
          }

          var icon = {
            url: "./assets/images/map-pin.svg",
            scaledSize: new google.maps.Size(35, 35)
          };
          let strdata = '<div  id="iw-container">' +
            '<div class="iw-title">' + element.drivername + " - " + element.drivermobile + '</div>' +
            '<div class="iw-content">' +
            '<table><tr><td>' + '<img src="./assets/images/info-window/timer.svg" width="20"> ' + '</td><td>:</td>' +
            '<td>' + element.simtrackdatetime + '</td></tr>' +
            '<tr><td>' + '<img src="./assets/images/info-window/location.svg" width="20">' + '</td><td>:</td>' +
            '<td>' + element.lat + ',' + element.lng + '</td></tr>' +
            '</table>' +
            '</div>' +
            '<div class="iw-bottom-gradient" style="display:none"></div>' +
            '</div>';

          // The below Code for to bind Vehicle as marker on the map //element.location + " , " +
          let stringObj: string = element.drivername + " - " + element.drivermobile
            + "\n" + element.simtrackdatetime
            + "\n" + element.lat + " , " + element.lng;

          let marker = new google.maps.Marker({
            position: latlng,
            map: this.map,
            icon: icon,
            //title: stringObj,
            zIndex: 3
          });
          var lineSymbol = {
            path: 'M 0,-1 0,1', //dashline
            //path: google.maps.SymbolPath.CIRCLE,
            strokeColor: "#FF0000",
            strokeWeight: 5,
            strokeOpacity: 0.9,
            scale: 2,
            // fillOpacity: 1,
            // fillColor: "#669df6",
          };

          this.attachMarkerTrips(marker, strdata);
          this.markersArray.push(marker);
          bounds.extend(marker.getPosition());
          this.map.setZoom(12);
          if (latLangs1.length >= 2) {
            let latLangs2 = [latLangs1[latLangs1.length - 2], latLangs1[latLangs1.length - 1]]
            let line = new google.maps.Polyline({
              path: latLangs2,
              strokeOpacity: 0,
              icons: [{
                icon: lineSymbol,
                offset: '0',
                repeat: '20px'
              }],
              map: this.map,
            });
          }
          let font = this.ReturnFontSize();
          let mapLabel = new MapLabel({
            text: element.seqno,
            position: latlng,
            fontSize: font,
            strokeWeight: 5,
            strokeColor: '#000000',
            fontColor: '#FFFFFF',
            align: 'left',
            map: this.map
          });
        }
      }
    });
  }

  ReturnFontSize() {
    var zoom = this.map.getZoom();
    if (zoom <= 5) {
      this.font = 12;
    } else if (zoom > 5 && zoom <= 10) {
      this.font = 15;
    } else if (zoom > 10 && zoom <= 15) {
      this.font = 18;
    } else {
      this.font = 22;
    }
    return this.font;
  }

  backmethod() {
    this.ngOnDestroy();
    this.parenttochild.emit(true);
  }

  backmethodCargo() {
    this.ngOnDestroy();
    this.backtoCargoTrips.emit(true);
  }

  ngOnDestroy() {
    this.toaster.clear();
    this.clearMap();
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  }

  waypointMarker: any = [];
  originDestinationArray: any = [];
  /** Simtracking ongoing conditions check */
  displaySimMap(list: any) {
    try {
      if (this.simData.type == "03") {
        this.loadMap();

      } else {
        this.visible = false;
        this.waypoints = [];
        this.listenChanges = true;
        this.visible = true;

        this.simData.route.waypoints.forEach(element => {

          this.waypoints.push({
            location: {
              lat: parseFloat(element.lat),
              lng: parseFloat(element.lng)
            },
            stopover: false
          })
          if (element.id != '') {
            this.waypointMarker.push({
              id: element.id,
              lat: parseFloat(element.lat),
              lng: parseFloat(element.lng),
              loc: element.name,
              iconUrl: this.waypointIcon,
              type: "waypoint"
            })
          }
        });

        this.direction = {
          origin: {
            lat: parseFloat(this.simData.source.lat),
            lng: parseFloat(this.simData.source.lon)
          },
          destination: {
            lat: parseFloat(this.simData.destination.lat),
            lng: parseFloat(this.simData.destination.lon)
          }
        };

        this.widthBuffer = this.simData.route.buffer_radius == null ? 0 : parseInt(this.simData.route.buffer_radius);
        this.directionBuffer = this.simData.route.buffer_points;

        this.originDestinationArray = [{
          id: "",
          lat: parseFloat(this.simData.source.lat),
          lng: parseFloat(this.simData.source.lon),
          loc: this.simData.source.addr,
          iconUrl: this.originIcon,
          type: "origin"

        }, {
          id: "",
          lat: parseFloat(this.simData.destination.lat),
          lng: parseFloat(this.simData.destination.lon),
          loc: this.simData.destination.addr,
          iconUrl: this.destIcon,
          type: "destination"
        }]

      }

      if (list.tripstarttime) {
        let start = new Date(list.tripstarttime);
        start.setHours(start.getHours() + 5);
        start.setMinutes(start.getMinutes() + 30);

        let end = new Date();
        end = moment(end).add(330, "minutes").toDate();
        this.epochStartDate = start.valueOf();
        this.epochEndDate = end.valueOf();
        this.mobileNumber = this.simData.driver.mno;

        if (start > end) {
          this.loaderService.display(false);
          return false;
        }
        else {
          this.simTrackOngoingHistory(this.mobileNumber, this.epochStartDate, this.epochEndDate)
        }
      }
    }
    catch (ex) {
      //console.log(ex.message)
    }
  }

  loadMap() {
    let latitude = sessionStorage.getItem("DEFAULT_LATITUDE");
    let longitude = sessionStorage.getItem("DEFAULT_LONGITUDE");
    let centerLatLng = new google.maps.LatLng(parseFloat(latitude), parseFloat(longitude));
    let mapProp = {
      center: centerLatLng,
      zoom: 15,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      streetViewControl: false
    };

    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapProp);

    if (this.simData.source.lat && this.simData.source.lon) {

      let latlng = new google.maps.LatLng(Number(this.simData.source.lat), Number(this.simData.source.lon));

      let marker = new google.maps.Marker({
        position: latlng,
        map: this.map,
        title: this.simData.source.addr,
        icon: {
          url: './assets/images/origin.svg',
          scaledSize: new google.maps.Size(35, 35)
        },
        zIndex: 3
      });
    }
    if (this.simData.destination.lat && this.simData.destination.lon) {
      let latlng = new google.maps.LatLng(Number(this.simData.destination.lat), Number(this.simData.destination.lon));

      let marker = new google.maps.Marker({
        position: latlng,
        map: this.map,
        title: this.simData.destination.addr,
        icon: {
          url: './assets/images/destination.svg',
          scaledSize: new google.maps.Size(35, 35)
        },
        zIndex: 3
      });

    }


    let wayPobj: any = [];
    this.simData.waypoints.forEach(latlng => {
      wayPobj.push({ stopover: true, location: new google.maps.LatLng(latlng.lat, latlng.lng) })
    });
    let orgin: any;
    let destination: any;
    if (this.simData) {
      orgin = (this.simData.source.lat && this.simData.source.lon) ? new google.maps.LatLng(Number(this.simData.source.lat), Number(this.simData.source.lon)) : "";
      destination = (this.simData.destination.lat && this.simData.destination.lon) ? new google.maps.LatLng(Number(this.simData.destination.lat), Number(this.simData.destination.lon)) : "";
    }
    var request = {
      origin: orgin ? orgin : this.simData.source.addr,
      destination: destination ? destination : this.simData.destination.addr,
      waypoints: wayPobj,
      travelMode: google.maps.TravelMode.DRIVING,
      provideRouteAlternatives: false
    };
    var that = this;
    that.directionsDisplay = new google.maps.DirectionsRenderer(
      {
        polylineOptions: {
          strokeColor: "#669df6",
          strokeWeight: 6,
          strokeOpacity: 0.9
        }
      }
    );
    that.directionsDisplay.setOptions({ suppressMarkers: true });
    that.directionsDisplay.setMap(this.map);
    that.directionsService.route(request, function (result, status) {
      if (status == google.maps.DirectionsStatus.OK) {
        that.directionsDisplay.setDirections(result);
      }
    });

  }

  /** SimTrack Ongoing History API call*/
  simTrackOngoingHistory(mobNum: any, startDate: any, Enddate: any) {
    const data = {
      drivermobile: mobNum,
      fromdate: startDate,
      todate: Enddate,
    }
    let result;
    this.baseService.doRequest('Tracker/v1/sim-ongoing-history', 'post', data).subscribe(async (res) => {
      result = res;
      if (result.status == 200) {
        if (result.data.length > 0) {
          result.data.sort(function (a, b) { return Number(a.seqno) - Number(b.seqno) });
          this.simObject = result.data[result.data.length - 1];
          // result.data.forEach(async element => {
          //   element.location = ""
          //   if (element.lat && element.lng) {
          //     await this.getLocation(element);
          //   }
          // });
          this.simMarkersList = result.data;
          this.displayMap(this.simMarkersList);
        }
      }
      else {
        if (this.simData.type == "04") {
          this.markersArray = [...this.waypointMarker, ...this.originDestinationArray];
        }
        this.toaster.warning(result.message);
        //console.log("ERROR :" + result.message);
      }
    }, error => {
      if (result.status == 400) {
        //console.log("ERROR :" + result.message);
        // this.toaster.warning(result.message);
      }
      this.loaderService.display(false);
    });
  }

  getLocation(obj) {
    return new Promise(function (resolve, reject) {
      if (obj.lat && obj.lng) {
        if(this.isgeoreversecoding_latlng){
        var latLangs = new google.maps.LatLng(Number(obj.lat), Number(obj.lng));
        var geoCoder = geoCoder = new google.maps.Geocoder();
        geoCoder.geocode({ 'latLng': latLangs }, function (results, status) {

          if (status == google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              resolve(obj.location = results[0].formatted_address);
            }
          }
        });
      }
      } else {
        resolve(0);
      }
    });
  }
  closeinfowindow() {
    if (this.infowindowarr.length > 0) {
      for (var j = 0; j < this.infowindowarr.length; j++) {
        this.infowindowarr[j].open(null);
      }
      this.infowindowarr.length = 0;
    }
  }

  infowindowarr = [];
  attachMarkerTrips(marker, indx) {
    var that = this;
    let infowindow = new google.maps.InfoWindow(
      {
        content: indx
      });
    google.maps.event.addListener(marker, 'click', function () {
      that.closeinfowindow();
      infowindow.open(this.map, marker);
      that.infowindowarr.push(infowindow);
    });

    google.maps.event.addListener(infowindow, 'domready', function () {

      var iwOuter = $('.gm-style-iw');
      var iwBackground = iwOuter.prev();

      // Removes background shadow DIV
      iwBackground.children(':nth-child(2)').css({ 'display': 'none' });

      // Removes white background DIV
      iwBackground.children(':nth-child(4)').css({ 'display': 'none' });

      // Moves the infowindow 115px to the right.
      iwOuter.parent().parent().css({ left: '0px' });

      // Moves the shadow of the arrow 76px to the left margin.
      iwBackground.children(':nth-child(1)').attr('style', function (i, s) { return s + 'left: 126px !important;' });

      // Moves the arrow 76px to the left margin.
      iwBackground.children(':nth-child(3)').attr('style', function (i, s) { return s + 'left: 126px !important;' });

      // Changes the desired tail shadow color.
      iwBackground.children(':nth-child(3)').find('div').children().css({ 'box-shadow': 'rgba(72, 181, 233, 0.6) 0px 1px 6px', 'z-index': '1' });


      // If the content of infowindow not exceed the set maximum height, then the gradient is removed.
      if ($('.iw-content').height() < 140) {
        $('.iw-bottom-gradient').css({ display: 'none' });
      }

      var iwCloseBtn = iwOuter.next();

      // Apply the desired effect to the close button
      iwCloseBtn.css({
        opacity: '1', // by default the close button has an opacity of 0.7
        right: '45px', top: '3px', // button repositioning
        border: '2px solid #48b5e9', // increasing button border and new color
        'border-radius': '13px', // circular effect
        'box-shadow': '0 0 5px #3990B9', // 3D effect to highlight the button
        width: '17px',
        height: '17px'
      });

      // The API automatically applies 0.7 opacity to the button after the mouseout event. This function reverses this event to the desired value.
      iwCloseBtn.mouseout(function () {
        $(this).css({ opacity: '1' });
      });
    });
  }

  clearMap() {
    try {
      if (this.markersArray) {
        for (var i = 0; i < this.markersArray.length; i++) {
          this.markersArray[i].setMap(null);
        }
        this.markersArray = [];
      }
    } catch (e) {
      e = null;
    }
    if (this.directionsDisplay != null) {
      this.directionsDisplay.setMap(null);
      this.directionsDisplay = null;
    }
  }
}

