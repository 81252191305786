import { Directive, ElementRef, HostListener } from '@angular/core';
import { JioMotiveService } from '../services/jio-motive.service';

@Directive({
  selector: '[ngxDateTimeFormat]'
})
export class DateTimeFormatDirective {

  constructor(private el: ElementRef, private _CommanService: JioMotiveService) {
  }

  private regex: RegExp = this._CommanService.DATETIME_REGX;
  @HostListener('focusout')
  onFocusOut() {
    
    let current: string = this.el.nativeElement.value;
    if (current != "") {
      if (current && !String(current).match(this.regex)) {

        this.el.nativeElement.style.borderLeft = "1px solid #f3120e";
        //this.el.nativeElement.focus();
      } else {
        this.el.nativeElement.style.borderLeft = "";
      }
    } else {
      this.el.nativeElement.style.borderLeft = "";
    }
  }


  //ngOnInit() {
    // let specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];
    // var enterPwd: any = document.getElementById('fromDate');
    // enterPwd.addEventListener('keydown', function (event) {

      // let selectionstart: Number = Number(this.selectionStart);

      // if (specialKeys.indexOf(event.key) !== -1) {
      //   return;
      // }
      
      // var allowNumerics = [49, 50, 51, 52, 53, 54, 55, 56, 57, 48];
      // var slashChar = 191;
      // var SpaceChar = 32;
      // var colunChar = 186;
      // var alphChar = [65, 80, 77];

      // var numericPos = [0, 1, 3, 4, 6, 7, 8, 9, 11, 12, 14, 15];
      // var slashPos = [2, 5];
      // var spacePos = [10, 16];
      // var alphPos = [17, 18];
      // var colunPos = [13];
      // var isValidInput = false;
      // 

      // for (var i = numericPos.length - 1; i >= 0; i--) {
      //   if (numericPos[i] == selectionstart) {
      //     allowNumerics.forEach(element => {
      //       if (element == event.keyCode) {
      //         isValidInput = true;
      //       }
      //     });
      //   }
      // };

      // for (var i = slashPos.length - 1; i >= 0; i--) {
      //   if ((slashPos[i] == selectionstart) && (event.keyCode == slashChar)) {
      //     isValidInput = true;
      //   }
      // };

      // for (var i = spacePos.length - 1; i >= 0; i--) {
      //   if ((spacePos[i] == selectionstart) && (event.keyCode == SpaceChar)) {
      //     isValidInput = true;
      //   }
      // };

      // for (var i = colunPos.length - 1; i >= 0; i--) {
      //   if ((colunPos[i] == selectionstart) && (event.keyCode == colunChar)) {
      //     isValidInput = true;
      //   }
      // };


      // for (var i = alphPos.length - 1; i >= 0; i--) {
      //   if (alphPos[i] == selectionstart) {
      //     alphChar.forEach(element => {
      //       if (element == event.keyCode) {
      //         isValidInput = true;
      //       }
      //     });
      //   }
      // };

      // // 
      // // var leng = this.value.length;

      // // if (!isValidInput) {
      // //   //allow numbers
      // //   event.preventDefault();
      // //   return;
      // // }

      // // } else if (isValidInput === false ||
      // //   (event.keyCode == 191 && (leng < 2 || leng > 5 || leng == 3 || leng == 4)) ||
      // //   ((leng == 2 || leng == 5) && event.keyCode !== 191) || /* only can hit a '-' for 3rd pos and 6th pos. */
      // //   (event.keyCode !== 32 && (leng == 10 || leng == 16)) || /* only can hit a ' '(space) for 11th pos AND 17TH POS. */
      // //   (event.keyCode !== 186 && (leng == 13)) || /* only can hit a ':' for 14th pos. */

      // //   ((event.keyCode !== 65) && (leng == 17)) || /* only can hit a 'A/P' for 18th pos. */
      // //   (event.keyCode !== 77 && (leng == 18)) || /* only can hit a 'A/P' for 19th pos. */
      // //   leng == 19) /* only want 19 characters "12-12-1990 07:56 AM" */ {

      // //   event.preventDefault();
      // //   return;
      // // }

    //});
  //}

}
