import { Directive, ElementRef, HostListener } from '@angular/core';
import { JioMotiveService } from '../services/jio-motive.service';

@Directive({
  selector: '[ngxAlphaNumericWithComma]'
})
export class ngxAlphaNumericWithCommaDirective {

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];
  constructor(private el: ElementRef, private _commanService: JioMotiveService) { }

  private regex: RegExp = new RegExp(/^[a-zA-Z0-9]+(\,[a-zA-Z0-9]+)*$/g);
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    
    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (String(event.key) == ",") {
      return;
    }
    if (next.length > 0 && String(next) == " ") {      
      event.preventDefault();
    } 
    else if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }

  }
  ngOninit() {

  }




}
