import { Directive, ElementRef, HostListener } from '@angular/core';
import { JioMotiveService } from '../services/jio-motive.service';

@Directive({
  selector: '[ngxMobilecheck]'
})
export class MobilecheckDirective {

  constructor(private el: ElementRef, private _commanService: JioMotiveService) { }
  private Lenregex: RegExp = this._commanService.MOBILE_REGX;
  @HostListener('focusout')
  onFocusOut() {
    
    let current: string = this.el.nativeElement.value;
    if (current != "") {
      if (current && !String(current).match(this.Lenregex)) {

        this.el.nativeElement.style.borderLeft = "1px solid #f3120e";
      } else {
        this.el.nativeElement.style.borderLeft = "";
      }
    }else{
      this.el.nativeElement.style.borderLeft = "";
    }
  }
}
