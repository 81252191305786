import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import moment from 'moment';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';

@Component({
  selector: 'app-date-range-calendar',
  templateUrl: './date-range-calendar.component.html',
  styleUrls: ['./date-range-calendar.component.scss']
})
export class DateRangeCalendarComponent implements OnInit {


  @ViewChild(DaterangepickerDirective, { static: false }) pickerDirective: DaterangepickerDirective;
  @Input() DateRange: any;
  @Input() ranges: any;
  @Input() format: any;
  @Input() showTime: any;
  @Input() opens: any;
  @Input() openCalendarOnLoad: boolean = false;
  @Input() showCalIcon: boolean = true;
  @Input() minDate: any;
  @Input() maxDate: any;
  @Output() valueChange = new EventEmitter();
  constructor() {
  }

  ngOnInit(): void {
    if (!this.ranges) {
      this.ranges = {
        'Today': [moment().startOf('day').toString(), moment()],
        'Yesterday': [moment().subtract(1, 'days').startOf('day').toString(), moment().subtract(1, 'days').endOf('day').toString()],
        'Last 7 Days': [moment().subtract(6, 'days').startOf('day').toString(), moment().endOf('day').toString()],
        'Last 30 Days': [moment().subtract(29, 'days').startOf('day').toString(), moment().endOf('day').toString()],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      };
    }
    //debugger;
    if (this.openCalendarOnLoad) {
      setTimeout(() => {
        this.openDatepicker();
      }, 100);
    }
  }

  rangeClicked($event) {
    this.isEndDateClick = false;
    this.noOFclick = -1;
  }

  datesUpdated($event) {

    if (this.noOFclick >= 1 || this.isEndDateClick) {
      this.checkEndDate($event);
    } else if (this.noOFclick == -1) {///from side menu-range click
      // this.checkEndDate($event);

      this.DateRange = {
        startDate: new Date($event.startDate._d),
        endDate: new Date($event.endDate._d)
      };
    }

    this.valueChange.emit(this.DateRange);
    this.noOFclick = 0;
  }

  checkEndDate(event) {
    var inputDate = this.DateRange.endDate;
    // Get today's date
    var todaysDate: any = moment();

    if ((inputDate.startOf('day')._d).toString() == (todaysDate.startOf('day')._d).toString()) {
      this.DateRange = {
        startDate: new Date(event.startDate._d.setHours(0, 0, 0, 0)),
        endDate: new Date(new Date().setDate(new Date().getDate()))
      };
    } else {
      this.DateRange = {
        startDate: new Date(event.startDate._d.setHours(0, 0, 0, 0)),
        endDate: new Date(event.endDate._d.setHours(23, 59, 59, 999))
      }
    }
  }

  noOFclick = 0;
  isEndDateClick = false;
  openDatepicker() {
    this.pickerDirective.open();
    var that = this;
    $(".table-condensed tr td").on('click', function (event) {
      ////console.log($(event.currentTarget).attr('class'));
      that.isEndDateClick = false;
      that.noOFclick++;
      if ($(event.currentTarget).attr('class').includes('end-date')) {
        that.isEndDateClick = true;
      }
    });

    $(".calendar-time").on('click', function (event) {
      that.isEndDateClick = false;
      that.noOFclick = 0;
    });
  }
}
