import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { LoaderService } from './services/loader.service';
 

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {
  title = 'JIOHumsafar';
  ///  showLoader: boolean = false;
  constructor() { }
  // private loaderService: LoaderService,
  // private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    // this.loaderService.status.subscribe((val: boolean) => {
    //   this.showLoader = val;
    //   this.cdr.detectChanges();
    // });
  }

  ngOnDestroy() {
  }

}
