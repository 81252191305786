import { AfterViewChecked, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseService } from 'src/app/services/base.service';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-file-viewer-modal',
  templateUrl: './file-viewer-modal.component.html',
  styleUrls: ['./file-viewer-modal.component.scss']
})
export class FileViewerModalComponent implements OnInit, AfterViewChecked {


  // @Input() modalTitle: string;
  // @Input() width: any;
   @Input() zIndex: number;
  // @Input() minWidth: number = 260;
  // @Input() minHeight: number = 200;
  // @Input() scrollTop: boolean = true;
  // @Input() maximizable: boolean;
  @Input() backdrop: boolean = true;
  // @Input() styleClass: string;
  @Input() src: string;
  @Input() type:string;
  @Input() selectedDoc:any;
  @ViewChild('modalRoot') modalRoot: ElementRef;
  @ViewChild('modalBody') modalBody: ElementRef;
  @Input('bookingid') bookingid :any;
  @Output('clickBack') backtosim = new EventEmitter();
  @ViewChild('deleteModel') deleteModel: any;
  @Input() imageHidden:boolean

  deleteConformation(obj) {
    this.deletedObj = obj;
    this.deleteModel.show();
  }
 // @Output('clickBackSimHistory1') backtosim1 = new EventEmitter();
  // @ViewChild('modalHeader') modalHeader: ElementRef;
  // @ViewChild('modalFooter') modalFooter: ElementRef;

  // @HostBinding('class')
  // get cssClass(): string {
  //   let cls = 'app-modal';
  //   if (this.styleClass) {
  //     cls += ' ' + this.styleClass;
  //   }
  //   return cls;
  // }

  visible: boolean;
  contentzIndex: number;
  executePostDisplayActions: boolean;
  // dragging: boolean;
  // resizingS: boolean;
  // resizingE: boolean;
  // resizingSE: boolean;
  // lastPageX: number;
  // lastPageY: number;
  preMaximizeRootWidth: number;
  preMaximizeRootHeight: number;
  preMaximizeBodyHeight: number;
  preMaximizePageX: number;
  preMaximizePageY: number;

  constructor(private element: ElementRef, private baseService: BaseService,
    private toaster: ToastrService,
    private loaderService: LoaderService,private ngZone: NgZone) {
  }

  ngOnInit() {
    if (!this.zIndex) {
      this.zIndex = this.getMaxModalIndex() + 1;
      this.zIndex = this.zIndex || 1100;
    }
    this.contentzIndex = this.zIndex + 1;
  }

  ngAfterViewChecked() {
    if (this.executePostDisplayActions) {
      this.center();
      this.executePostDisplayActions = false;
    }
  }

  @HostListener('keydown.esc', ['$event'])
  onKeyDown(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.hide();
  }
  touchpt_id: any = "";
  //selectedDoc: any;
  fileType = '';
  fileSRC = '';
  deletedObj: any;
  public multFilesList: any = [];
  removeDoc() {
    //this.loaderService.display(true);
    let data = {
      "bookingid": this.bookingid,
      "docID": this.selectedDoc._id,
      "loctype": this.touchpt_id == "" ? 1 : 2,
      "tch_id": this.touchpt_id
    }
    try {
      this.baseService.doRequest('customer/booking/v1/removeEpodDoc', 'post', data).subscribe(async (res) => {
        let result: any = res;
        this.multFilesList = [];
        if (result.status == 200) {
          this.toaster.success(result.msg);
          this.fileType = '';
          this.fileSRC = '';
          this.selectedDoc = undefined;
         this.hide();
          this.backtosim.emit(true);
          this.loaderService.display(true);
        }
        else {
          this.toaster.warning(result.msg);
        }

       this.loaderService.display(false);
      }, error => {
       // this.loaderService.display(false);
      });
    } catch (e) {
      e = null;
      this.loaderService.display(false);
    }
  }


  onoutSideClick(event): void {
    event.preventDefault();
    event.stopPropagation();
    this.hide();
  }

  show(): void {
    this.executePostDisplayActions = true;
    this.visible = true;
    setTimeout(() => {
      this.modalRoot.nativeElement.focus();
      this.modalBody.nativeElement.scrollTop = 0;
     // this.maximize();
    }, 1);


    
  }

  hide(): void {
    this.visible = false;
    this.focusLastModal();
  }

  center() {
    let elementWidth = this.modalRoot.nativeElement.offsetWidth;
    let elementHeight = this.modalRoot.nativeElement.offsetHeight;

    if (elementWidth === 0 && elementHeight === 0) {
      this.modalRoot.nativeElement.style.visibility = 'hidden';
      this.modalRoot.nativeElement.style.display = 'block';
      elementWidth = this.modalRoot.nativeElement.offsetWidth;
      elementHeight = this.modalRoot.nativeElement.offsetHeight;
      this.modalRoot.nativeElement.style.display = 'none';
      this.modalRoot.nativeElement.style.visibility = 'visible';
    }

    const x = Math.max((window.innerWidth - elementWidth) / 2, 0);
    const y = Math.max((window.innerHeight - elementHeight) / 2, 0);

    this.modalRoot.nativeElement.style.left = x + 'px';
    this.modalRoot.nativeElement.style.top = y + 'px';
  }
  calcBodyHeight() {
    const windowHeight = window.innerHeight;
    if (this.modalRoot.nativeElement.offsetWidth > windowHeight) {
      this.modalBody.nativeElement.style.height = (windowHeight * .75) + 'px';
    }
  }

  getMaxModalIndex() {
    let zIndex = 0;
    const modals = document.querySelectorAll('.ui-modal');
    [].forEach.call(modals, function (modal) {
      const indexCurrent = parseInt(modal.style.zIndex, 10);
      if (indexCurrent > zIndex) {
        zIndex = indexCurrent;
      }
    });
    return zIndex;
  }

  focusLastModal() {
    try {
      const modal = this.findAncestor(this.element.nativeElement, 'app-modal');
      if (modal && modal.children[1]) {
        modal.children[1].focus();
      }
    } catch (e) {
      e = null;
    }
  }

  findAncestor(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls)) {
    }
    return el;
  }

  maximize() {
    this.preMaximizePageX = parseFloat(this.modalRoot.nativeElement.style.top);
    this.preMaximizePageY = parseFloat(this.modalRoot.nativeElement.style.left);
    this.preMaximizeRootWidth = this.modalRoot.nativeElement.offsetWidth;
    this.preMaximizeRootHeight = this.modalRoot.nativeElement.offsetHeight;
    this.preMaximizeBodyHeight = this.modalBody.nativeElement.offsetHeight;

    this.modalRoot.nativeElement.style.top = '0px';
    this.modalRoot.nativeElement.style.left = '0px';
    this.modalRoot.nativeElement.style.width = '100vw';
    this.modalRoot.nativeElement.style.height = '100vh';
    // const diffHeight = this.modalHeader.nativeElement.offsetHeight + this.modalFooter.nativeElement.offsetHeight;
    // this.modalBody.nativeElement.style.height = 'calc(100vh - ' + diffHeight + 'px)';
     this.modalBody.nativeElement.style.maxHeight = 'none';

  }

  downloadImage(data) {
    try {
      let imgpath = data.fileSRC
      this.deletedObj=data._id
      var that = this;
      if (imgpath) {
        var req = new XMLHttpRequest();
        req.open("GET", imgpath, true);
        req.responseType = "blob";

        req.onload = function (event) {
          var blob = req.response;
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = data.fname; //+ data.filetype;
          link.click();
        };
        req.send();
      }
    } catch (error) {
      //console.log(error.message);
    }
  }


}

