import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor() { }
  public status = new EventEmitter();
  public loderSts = new EventEmitter();

  display(value: boolean, text?: any) {
    this.status.next({ value, text });
  }
  spinner(value: boolean, text?: any) {
    this.loderSts.next({ value, text });
  }

  // public data = new EventEmitter();
  // dataDisplay(value: any, text?: any) {
  //   this.data.next({ value, text });
  // }
}
