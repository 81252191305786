import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { User } from './../models/user';
import { AppConfigService } from './app-config-service';
import { JioMotiveService } from './jio-motive.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient, private appConfigService: AppConfigService,
        private jioMotiveService: JioMotiveService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string, is_mobile = false, otp = "") {
        sessionStorage.clear();
        return this.http.post<any>(`${this.appConfigService.getConfig().apiUrl}API/Login/v7/Token`, { username, password, is_mobile, otp })
            .pipe(map((user: any) => {                
                //sessionStorage.setItem('currentUser', JSON.stringify(user));
                if (user.status === 200) {
                this.setSessionData(user,username,password)
                }
                return user;

            }));
    }
    ssoLogin(token: any) {
        let reqObj: any = {
            "JHSAuth": token,
        }
        
        sessionStorage.clear();
     return this.http.post<any>(`${this.appConfigService.getConfig().evapiUrl}api/ev/login/v1/authenticate_SSO`, reqObj)
            .pipe(map((user: any) => {                
                if (user.status === 200) {
                this.setSessionData(user,'',"")
                }
                return user;

            }));
    }
    sendLoginOTP(reqObj) {
        return this.http.post<any>(`${this.appConfigService.getConfig().apiUrl}API/login/v5/loginWithOTP`, reqObj)
            .pipe(map(res => {
                return res;
            }));
    }
    setSessionData(user,username,password){
       
            if (Object.keys(user.adata).length > 0 && user.adata.constructor === Object) {
                sessionStorage.setItem('ADMIN_EMAIL_ID', user.adata.enterpriseid);
                sessionStorage.setItem('ADMIN_RMN', user.adata.rmnno);
                sessionStorage.setItem("ASSET_TOKEN", user.adata.accesstoken==undefined?"":user.adata.accesstoken.token )
                sessionStorage.setItem("ASSET_REFRESH_TOKEN", user.adata.accesstoken==undefined?"":user.adata.accesstoken.refreshToken)
                sessionStorage.setItem("ASSET_TOKEN_EXP", user.adata.accesstoken==undefined?"":user.adata.accesstoken.expires_in);
                sessionStorage.setItem('CLIENT_ID', user.adata.clientid);
                sessionStorage.setItem('CLIENT_NAME', user.adata.clientname);
                sessionStorage.setItem('DEFAULT_LATITUDE', user.adata.latitude);
                sessionStorage.setItem('DEFAULT_LONGITUDE', user.adata.longitude);
                sessionStorage.setItem('DEFAULT_ZOOM', user.adata.zoom ? user.adata.zoom : 12);
                sessionStorage.setItem('IS_FEEDBACK_REQUEST', user.adata.feedbackmodule);
                sessionStorage.setItem('LOGIN_EMAIL_ID', username);
                sessionStorage.setItem('LOGIN_USER_ID', user.adata.userid); //login ID
                sessionStorage.setItem('LOGIN_USER_NAME', user.adata.username);
                sessionStorage.setItem('LOGIN_USER_PASSWORD', password);
                sessionStorage.setItem('REDIRECT_URL', user.adata.redirecturl);
                sessionStorage.setItem('SITE_ID', user.adata.siteid);
                sessionStorage.setItem('ENTERPRISE_CODE', user.adata.enterpriseCode);
                sessionStorage.setItem('ROLE_ID', user.adata.roleId == null ? "" : user.adata.roleId);
                sessionStorage.setItem('TENENT_ID', user.adata.tenantid);
                sessionStorage.setItem('WIDGET_LIST', JSON.stringify(user.widgetsenablelist));
                sessionStorage.setItem('ACCESS_RIGHTS', JSON.stringify(user.accessRights));
                sessionStorage.setItem('FEATURE_AVAILABILITY', JSON.stringify(user.featureAvailability))
                let newDate: any = new Date();
                sessionStorage.setItem('LOGIN_TIME_SUMMARY', newDate.getTime());
                sessionStorage.setItem('USEREMAIL', user.adata.userEmail);
                sessionStorage.setItem('USERMOBILENO', user.adata.userMobileNo); 
                sessionStorage.setItem('enterprise_ID', user.adata.enterprise_ID); 
            }
            if (Object.keys(user.hdata).length > 0 && user.hdata.constructor === Object) {
                sessionStorage.setItem('JIO_REFRESHTOKEN', user.hdata.refresh_token);
                sessionStorage.setItem('JIO_TOKEN', user.hdata.access_token);
                sessionStorage.setItem('JIO_TOKEN_EXPER', user.hdata.expires_in);
            }
        
        this.currentUserSubject.next(user);
        return user;
    }

    verifyLoginOTP(reqObj) {
        return this.http.post<any>(`${this.appConfigService.getConfig().apiUrl}API/login/v6/verifyLoginOTP`, reqObj)
            .pipe(map(res => {
                return res;
            }));
    }
    // API/login/getnewsiteadminotp
    verifyNewUserOTP(reqObj) {
        return this.http.post<any>(`${this.appConfigService.getConfig().apiUrl}API/login/getnewsiteadminotp`, reqObj)
            .pipe(map(res => {
                return res;
            }));
    }

    jiouserdata(username: string) {
        var httpIOTOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "bearer " + sessionStorage.getItem("JIO_TOKEN") })
        }
        return this.http.get<any>(`${this.appConfigService.getConfig().jioUrl}users/name/` + username, httpIOTOptions)
            .pipe(map(user => {
                return user
            }));
    }

    jiositedata(siteid: number) {
        var httpIOTOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': "bearer " + sessionStorage.getItem("JIO_TOKEN") })
        }
        return this.http.get<any>(`${this.appConfigService.getConfig().jioUrl}sites/` + siteid, httpIOTOptions)
            .pipe(map(siteData => {
                return siteData
            }));
    }
    
    resetpassword(resetdata: any) {
        var httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
        return this.http.post<any>(`${this.appConfigService.getConfig().apiUrl}API/Login/v4/updatePwd`, resetdata)
            .pipe(map(resetpwd => {
                return resetpwd
            }));
    }

    resetpasscode(resetdata: any) {
        var httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
        return this.http.post<any>(`${this.appConfigService.getConfig().apiUrl}API/Login/UpdateUserPasscode`, resetdata)
            .pipe(map(passcode => {
                return passcode
            }));
    }

    forgotpassword(forgotObj: any) {
        var httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
        return this.http.post<any>(`${this.appConfigService.getConfig().apiUrl}API/Login/v3/ForgetPassword`, forgotObj)
            .pipe(map(resetpwd => {
                return resetpwd
            }));
    }


    clientconfiguration(userdata: any) {
        return this.http.post<any>(`${this.appConfigService.getConfig().apiUrl}API/Login/v3/ClientUserConfigurtaion`, userdata)
            .pipe(map(user => {
                if (user.status == 200) {
                    sessionStorage.setItem('LOGIN_USER_ID', user.userID)
                }
                return user

            }));
    }








    logout() {
        // remove user from local storage to log user out
        sessionStorage.clear();
        this.currentUserSubject.next(null);
    }

    //The set method is use for encrypt the password value.
    set(keys, value) {
        var key = CryptoJS.enc.Utf8.parse(keys);
        var iv = CryptoJS.enc.Utf8.parse(keys);
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
            {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        return encrypted.toString();
    }

    //The get method is use for decrypt the password value.
    get(keys, value) {
        var key = CryptoJS.enc.Utf8.parse(keys);
        var iv = CryptoJS.enc.Utf8.parse(keys);
        var decrypted = CryptoJS.AES.decrypt(value, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
}


