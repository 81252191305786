<div class="row">
    <div class="col-md-8">
        <div class="card_container epod_viewport_height">
            <div class="row" *ngIf="contype ==1">
                <div class="col-md-4 pl-0">
                    <div class="origin-sec">
                        <i class="fas fa-map-marker-alt pl-2 pr-2" style="color:#169C47 ;font-size: 20px"></i>
                        <span class="origin-dest">
                            {{tripObj?.source?.addr}}
                        </span>
                    </div>
                </div>

                <div class="col-md-4 p-0" *ngIf="tripObj?.bookingsts=='57'">
                    <div class="touch-points3">
                        <span *ngIf="tripObj?.touchpoints?.length > 0">+{{tripObj?.touchpoints?.length}}</span>
                        <span class="touch-line-center3"></span>
                    </div>
                </div>
                <div class="col-md-4 p-0" *ngIf="tripObj?.bookingsts=='54'">
                    <div [ngStyle]="{display:(tripObj?.touchpoints?.length>0?'block':'none')}">
                        <div class="touch-points">
                            <span *ngIf="tripObj.tch_rch_Cnt>0">
                                <span *ngIf="tripObj.tch_rch_Cnt==1">
                                    <div class="touch-line-left">
                                        <span class="toch-1">{{tripObj?.tch_rch_Cnt}}</span>
                                    </div>
                                </span>
                                <div *ngIf="tripObj.tch_rch_Cnt>1" class="tp-count-left">
                                    <span>+ {{tripObj.tch_rch_Cnt-1}}</span>
                                </div>
                                <span *ngIf="tripObj.tch_rch_Cnt>1">
                                    <div class="touch-line-left">
                                        <span class="toch-1">{{tripObj.tch_rch_Cnt-1}}</span>
                                    </div>
                                </span>
                            </span>
                            <span class="touch-line-center"></span>
                            <span *ngIf="tripObj.tch_not_rch_Cnt>0">
                                <span *ngIf="tripObj.tch_not_rch_Cnt==1">
                                    <div class="touch-line-right">
                                        <span class="toch-2">{{tripObj.tch_not_rch_Cnt}}</span>
                                    </div>
                                </span>
                                <div *ngIf="tripObj.tch_not_rch_Cnt>1" class="tp-count-right">
                                    <span>+ {{tripObj.tch_not_rch_Cnt-1}}</span>
                                </div>
                                <span *ngIf="tripObj.tch_not_rch_Cnt>1">
                                    <div class="touch-line-right">
                                        <span class="toch-2">{{tripObj.tch_not_rch_Cnt-1}}</span>
                                    </div>
                                </span>
                            </span>
                        </div>
                    </div>

                    <div [ngStyle]="{display:(tripObj?.touchpoints?.length==0?'block':'none')}">
                        <div class="touch-points1">
                            <span class="touch-line-center1"></span>
                        </div>
                    </div>
                </div>


                <div class="col-md-4 pr-0">
                    <div class="origin-sec">
                        <i class="fas fa-map-marker-alt pl-2 pr-2" style="color:#e5293e ;font-size: 20px"></i>
                        <span class="origin-dest">
                            {{tripObj?.destination?.addr}}
                        </span>
                    </div>

                </div>
            </div>

            <div class="row" *ngIf="contype ==0">
                <div class="col-md-6">
                    <div class="origin-sec">
                        <span class="origin" title="Origin"><img src="assets/images/flag-green.svg" alt=""></span>
                        <span class="origin-dest"> {{tripObj?.source?.addr}}</span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="origin-sec">
                        <span class="origin" title="Destination"><img src="assets/images/flag-red.svg" alt=""></span>
                        <span class="origin-dest">{{tripObj?.destination?.addr}}</span>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-9">
                    <span class="document_box" *ngFor="let doc of multFilesList" (click)='openDocView(doc)'
                    
                        [ngStyle]="{'background-color':(doc?._id === selectedDoc?._id ? '#125D98' : '#ffffff')}">
                        <i class="fas " title="{{doc.fname}}"
                            [ngClass]="doc?.fsts === 1?'fa-check-circle' :(doc?.fsts === 2?'fa-times-circle' : 'fa-upload')"
                            [ngStyle]="(doc?._id === selectedDoc?._id)  && {'color': '#ffffff'}"></i>
                    </span>

                    <div style="font-size: 12px;" [ngClass]="tripObj.isDashboard === 'true' ? 'css-class-1' : 'css-class-2'">
                        <p><i>supported formats are png, jpeg, jpg and pdf with allowed size 10MB &
                                maximum 10 files</i></p>
                    </div>
                </div>
                
                <div class="col-md-3 image-upload" [ngClass]="tripObj.isDashboard === 'true' ? 'css-class-1' : 'css-class-2'" >

                    <label for="file-upload">
                        <i class="fas fa-upload pr-2"></i>Document
                    </label>
                    <input onclick="this.value = null"  id="file-upload" 
                    class="file-upload" type="file" style="display: none;"
                        (change)="onSelectFileAndImage($event)" />
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-6">
                    <fieldset *ngIf="selectedDoc?.fsts === 1">
                        <legend>Approved By</legend>
                        <p>
                            <span><i class="fas fa-user pr-2"></i> </span>
                            <span>{{selectedDoc?.aprv?.name}}</span>
                        </p>
                        <p>
                            <span><i class="fas fa-calendar-day  pr-2"></i></span>
                            <span>{{selectedDoc?.aprv?.ts}}</span>
                        </p>

                    </fieldset>
                    <fieldset *ngIf="selectedDoc?.fsts === 2">
                        <legend>Rejected By</legend>
                        <p>
                            <span><i class="fas fa-user  pr-2"></i> </span>
                            <span>{{selectedDoc?.rjct?.name}}</span>
                        </p>
                        <p>
                            <span><i class="fas fa-calendar-day  pr-2"></i></span>
                            <span>{{selectedDoc?.rjct?.ts}}</span>
                        </p>
                    </fieldset>
                </div>
                <div class="col-md-6" *ngIf="selectedDoc?.autoaprv === 0">
                    <fieldset *ngIf="selectedDoc?.fsts === 1 || selectedDoc?.fsts === 2">
                        <legend>Comments</legend>
                        <p class="comments">{{selectedDoc?.comm}}
                        </p>
                    </fieldset>
                </div>
                    <div *ngIf="selectedDoc?.autoaprv === 1" class="remove-btn ml-auto" [ngClass]="tripObj.isDashboard === 'true' ? 'css-class-1' : 'css-class-2'">
                        <button class="btn btn-danger" style="margin-right: 5px;"
                            (click)='deleteConformation(selectedDoc)'>Remove</button>
                    </div>
            </div>
            <div *ngIf="selectedDoc?.fsts === 0">
                <textarea rows="3" class="form-control" placeholder="comments" style="margin-bottom: 10px;"
                    name="docComment" [(ngModel)]="selectedDoc.comm"
                    [ngStyle]="{border: (actiontype == 2 && selectedDoc.comm=='') ? '1px solid red' : '1px solid lightgray'}"></textarea>
                <div class="action_butns">
                    <button class="btn btn-danger" style="margin-right: 5px;"
                        (click)='actionOnDoc(selectedDoc,2)'>Reject</button>
                    <button class="btn btn-primary" (click)='actionOnDoc(selectedDoc,1)'>Approve</button>
                </div>
            </div>
           
        </div>
    </div>
    <div class="col-md-4" style="padding-left: 0;">
        <div class="card_container epod_viewport_height" style="padding: 0; position: relative;">
            <div style="padding: 10px;">
                <div class="pdf_container" (click)="openPDFonModel()" *ngIf="fileType != ''">
                    <div *ngIf="fileType=='PDF'">
                        <pdf-viewer [src]="fileSRC" [rotation]="0" [original-size]="false" [show-all]="true"
                            [fit-to-page]="false" [zoom]="0.8" [zoom-scale]="'page-width'" [stick-to-page]="false"
                            [render-text]="true" [external-link-target]="'blank'" [autoresize]="true"
                            [show-borders]="false"></pdf-viewer>
                    </div>
                    <div *ngIf="fileType=='IMG'">
                        <img [src]='fileSRC' style="width:100%;height:calc(100vh - 290px)">
                    </div>
                </div>
            </div>
            <div class="details_foot">
                <p>
                    <span><i class="fa fa-user"></i> Uploaded by:</span>
                    <span>{{selectedDoc?.updby}}</span>
                    <span style="float: right;">
                        <span><i class="fas fa-calendar-day"></i></span>
                        <span>{{selectedDoc?.updts}}</span>
                    </span>
                </p>
            </div>
        </div>
    </div>
</div>



<app-file-viewer-modal #pdfviewModel [src]='fileSRC' [type]='fileType'>
</app-file-viewer-modal>


<app-modal #deleteModel modalTitle="Delete Document" [width]="400" [maximizable]="true" (close)="deleteModel.hide();"
    autocomplete="off">
    <div class="app-modal-body">
        <div>
            Are you sure you want to delete document <b>{{deletedObj?.fname}}?</b>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" (click)="deleteModel.hide();" class="btn btn-inverse width-100 mr-2">
            Close</button>
        <button type="button" (click)="removeDoc();deleteModel.hide();" class="btn btn-success" tabindex="20">
            Remove
        </button>
    </div>
</app-modal>