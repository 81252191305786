import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import {
  CamelToHumanPipe, DaysHoursSecondsPipe, BytesConvertFilterPipe, EpochToDateAndTimeFilterPipe, StrToMacPipe, SafePipe, ArrayOrderByPipe,
  FormatTrafficUnitsPipe, DateAgoPipe, FormatCellPipe, UniqueFilterPipe, ArrayToStrPipe, TimeFormat, DateFormat, DateAndTimeFormat,
  CveToDateFilterPipe, MacFilterPipe, AssessmentDateFilterPipe, SplitPipe, FilterPipe, EpochToDateFilterPipe, DateFormatType, IsoToDateFormatPipe, SafeUrlPipe
} from './app.filter.pipe';

@NgModule({
  declarations: [
    CamelToHumanPipe,
    DaysHoursSecondsPipe,
    BytesConvertFilterPipe,
    EpochToDateAndTimeFilterPipe,
    StrToMacPipe,
    SafePipe, MacFilterPipe, ArrayOrderByPipe, AssessmentDateFilterPipe, SplitPipe,
    FormatTrafficUnitsPipe,
    DateAgoPipe,
    FormatCellPipe,
    UniqueFilterPipe,
    ArrayToStrPipe,
    TimeFormat,
    DateFormat,
    DateAndTimeFormat,
    CveToDateFilterPipe, FilterPipe, EpochToDateFilterPipe,DateFormatType,IsoToDateFormatPipe, SafeUrlPipe
  ],
  imports: [CommonModule],
  exports: [
    CamelToHumanPipe,
    DaysHoursSecondsPipe,
    BytesConvertFilterPipe,
    EpochToDateAndTimeFilterPipe,
    StrToMacPipe,
    SafePipe, MacFilterPipe, ArrayOrderByPipe, AssessmentDateFilterPipe, SplitPipe,
    FormatTrafficUnitsPipe,
    DateAgoPipe,
    FormatCellPipe,
    UniqueFilterPipe,
    ArrayToStrPipe,
    TimeFormat,
    DateFormat,
    DateAndTimeFormat,
    CveToDateFilterPipe,
    FilterPipe, EpochToDateFilterPipe,DateFormatType,IsoToDateFormatPipe, SafeUrlPipe
  ],
  providers: [DatePipe, FilterPipe]
})
export class AppFilterPipeModule {
}
