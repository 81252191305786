import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[ngxNoSpacesAllowed]'
})
export class NoSpacesAllowedDirective implements OnInit {

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  //private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home', '-' ];
  private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home'];
 
 constructor(private el: ElementRef) {
  }
  @HostListener('keydown', [ '$event' ])
  onKeyDown(event: KeyboardEvent) {
  
  if (this.specialKeys.indexOf(event.key) !== -1) {
  return;
  }
  let current: string = this.el.nativeElement.value;
  
      var x = event.keyCode;
      if (x == 32) {
        event.preventDefault();
      }
  }
  ngOnInit(){
  }
}
