<div class="bg-overlay" (click)='onoutSideClick($event)' [style.zIndex]="zIndex"
    [ngStyle]="{'display': (visible && backdrop) ? 'block' : 'none'}">
</div>

<div class="bg-modal" tabindex="-1" #modalRoot
    [style.zIndex]="contentzIndex"
     [ngStyle]="{'display': visible ? 'block' : 'none', 'width': type=='IMG' ? 'unset' : '500px'}">

    <div class="bg-modal-body" #modalBody [ngStyle]="{'height': type=='IMG' ? 'auto' : '81vh'}">
        <div class="text-right mb-2 mt-5 pr-2" >
            <button type="button" class="btn btn-primary mr-2" (click)="downloadImage(selectedDoc)"><i class="fa fa-download" aria-hidden="true"></i></button>
            <button type="button" *ngIf="!imageHidden"
            (click)='deleteConformation(selectedDoc)' class="btn btn-danger" tabindex="20">
                <i class="fa fa-trash-o" aria-hidden="true"></i>
            </button>
        </div>
        <div class="preview">
            <span class="float-left">
                <h4 style="font-size: 1.2rem;    margin: 0;position: relative;top: 5px;padding-left: 8px;">{{ !imageHidden ?' EPOD Document':'Document'}}</h4>
            </span>
            <span class="float-right" (click)="hide()" (mousedown)="onoutSideClick($event)" (touchstart)="onoutSideClick($event)">
                <svg class="bg-icon zoom" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32">
                    <path fill="#e5293e;"
                        d="M12,2C6.5,2,2,6.5,2,12c0,5.5,4.5,10,10,10s10-4.5,10-10C22,6.5,17.5,2,12,2z M16.9,15.5l-1.4,1.4L12,13.4l-3.5,3.5   l-1.4-1.4l3.5-3.5L7.1,8.5l1.4-1.4l3.5,3.5l3.5-3.5l1.4,1.4L13.4,12L16.9,15.5z">
                    </path>
                </svg>
            </span>
            <div class="clearfix"></div>
        </div>
        <div *ngIf="type=='PDF'">
            <pdf-viewer class="w-100" [src]="src" [rotation]="0" 
            [original-size]="false" [show-all]="true" [fit-to-page]="true"
                [zoom]="1" [zoom-scale]="'page-width'" [stick-to-page]="true" [render-text]="true"
                [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false"></pdf-viewer>
        </div>

        <div *ngIf="type=='IMG'" class="ext-center">
            <div class="">      
                <div class="p-2">
                    <div class="mb-2"><img [src]='src' class="m-auto mb-2 w-100">
                    </div>
          
                </div>
               
            </div>      
            
        </div>
    </div>

</div>
<app-modal #deleteModel modalTitle="Delete Document" [width]="400" [maximizable]="true" (close)="deleteModel.hide();"
    autocomplete="off">
    <div class="app-modal-body">
        <div>
            Are you sure you want to delete document <b>{{deletedObj?.fname}}?</b>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" (click)="deleteModel.hide();" class="btn btn-inverse width-100 mr-2">
            Close</button>
        <button type="button" (click)="removeDoc();deleteModel.hide();" class="btn btn-success" tabindex="20">
            Remove
        </button>
    </div>
</app-modal>