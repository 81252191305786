import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[ngxAcceptOnlyNumbers]'
})
export class AcceptOnlyNumbersDirective {

  private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home' ];
  constructor(private el: ElementRef) { }

  private regex: RegExp = new RegExp(/^-?[0-9]+(\[0-9]*){0,1}$/g);

 @HostListener('keypress', [ '$event' ])
 onKeyPress(event: KeyboardEvent) { 
 
  if (this.specialKeys.indexOf(event.key) !== -1) {
  return;
  }
  let current: string = this.el.nativeElement.value;
  let next: string = current.concat(event.key);
  if (next && !String(next).match(this.regex)) {
            event.preventDefault();
      }
      
  }
  ngOnInit(){ }
}
