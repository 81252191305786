import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { JioMotiveService } from './jio-motive.service';
import { AuthenticationService, LoaderService } from '.';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';

interface JsonResponse {
  assignedTags: any;
  searchedGeofenceTags: any;
  assignedEntities: any;
  unAssignEntities: any;
  status: number;
  message: any;
  details: any;
  data: any;
  msg: any;
  sts: any
  result: any // added for eway bill
}
@Injectable({
  providedIn: 'root'
})

export class BaseService {

  routeActivedata: any = {};
  menuData: any = [];
  DERIVED_ALERTS_LOOKUPS_DATA: any = {};
  archiveReport: boolean;
  responseStatus: number;
  constructor(private httpClient: HttpClient,
    private commonService: JioMotiveService,
    public authService: AuthenticationService,
    private toaster: ToastrService,
    private router: Router,
    private loaderService: LoaderService) { }

  async getDerivedAlertsFromLookUp() {
    try {
      let derivedResultAsync: any = await this.getDerivedLookupValues(this.lookTypeIds.DerivedAlerts);
      derivedResultAsync.forEach(element => {
        this.DERIVED_ALERTS_LOOKUPS_DATA[element.alertId] = element.alertId;
      });
    } catch (error) {
      //console.log("Error in getDerivedAlertsFromLookUp() :" + error.message);
    }
  }

  getDerivedLookupValues(lookUpId) {
    try {
      let data = {
        type: [lookUpId]
      }
      return new Promise((resolve, reject) => {
        let alertLst = [];
        this.doRequest('fleetmaster/common/gettype', 'post', data).subscribe(result => {
          if (result.status == 200) {
            result.data[0].lvdata.forEach(element => {
              alertLst.push({
                alertId: element.lkpval,
                alertName: element.lkpdesc
              })
            });
          } else {
            //console.log(result.message);
          }
          resolve(alertLst);
        }, error => {
          resolve(alertLst)
          //console.log("getDerivedLookupValues " + " error : " + error.message);
        })
      });
    } catch (error) {
      return [];
      //console.log(error.message)
    }
  }


  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    try {
      this.routeActivedata = route;
      // debugger;
      if (this.routeActivedata._routerState.url.indexOf('?') > -1) {
        let url: any = this.routeActivedata._routerState.url.split('?');
        this.routeActivedata._routerState.url = url[0];
      }
      let data: any;
      let isstatus: boolean = true;

      if (this.routeActivedata._routerState.url) {
        let routeUrl: any = this.routeActivedata._routerState.url.split("/");
        if (routeUrl.length > 4) {
          this.routeActivedata._routerState.url = "/" + routeUrl[1] + "/" + routeUrl[2] + "/" + routeUrl[3];
        }
      }

      if (sessionStorage.getItem("defaultPage") == this.routeActivedata._routerState.url) {
        isstatus = true;
      }
      else {
        for (let i = 0; i < this.menuData.length; i++) {
          data = this.menuData[i];
          isstatus = false;
          for (let j = 0; j < data.children.length; j++) {
            if (data.children[j].link == this.routeActivedata._routerState.url) {
              isstatus = true;
              break;
            }
          }
          if (isstatus) {
            break;
          }
        }
      }
      return of(isstatus);
    } catch (ex) {
      return of(false);
    }
  }



  doRequest(endpointurl: string,
    method: string,
    data?: any,
    params?: any,
    headers?: HttpHeaders, hashOptions?: any) {
    const httpOptions = {
      headers: headers ? headers : new HttpHeaders({
        'Content-Type': 'application/json',
        "tokenuserid": sessionStorage.getItem('LOGIN_USER_ID'),
        'Authorization': "Bearer " + sessionStorage.getItem("ASSET_TOKEN"),
      }),

      body: !data ? {} : JSON.stringify(data),
      params: params
    }

    return this.httpClient
      //  .request<JsonResponse>(method, this.commonService.BASE_URL_ASSET + `${endpointurl}`, httpOptions)
       .request<JsonResponse>(method, (endpointurl.split('/').includes('ev') ? this.commonService.EV_BASE_URL_ASSET : this.commonService.BASE_URL_ASSET) + `${endpointurl}`, httpOptions)
      .pipe(
        map(response => this.handleResponse(response))
      );
  }
  doMockRequest(endpointurl: string,
    method: string,
    data?: any,
    params?: any,
    headers?: HttpHeaders, hashOptions?: any) {
    const httpOptions = {
      headers: headers ? headers : new HttpHeaders({
        'Content-Type': 'application/json',
        "tokenuserid": sessionStorage.getItem('LOGIN_USER_ID'),
        'Authorization': "Bearer " + sessionStorage.getItem("ASSET_TOKEN"),
      }),

      body: !data ? {} : JSON.stringify(data),
      params: params
    }

    return this.httpClient
      //  .request<JsonResponse>(method, this.commonService.BASE_URL_ASSET + `${endpointurl}`, httpOptions)
       .request<JsonResponse>(method,  `${endpointurl}`, httpOptions)
      .pipe(
        map(response => this.handleResponse(response))
      );
  }

  doLocalRequest(endpointurl: string,
    method: string,
    data?: any,
    params?: any,
    headers?: HttpHeaders, hashOptions?: any) {
    const httpOptions = {
      headers: headers ? headers : new HttpHeaders({
        'Content-Type': 'application/json',
        "tokenuserid": sessionStorage.getItem('LOGIN_USER_ID'),
        'Authorization': "Bearer " + sessionStorage.getItem("ASSET_TOKEN"),
      }),

      body: !data ? {} : JSON.stringify(data),
      params: params
    }

    return this.httpClient
      .request<JsonResponse>(method, "http://183.82.2.55:8090/jhswebapi/api/" + `${endpointurl}`, httpOptions)
      .pipe(
        map(response => this.handleResponse(response))
      );
  }

  async vehicleSystemStatus(endpointurl: string){
    // await this.authService.vehicleSystemStatusAuth()
    // //console.log('TOKEN--> ', sessionStorage.getItem('BEARER_TOKEN'));
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "tokenuserid": sessionStorage.getItem('LOGIN_USER_ID'),
        'Authorization': "Bearer "+sessionStorage.getItem('JIO_TOKEN'),
      }),

      // body: !data ? {} : JSON.stringify(data),
      // params: params
    }
return this.httpClient.get<any>(endpointurl,httpOptions);
    // return this.httpClient
    //   .request<JsonResponse>(method,  `${endpointurl}`, httpOptions)
    //   .pipe(
    //     map(response => this.handleResponse(response))
    //   );
  }

  doRequestFormData(endpointurl: string,
    method: string,
    data?: any,
    params?: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        "tokenuserid": sessionStorage.getItem('LOGIN_USER_ID'),
        'Authorization': "Bearer " + sessionStorage.getItem("ASSET_TOKEN"),
      }),

      body: data,
      params: params
    }

    return this.httpClient
      .request<JsonResponse>(method, this.commonService.BASE_URL_ASSET + `${endpointurl}`, httpOptions)
      .pipe(
        map(response => this.handleResponse(response))
      );
  }
  JIORequestForNoResponce(endpointurl: string,
    method: string,
    data?: any,
    params?: any,
    headers?: HttpHeaders, hashOptions?: any) {
    type bodyType = 'body';  
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Authorization': "Bearer " + "",
        'Authorization': "Bearer " + sessionStorage.getItem("JIO_TOKEN"),
      }),
      body: JSON.stringify(data),
      params: params,
      observe: <bodyType>'response'
    }
    return this.httpClient
      .request<Response>(method, this.commonService.BASE_URL_IOT + `${endpointurl}`, httpOptions)
      .pipe(
        map((response: Response) => {
          //this.jiohandleResponse(response)
          //this.responseStatus = response.status;
          return response;
        }),
        catchError((err: any) => {
          return Observable.throwError(this.jiohandleResponse(err));
        }),
      );
  }

  JIORequest_B2B(endpointurl: string,
    method: string,
    data?: any,
    params?: any,
    headers?: HttpHeaders, hashOptions?: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Authorization': "Bearer " + "",
        'Authorization': "Bearer " + sessionStorage.getItem("JIO_TOKEN"),
      }),
      body: JSON.stringify(data),
      params: params
    }
    return this.httpClient
      .request<JsonResponse>(method, endpointurl, httpOptions)
      .pipe(
        map(response => this.jiohandleResponse(response))
      );
  }


  doRequestWithoutBearer(endpointurl: string,
    method: string,
    data?: any,
    params?: any,
    headers?: HttpHeaders, hashOptions?: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(data),
      params: params
    }
    return this.httpClient
      .request<JsonResponse>(method, this.commonService.BASE_URL_ASSET + `${endpointurl}`, httpOptions)
      .pipe(
        map(response => this.handleResponse(response))
      );
  }
  reqObj: any;
  error_msg = 'Something went wrong.. Please try again later';
  lookTypeIds = {
    'truckType': 'cb2d84dd-d4f3-4eeb-9cd5-3702b6409a66',
    'capacity': '9366cafd-1757-49eb-a80e-e38d9facf289',
    'goodsType': 'f7734b79-3f4d-4726-b831-d070df8a22a6',
    'fuelType': '30761b64-37b4-41ed-a079-d2fa1b911418',
    'DerivedAlerts': 'e4f06c2f-7c1a-11eb-8e2b-005056af269b',
    'PrimaryAlerts': '52f15621-7c17-11eb-8e2b-005056af269b',
    'MasterCargotrip': '9eef0055-ed47-11ec-b322-000c295d0053',
    'VoiceBoxModels':"0b416cf5-fdc4-11ec-8822-000c295d0053",
    'PortableKey':"7036c98a-f0cc-11ed-ae95-000c29cbb699",
    'netradyneKey':"b5443b04-fed0-11ed-afa2-000c29c7189d",
    'loc_enable': "fa1b2116-1c07-11ee-b9d6-000c29a442fb"
  }
  default_lat = 17.3850;
  default_long = 78.4867;
  emptyDataValue = "";
  menusItems: any = [];

  JIORequest(endpointurl: string,
    method: string,
    data?: any,
    params?: any,
    headers?: HttpHeaders, hashOptions?: any) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        //'Authorization': "Bearer " + "",
        'Authorization': "Bearer " + sessionStorage.getItem("JIO_TOKEN"),
      }),
      body: JSON.stringify(data),
      params: params
    }
    return this.httpClient
      .request<JsonResponse>(method, this.commonService.BASE_URL_IOT + `${endpointurl}`, httpOptions)
      .pipe(
        map(response => this.jiohandleResponse(response))
      );
  }

  private handleResponse(response: JsonResponse) {

    //  alert("Hi")
    if (response.hasOwnProperty("sts")) {
      if (response.sts === 401) {
        // this.authService.logout();
        // this.router.navigate(['/login']);
        // location.reload();
        this.router.navigateByUrl('auth');
        sessionStorage.clear();

      }
      else {
        return response;
      }
    }
    else {
      return response;
    }
  }

  private jiohandleResponse(response: JsonResponse) {
    //debugger;
    //  alert("JIO-Hi")
    return response;
  }

  //Getting Jio Access Token using Jio Refresh Token
  refreshToken() {
    const IOT_httpOptions = {
      headers: new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/x-www-form-urlencoded' })
    };
    let JIOdata = "refresh_token=" + sessionStorage.getItem("JIO_REFRESHTOKEN") + "&grant_type=refresh_token&client_id=apiOne&client_secret=apiOne";
    return this.httpClient.post(this.commonService.BASE_URL_IOT + 'oauth/token', JIOdata, IOT_httpOptions);
  }
  epochUTCtoReadableUTC(epochDate) {
    return new Date(epochDate);

  }

  getDefaultLat() {
    return sessionStorage.getItem('DEFAULT_LATITUDE');
  }

  getDefaultLng() {
    return sessionStorage.getItem('DEFAULT_LONGITUDE');
  }

  getDefaultZoom() {
    return Number(sessionStorage.getItem('DEFAULT_ZOOM'));
  }
  getAssetDetailsData() {
    return new Promise((resolve, reject) => {
      let reqestObj = {
        "userID": this.commonService.getLoginUserID(),
        "clientID": this.commonService.getClientID()
      };
      this.doRequest("Tracker/v5/GetAssetDetail", 'post', reqestObj).subscribe(response => {
        resolve(response)
      }, error => {
        this.loaderService.display(false);
        this.loaderService.spinner(false);
        //console.log(error.message)
      })
    });
  }
  // let archiveReport 
  getQueryParams(boolValue){
  return this.archiveReport = boolValue
  }
//   getArchiveReport(){
//     debugger
// return this.archiveReport
//   }


  getAssetDetailsData_v1() {
    return new Promise((resolve, reject) => {
      let reqestObj = {
        "userID": this.commonService.getLoginUserID(),
        "clientID": this.commonService.getClientID()
      };
      this.doRequest("Tracker/v5/GetAssetDetail", 'post', reqestObj).subscribe(response => {
        // //console.log("response of new api"+JSON.stringify(response));
        resolve(response)
      }, error => {
        this.loaderService.display(false);
        this.loaderService.spinner(false);
        //console.log(error.message)
      })
    });
  }
  getAssetDetailsData_v2(userId) {
    return new Promise((resolve, reject) => {
      let reqestObj = {
        "userID": userId!=''?userId:this.commonService.getLoginUserID(),
        "clientID": this.commonService.getClientID()
      };
      this.doRequest("Tracker/v6/GetAssetDetail", 'post', reqestObj).subscribe(response => {
        // //console.log("response of new api"+JSON.stringify(response));
        resolve(response)
      }, error => {
        this.loaderService.display(false);
        this.loaderService.spinner(false);
        //console.log(error.message)
      })
    });
  }




  registerOrUpdateSIM(reguestObj: any) {
    return new Promise((resolve, reject) => {
      this.doRequest('Tracker/v1/deviceRegister', 'post', reguestObj).subscribe(response => {
        resolve(response)
      }, error => {
        this.loaderService.display(false);
        this.loaderService.spinner(false);
        //console.log(error.message)
      })
    });
  }

  cancelRegisterSIM(reguestObj: any) {
    return new Promise((resolve, reject) => {
      this.doRequest('Tracker/deviceUnRegister', 'post', reguestObj).subscribe(response => {
        resolve(response)
      }, error => {
        this.loaderService.display(false);
        this.loaderService.spinner(false);
        //console.log(error.message)
      })
    });
  }
  getDropDownsData(data) {
    return new Promise((resolve, reject) => {
      let reqestObj = {
        "userID": this.commonService.getLoginUserID(),
        "clientID": this.commonService.getClientID(),
        "lookUpType": data // data is array  example ["HRCHY"]
      };
      this.doRequest("JME/configuration/list", 'post', reqestObj).subscribe(response => {
        resolve(response)
      }, error => {
        //console.log(error.message)
      })
    });
  }

  
  getLookUpTypes(data) {

    let body: any = {
      "userID": sessionStorage.getItem('LOGIN_USER_ID'),
      "clientID": sessionStorage.getItem('CLIENT_ID'),
      "lookUpType": data
    }
    return new Promise((resolve, reject) => {
      let bufferRadiusList = [];
      this.doRequest("comman/list", 'post', body).subscribe((result: any) => {

        if (result.status == 200) {
          result.data.forEach(element => {
            bufferRadiusList.push({
              name: element.Descr,
              value: element.TypeValue
            })
          });
          resolve(bufferRadiusList);
        }
      }, error => {
        resolve(bufferRadiusList);
        //console.log(error.message);
      })
    });

  }

  getvehicleData(data) {
    return new Promise((resolve, reject) => {
      let reqestObj =
      {
        "vType": data.vType,
        "vManufacturer": data.vManufacturer,
        "vModel": data.vModel
      };
      this.doRequest("JME/Configuration/v1/vehicleOEM/VehicleDropdown", 'post', reqestObj).subscribe(response => {
        resolve(response)
      }, error => {
        //console.log(error.message)
      })
    });
  }

  methodStartsWith(tackerdetails, toasterMessage) {
    if (tackerdetails.startsWith("0") || tackerdetails.startsWith("1") || tackerdetails.startsWith("2")
      || tackerdetails.startsWith("3") || tackerdetails.startsWith("4") || tackerdetails.startsWith("5")
      || tackerdetails.startsWith("6") || tackerdetails.startsWith("7") || tackerdetails.startsWith("8")
      || tackerdetails.startsWith("9")
    ) {
      this.toaster.warning(toasterMessage);
      return true;
    }
    else {
      return false;
    }
  }

  nameLengthCheck(txt: string) {
    if (txt.length >= this.commonService.NAME_LENGTH_MIN && txt.length <= this.commonService.NAME_LENGTH_MAX) {
      return true;
    } else {
      return false;
    }
  }

  pasteEvent(event, name): any {
    if (event.match(/^[0-9]+$/g) || event.match(/^[ ]+$/g)) {
      this.toaster.warning(name + " " + 'should not be numeric/spaces');
      return false;
    }
    else if (event.startsWith(" ")) {
      this.toaster.warning(name + " " + 'should not starts with spaces');
      return false;
    }
    else if (event.length > 30) {
      this.toaster.warning(name + " " + 'should not exceed 30 characters');
      return false;
    }
    else if (event.match(/^[a-zA-Z0-9 ]+$/)) {
      return true;
    }
    else {
      this.toaster.warning('Special characters not allowed');
      return false;
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (String(email).toLowerCase().match(re)) {
      return true;
    } else {
      return false;
    }
  }

  getMenusDetails() {
    return new Promise((resolve, reject) => {
      let reqestObj = {
        "userID": this.commonService.getLoginUserID()
      };
      this.doRequest("Geofence/GetMenus", 'post', reqestObj).subscribe(response => {
        let data: any = response;
        this.menuData = data.menuDetails;
        //console.log("menus", this.menuData )
        resolve(data.menuDetails)
        // //console.log("menus", this.menuData )
      }, error => {
        //console.log(error.message)
      })
    });
  }


  getReadAlertsData(reqestObj: any) {
    try {
      return new Promise((resolve, reject) => {
        this.JIORequest_B2B(this.commonService.BASE_URL_IOT_B2B, 'post', reqestObj).subscribe(response => {
          resolve(response)
        }, error => {
          this.loaderService.display(false);
          this.loaderService.spinner(false);
          //console.log(error.message)
          resolve('')
        })
      });
    } catch (error) {
      this.loaderService.display(false);
      this.loaderService.spinner(false);
      //console.log(error.message)
    }
  }

  getNetradyneAlertsData(reqestObj: any) {
    try {
      return new Promise((resolve, reject) => {
        this.doRequest('report/booking/v1/getalerts', 'post', reqestObj).subscribe(response => {
          resolve(response)
        }, error => {
          this.loaderService.display(false);
          this.loaderService.spinner(false);
          //console.log(error.message)
          resolve('')
        })
      });
    } catch (error) {
      this.loaderService.display(false);
      this.loaderService.spinner(false);
      //console.log(error.message)
    }
  }
  booleanify = (value: string): boolean => {
    const truthy: string[] = [
      'true',
      'True',
      '1'
    ]

    return truthy.includes(value)
  }

  getDerivedAlertsData(reqestObj: any) {
    try {
      return new Promise((resolve, reject) => {
        this.doRequest('jhsDashboard/v2/get-report-alerts', 'post', reqestObj).subscribe(response => {
          resolve(response)
        }, error => {
          this.loaderService.display(false);
          this.loaderService.spinner(false);
          //console.log(error.message)
          resolve('')
        })
      });
    } catch (error) {
      this.loaderService.display(false);
      this.loaderService.spinner(false);
      //console.log(error.message)
    }
  }
  getDerivedAlertsList(reqestObj: any) {
    try {
      return new Promise((resolve, reject) => {
        this.doRequest('jhsDashboard/v3/get-derived-alerts', 'post', reqestObj).subscribe(response => {
          resolve(response)
        }, error => {
          this.loaderService.display(false);
          this.loaderService.spinner(false);
          //console.log(error.message)
          resolve('')
        })
      });
    } catch (error) {
      this.loaderService.display(false);
      this.loaderService.spinner(false);
      //console.log(error.message)
    }
  }

  sendPanicAcknowledged(userName: any, pdID: any) {
    try {
      return new Promise((resolve, reject) => {
        //provisioning/dongle/users/{username}/devices/{pdid}
        this.JIORequest('provisioning/dongle/users/' + userName + '/devices/' + pdID + '?command=PANIC_BUTTON_RESET', 'post').subscribe(response => {
          resolve(response)
        }, error => {
          this.loaderService.display(false);
          this.loaderService.spinner(false);
          //console.log(error.message)
          resolve('')
        })
      });
    } catch (error) {
      this.loaderService.display(false);
      this.loaderService.spinner(false);
      //console.log(error.message)
    }
  }
  setGetIgnitionStatus(userName: any, pdID: any,command: any, jsonData: any) {
    try {
      return new Promise((resolve, reject) => {
        //provisioning/dongle/users/{username}/devices/{pdid}
        this.loaderService.display(true)
        this.JIORequest('provisioning/dongle/users/' + userName + '/devices/' + pdID + '?command='+command, 'post', jsonData).subscribe(response => {
          this.loaderService.display(false)
          resolve(response)
        }, error => {
          this.loaderService.display(false);
          this.loaderService.spinner(false);
          //console.log(error.message)
          resolve('')
          this.toaster.error(error.message);
        })
      });
    } catch (error) {
      this.loaderService.display(false);
      this.loaderService.spinner(false);
      //console.log(error.message)
      this.toaster.error(error.message);
    }
  }

//Getting Jio Access Token using Jio Refresh Token - Enterprise admin
refreshTokenForEnterprise(username,passwaord) {
  const IOT_httpOptions = {
    headers: new HttpHeaders({ 'Accept': 'application/json', 'Content-Type': 'application/x-www-form-urlencoded' })
  };
  let jsonString = "username=" + username + "&password=" + passwaord + "&grant_type=password&client_secret=apiOne&client_id=apiOne";
  return this.httpClient.post(this.commonService.BASE_URL_IOT + 'oauth/token', jsonString, IOT_httpOptions);
}

/** Common API for SIM tracking vehicles moving and stopped status info - 18/01/2022 -4R.1 */
getSIMTrackersStatus() {
  return new Promise((resolve, reject) => {
      let todayDate = new Date();
      let endDateSet = new Date();
      let fromDate = new Date(todayDate.setHours(0, 0, 0, 0));
      let toDate = new Date(endDateSet.setHours(23, 59, 59, 999));
      let reqObj: any = {
        adminId: sessionStorage.getItem("ADMIN_EMAIL_ID"),
        clientId: sessionStorage.getItem("CLIENT_ID"),
        fromDate: fromDate,
        toDate: toDate,
      }
    this.doRequest("tracker/getSimTrackingStatus", 'post', reqObj).subscribe(response => {
      resolve(response)
    }, error => {
      this.loaderService.display(false);
      this.loaderService.spinner(false);
      //console.log(error.message)
    })
  });
}

  doRequestJioToken(endpointurl: string,
    method: string,
    data?: any,
    params?: any,
    headers?: HttpHeaders, hashOptions?: any) {
    const httpOptions = {
      headers: headers ? headers : new HttpHeaders({
        'Content-Type': 'application/json',
      }),

      body: !data ? {} : data,
      params: params
    }

    return this.httpClient
      .request<JsonResponse>(method, this.commonService.Geo_Loc_Api + `${endpointurl}`, httpOptions)
      .pipe(
        map(response => this.handleResponse(response))
      );
  }

GetToken(obj: any) {
  try {
    return new Promise(async (resolve, reject) => {
      this.doRequestJioToken("/apikeyservicev2/v2/apikeys", 'post', obj).subscribe((result: any) => {
        if (result.data) {
          resolve({ status: 200, message: "Success", data: result.data })
        }
        else {
          //winstonService.errorLog("apikeyservicev2/v2/apikeys catch block in service.ts : " + "No token received");
          //console.log("apikeyservicev2/v2/apikeys catch block in service.ts : No token received");
          resolve({ status: 204, message: "No data available", data: "" })
        }
      }), error => {
        this.loaderService.display(false);
        this.loaderService.spinner(false);
        //console.log(error.message)
      }

    })

  }
  catch (e) {
    //console.log("getJioToken catch block in service.ts");
    return ({ status: 500, message: "Something went wrong", data: "" })
  }
}

  doRequestJioAddress(endpointurl: string,
    method: string,
    token: string,
    data?: any,
    params?: any,
    headers?: HttpHeaders, hashOptions?: any) {
    const httpOptions = {
      headers: headers ? headers : new HttpHeaders({
        'Content-Type': 'application/json',
        "token": token,
      }),

      body: !data ? {} : data,
      params: params
    }

    return this.httpClient
      .request<JsonResponse>(method, this.commonService.Geo_Loc_Api + `${endpointurl}`, httpOptions)
      .pipe(
        map(response => this.handleResponse(response))
      );
  }

  // locationAPI = (token, obj: any) => {
  //   return new Promise(async (resolve, reject) => {
  //     this.doRequestJioAddress("/jiolocate/street-address/v1/get", 'post', token, obj).subscribe((result: any) => {
  //       if (result.status == 200) {
  //         //console.log("/jiolocate/street-address/v1/get : data available");

  //         resolve({ status: 200, message: "Success", data: result.address })
  //       }
  //       else {
  //         //console.log("/jiolocate/street-address/v1/get : No data available");
  //         resolve({ status: 204, message: "No data available", data: "" })
  //       }
  //     }), error => {
  //       this.loaderService.display(false);
  //       this.loaderService.spinner(false);
  //       //console.log(error.message)
  //     }
  //   });
  // }
  locationAPI = (token, obj: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.doRequestJioAddress("/jiolocate/street-address/v1/get", 'post', token, obj).subscribe((result: any) => {
          try {
            if (result?.status == 200) {
              //console.log("/jiolocate/street-address/v1/get : data available");

              resolve({ status: 200, message: "Success", data: result.address })
            }
            else {
              //console.log("/jiolocate/street-address/v1/get : No data available");
              resolve({ status: 204, message: "No data available", data: "" })
            }
          } catch (error) {
            this.loaderService.display(false);
            this.loaderService.spinner(false);
            //console.log(error.message)
            resolve({ status: 204, message: "Somthing Went Wrong", data: "" })
          }

        },
          error => {
            this.loaderService.display(false);
            this.loaderService.spinner(false);
            //console.log(error.message)
            resolve({ status: 204, message: "Somthing Went Wrong", data: "" })
          }),
          error => {
            this.loaderService.display(false);
            this.loaderService.spinner(false);
            //console.log(error.message)
            resolve({ status: 204, message: "Somthing Went Wrong", data: "" })
          }
      } catch (error) {
        this.loaderService.display(false);
        this.loaderService.spinner(false);
        //console.log(error.message)
        resolve({ status: 204, message: "Somthing Went Wrong", data: "" })
      }

    });
  }

}





