<div class="row">
    <div class="col-md-12">
        <div class="page-breadcrumb">
            <p>
                <i class="fas fa-arrow-left" title="Go back to Aggregate View" (click)="backmethod()" *ngIf="!isHistotyData"></i>
                <i class="fas fa-arrow-left" title="Go back to Cargo Trips" (click)="backmethodCargo()" *ngIf="isHistotyData"></i>
                <span style="font-size: 14px !important;">Tracking > </span>
                <span style="font-weight:600;color:#424242;">SIM Track Live</span>
                <!-- <button class="btn btn-inverse float-right" title="Go back to aggregate view" type="button"
                    (click)="backmethod()" *ngIf="!isHistotyData">
                    <i class="fas fa-arrow-left"></i>
                </button>
                <button class="btn btn-inverse float-right" title="Go back to cargo trips" type="button"
                    (click)="backmethodCargo()" *ngIf="isHistotyData">
                    <i class="fas fa-arrow-left"></i>
                </button> -->
            </p>
        </div>
    </div>
</div>
<div class="row" style="margin:10px 0">
    <div class="col-md-4 trip-media" *ngIf="sliderShow">
        <div class="card_container">
            <div class="row sim-tra">
                <select id="ddlTimer" [(ngModel)]="selectedTimer" (change)="timerCallData()"
                    class="form-control custom_drop">
                    <option value="00">Off</option>
                    <option selected="selected" value="900">15 Minute</option>
                    <option value="1800">30 Minute</option>
                    <option value="2700">45 Minute</option>
                    <option value="3600">60 Minute</option>
                </select>
                <button class="btn btn-success" style="margin-left: 0.80rem;"
                    (click)="displaySimMap(simData)">
                    <i class="fa fa-refresh"></i>
                </button>
            </div>
            <div style="background-color: #54617a;color: #fff;">
                <div class="TableHeader" style="padding:10px;">
                    {{isHistotyData ? 'Trip Details':'Sim Location'}}
                </div>
                <div class="TableHeader1">
                    <a style="cursor:pointer;">
                        <img src='./assets/images/playWhite.svg' id="ResetPlay" style="display:none;">
                    </a>
                </div>
            </div>
            <div id="divTripwise" class="trips-scroll">
                <div class="trip_card">
                    <div class="cargo-trip" *ngIf="!isHistotyData">
                        <h5>Vehicle</h5>
                        <table>
                            <tr>
                                <th>Registration Number</th>
                                <td>{{simObject.vehiclenumber}}</td>

                            </tr>
                            <tr>
                                <th>Vehicle TagName</th>
                                <td>{{vehicleName}}</td>

                            </tr>
                        </table>
                        <hr>
                        <h5>Driver</h5>
                        <table>
                            <tr>
                                <th>Driver Name</th>
                                <td>{{simObject.drivername}}</td>

                            </tr>
                            <tr>
                                <th>Driver Mobile No.</th>
                                <td>{{simObject.drivermobile}}</td>

                            </tr>
                            <tr>
                                <th>Sim TrackTime</th>
                                <td>{{simObject.simtrackdatetime}}</td>

                            </tr>

                        </table>
                    </div>
                    <div class="cargo-trip" *ngIf="isHistotyData">
                        <table>
                            <tr>
                                <th> <span Class="originTrip"><img src="assets/images/flag-green.svg"
                                    alt="" width="15px">
                                    </span> Origin </th>
                                <td>
                                    <div class="table_location_height">{{simData.source ? simData.source.addr : ''}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th> <span Class="destination"><img src="assets/images/flag-red.svg"
                                    alt="" width="15px">
                                    </span> Destination</th>
                                <td>
                                    <div class="table_location_height">{{simData.destination ? simData.destination.addr
                                        : ''}}</div>
                                </td>
                            </tr>
                        </table>
                        <hr>
                        <h5>Mobile</h5>
                                        <table>
                                            <tr>
                                                <th>Phone.</th>
                                                <td>{{simObject.switch ? (simObject.switch.status ? "Switched On" : "Switched Off"):""}}</td>

                                            </tr>
                                            <tr>
                                                <th>Network</th>
                                                <td>{{simObject.carrier}}</td>

                                            </tr>
                                        </table>
                                        <hr>
                        <h5>Vehicle</h5>
                        <table>
                            <tr>
                                <th>Trip Id</th>
                                <td>{{simData.bookingid}}</td>

                            </tr>
                            <tr *ngIf="simObject.referenceid != undefined">
                                <th>Ref Id</th>
                                <td>{{simData.referenceid}}</td>

                            </tr>
                            <tr>
                                <th>Registration Number</th>
                                <td>{{simData.vehicle ? simData.vehicle.regno : ''}}</td>

                            </tr>
                            <tr>
                                <th>Vehicle TagName</th>
                                <td>{{simData.vehicle ? simData.vehicle.tagname : ''}}</td>

                            </tr>
                        </table>
                        <hr>
                        <h5>Driver</h5>
                        <table>
                            <tr>
                                <th>Name</th>
                                <td>{{simData.driver ? simData.driver.name : ''}}</td>

                            </tr>
                            <tr>
                                <th>Mobile Number</th>
                                <td>{{simData.driver ? simData.driver.mno : ''}}</td>

                            </tr>
                        </table>
                        <hr>
                        <h5>Customer</h5>
                        <table>
                            <tr>
                                <th>Name</th>
                                <td>{{simData.customer ? simData.customer.name : ''}}</td>

                            </tr>
                            <tr>
                                <th>Mobile Number</th>
                                <td>{{simData.customer ? simData.customer.mno : ''}}</td>

                            </tr>
                            <tr>
                                <th>Sim TrackTime</th>
                                <td>{{simObject.simtrackdatetime}}</td>

                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-8" [ngClass]="sliderShow ? 'col-md-8' : 'col-md-12'"
        style="transition: visibility 0s linear 0.33s, opacity 0.33s linear;transition-delay: 0s;padding: 0;">
        <div class="card_container">
            <div class="map_header">
                <span (click)="sliderShow=!sliderShow" class="map_menu">
                    <i class="fa fa-bars"></i>
                </span>

                <span> Map</span>
            </div>
            <div #mapDiv class="maprespHeight" [hidden]="simData.type=='04'"></div>

            <div *ngIf="simData.type=='04'">
                <div class="maprespHeight">
                    <agm-map #agmMapId [latitude]="17.123184" [longitude]="79.208824" [fullscreenControl]="true"
                        (mapReady)="onMapReady($event)" [mapTypeControl]="true"
                        [fitBounds]="markersArray.length > 0 ? true:false">
                        <agm-buffer-direction [origin]="direction.origin" [destination]="direction.destination"
                            [renderOptions]="renderOptions" [waypoints]="waypoints" [widthBuffer]="widthBuffer"
                            [listenChanges]="listenChanges" [visible]="visible">
                        </agm-buffer-direction>
                        <agm-polygon [paths]="directionBuffer" [editable]="false" [fillColor]="'#3658b1'"
                            [strokeColor]="'#3658b1'" [polyDraggable]="false" [strokeWeight]="1">
                        </agm-polygon>
                        <agm-marker (markerClick)="markerClick(infowindow)" *ngFor="let i of markersArray" [latitude]="i.lat" [longitude]="i.lng"
                            [agmFitBounds]="true" [iconUrl]="i.iconUrl">
                                <agm-info-window #infowindow [closeWhenOthersOpen]="true">
                                    <div class="iw-container" style="width: 200px;">
                                        <div class="iw-title">
                                            <table>
                                                <tr>
                                                    <td>{{ i.id }} </td>
                                                    <td>{{ i.dName }}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div class="iw-content">
                                            <table>
                                                <tr>
                                                    <td><img src="./assets/images/info-window/timer.svg" width="20"></td>
                                                    <td>:</td>
                                                    <td>{{i.time}}</td>
                                                </tr>
                                                <tr>
                                                    <td><img src="./assets/images/info-window/location.svg" width="20"></td>
                                                    <td>:</td>
                                                    <td>{{i.latlng}}</td>
                                                </tr>
                                            </table>
                                        </div>
    
                                    </div>
                        </agm-info-window>
                        </agm-marker>
                            <agm-polyline>
                                <ng-container *ngFor="let point of latLangs">
                                    <agm-polyline-point [latitude]="point.lat" [longitude]="point.lng">
                                    </agm-polyline-point>
                                    <agm-icon-sequence [strokeColor]="'#FF0000'" [repeat]="'10px'" [offset]="0"
                                        [strokeWeight]="3" [scale]="2" [strokeOpacity]="100" [path]="'M 0,-1 0,1'">
                                    </agm-icon-sequence>
                                    <!-- <ng-container *ngIf="point[i+1]">
                                    <agm-polyline-point [latitude]="point[i+1].lat" [longitude]="point[i+1].lng">
                                    </agm-polyline-point>
                                </ng-container> -->
                                </ng-container>
                            </agm-polyline>
                    </agm-map>
                </div>
            </div>
        </div>
    </div>

</div>