import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { HeaderComponent } from './@them/header/header.component';
// import { FooterComponent } from './@them/footer/footer.component';
import * as $ from 'jquery';
import { NgApexchartsModule } from "ng-apexcharts";
import { SharedModule } from './shared/shared.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { TitleCasePipe } from '@angular/common';
import { DatePipe } from '@angular/common';
import { AppConfigService } from './services/app-config-service';
import { NgxAlphabetsOnlyDirective } from './directives/ngx-alphabets-only.directive';
import { NoSpacesAllowedFirstDirective } from './directives/no-spaces-allowed-first.directive';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxTreeDndModule } from 'ngx-tree-dnd';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableExporterModule } from 'mat-table-exporter';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ViewpopupComponent } from './popups/viewpopup/viewpopup.component';

// import { AgmCoreModule } from '@agm/core';
// import { AgmBufferDirectionModule } from './agm-buffer-direction/agm-buffer-direction.module';




library.add(fas);

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.loadAppConfig()
  };
}
 
// declare module "@angular/core" {
//   interface ModuleWithProviders<T = any> {
//     ngModule: Type<T>;
//     providers?: Provider[];
//   }
// }


@NgModule({
  declarations: [
    AppComponent,
    NgxAlphabetsOnlyDirective,
    NoSpacesAllowedFirstDirective,
    ViewpopupComponent,
  
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    MatTableExporterModule,
    NgMultiSelectDropDownModule.forRoot(),
    SharedModule,
    FontAwesomeModule,
    MatDialogModule,
    NgxTreeDndModule,
    BackButtonDisableModule.forRoot(),
    PdfViewerModule

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [TitleCasePipe, DatePipe,
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService]
    }],
  bootstrap: [AppComponent]
})
export class AppModule { 
 
}
