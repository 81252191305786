import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as data from './../../assets/data/appconfiguration.json';

@Injectable()
export class AppConfigService {
    private appConfig;
    constructor(private http: HttpClient) { }

    loadAppConfig() {
        this.appConfig = data;
        // return this.http.get('./assets/data/appconfiguration.json').toPromise().then(data => {
        //     this.appConfig = data;
        // })
    }

    getConfig() {
        return this.appConfig.default;
    }


}
