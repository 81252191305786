import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal/modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { AcceptFirstCharacterDirective } from '../directives/accept-first-character.directive';
import { AlphaNumericsDirective } from '../directives/alpha-numerics.directive';
import { CheckArrowsDirective } from '../directives/check-arrows.directive';
import { DateTimeFromatCheckDirective } from '../directives/date-time-format-check.directive';
import { DateTimeFormatDirective } from '../directives/date-time-format.directive';
import { EmailcheckDirective } from '../directives/emailcheck.directive';
import { LatLonCheckDirective } from '../directives/lat-lon-check.directive';
import { LoginWithMobileCheckDirective } from '../directives/loginwithmobilecheck.directive'
import { MobilecheckDirective } from '../directives/mobilecheck.directive';
import { NoNumberAllowedFirstDirective } from '../directives/no-number-allowed-first.directive';
import { NoSpacesAllowedDirective } from '../directives/no-spaces-allowed.directive';
import { NumberOnlyDirective } from '../directives/number-only.directive';
import { StrongPasswordDirective } from '../directives/strong-password.directive';
import { CopyPasteNameTextDirective } from '../directives/copy-paste-name-text.directive';
import { NoNumericsDirective } from '../directives/NoNumericsDirective';
import { AppFilterPipeModule } from '../filters/app.filter-pipe.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { OnlyNumbersAllowedDirective } from '../directives/only-numbers-allowed.directive';
import { DateFormatCustomPipePipe } from '../directives/date-format-custom-pipe.pipe';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RecaptchaModule } from 'ng-recaptcha';
import { SimTrackCommonComponent } from './sim-track-common/sim-track-common.component';
import { AppConfigService } from '../services';
import { AgmCoreModule } from '@agm/core';
import { AgmBufferDirectionModule } from '../pages/master/agm-buffer-direction/agm-buffer-direction.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AcceptOnlyNumbersDirective } from '../directives/accept-only-numbers.directive';
import { FileViewerModalComponent } from './file-viewer-modal/file-viewer-modal.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { EpodDocComponent } from './epod-doc/epod-doc.component';
import { ngxAlphaNumericWithCommaDirective } from '../directives/ngx-alphanumericwithcama.directive';
import { CopyPasteAlphaNumericWithCommaDirective } from '../directives/copy-paste-Alphanumericwithcomma';
import { ScrollTopService } from '../services/scrollTop.service';
import { DateRangeCalendarComponent } from './date-range-calendar/date-range-calendar.component';
import { TimeFormatIn12Hours } from '../filters/app.filter.pipe';

var config: any = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.getConfig()
  };
}

@NgModule({
  declarations: [
    ModalComponent,
    SimTrackCommonComponent,
    NumberOnlyDirective,
    EmailcheckDirective,
    MobilecheckDirective,
    LatLonCheckDirective,
    StrongPasswordDirective,
    AcceptFirstCharacterDirective,
    AlphaNumericsDirective,
    CheckArrowsDirective,
    NoSpacesAllowedDirective,
    DateTimeFormatDirective,
    DateTimeFromatCheckDirective,
    NoNumberAllowedFirstDirective,
    CopyPasteNameTextDirective,
    NoNumericsDirective,
    ngxAlphaNumericWithCommaDirective,
    CopyPasteAlphaNumericWithCommaDirective,
    OnlyNumbersAllowedDirective,
    AcceptOnlyNumbersDirective,
    LoginWithMobileCheckDirective,
    DateFormatCustomPipePipe,
    FileViewerModalComponent,
    EpodDocComponent,
    DateRangeCalendarComponent,
    TimeFormatIn12Hours

  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatMenuModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    AppFilterPipeModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      maxOpened: 1,
      autoDismiss: true
    }),
    FlexLayoutModule,
    RecaptchaModule,
    AgmCoreModule.forRoot({
      apiKey: config.google_key, libraries: ["places"]
    }),
    AgmBufferDirectionModule, // agm-buffer-direction
    NgxDaterangepickerMd.forRoot(),
    PdfViewerModule
  ],
  exports: [
    ModalComponent,
    SimTrackCommonComponent,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatIconModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatButtonToggleModule,
    ToastrModule, // ToastrModule added
    NumberOnlyDirective,
    EmailcheckDirective,
    MobilecheckDirective,
    LatLonCheckDirective,
    LoginWithMobileCheckDirective,
    StrongPasswordDirective,
    AcceptFirstCharacterDirective,
    AlphaNumericsDirective,
    CheckArrowsDirective,
    NoSpacesAllowedDirective,
    DateTimeFormatDirective,
    DateTimeFromatCheckDirective,
    DateFormatCustomPipePipe,
    NoNumberAllowedFirstDirective,
    CopyPasteNameTextDirective,
    NoNumericsDirective,
    ngxAlphaNumericWithCommaDirective,
    CopyPasteAlphaNumericWithCommaDirective,
    AppFilterPipeModule,
    MatAutocompleteModule,
    FlexLayoutModule,
    OnlyNumbersAllowedDirective,
    AcceptOnlyNumbersDirective,
    RecaptchaModule,
    AgmCoreModule,
    AgmBufferDirectionModule, // agm-buffer-direction
    NgxDaterangepickerMd,
    MatCheckboxModule,
    FileViewerModalComponent,
    EpodDocComponent,
    PdfViewerModule,
    DateRangeCalendarComponent,
    TimeFormatIn12Hours
  ], providers: [
    ScrollTopService],
})
export class SharedModule { }
