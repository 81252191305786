import { Directive, Input, Output, OnChanges, OnInit, EventEmitter, SimpleChanges } from '@angular/core';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { LoaderService } from 'src/app/services';

/* JSTS import angular-cli.json like */
declare var jsts: any;
declare var google: any;

@Directive({
  selector: 'agm-buffer-direction'
})
export class AgmBufferDirection implements OnChanges, OnInit {

  @Input() origin: { lat: Number, lng: Number };
  @Input() destination: { lat: Number, lng: Number };
  @Input() waypoints: any[] = [];
  @Input() travelMode: string = 'DRIVING';
  @Input() optimizeWaypoints: boolean = true;
  @Input() visible: boolean;
  @Input() renderOptions: any;
  @Input() panel: object | undefined;
  @Input() widthBuffer: number = 0;
  @Input() listenChanges: boolean;
  @Output() getWaypoints = new EventEmitter();

  public directionsService: any = undefined;
  public directionsDisplay: any = undefined;
  data = {};
  listenCustom: any;

  constructor(private gmapsApi: GoogleMapsAPIWrapper, private loaderService: LoaderService) { }

  ngOnInit() { }
  ngOnChanges(changes: SimpleChanges) {

    if (!this.visible) {
      if (this.directionsDisplay) {
        this.directionsDisplay.setMap(null);
        this.directionsDisplay = undefined;
      }
    }
    //When origin and destination come from modal windows
    if (this.listenChanges && changes.origin && changes.destination) {
      //console.log("listenChanges 01  ");
      this.directionDraw();
    }
    //If use buffer in modal window, must to concatenate all "IF" and call "this.directionDraw()" once.
    //When changed weightBuffer we make new directionDraw for calculate buffer coords    
    if (changes.widthBuffer && changes.widthBuffer.currentValue !== changes.widthBuffer.previousValue) {
      //console.log("listenChanges 02  ");

      this.directionDraw();
    }
  }
  // This event is fired when the user creating or updating this direction
  private directionDraw() {
    try {
      var that = this;
      if (this.origin && this.destination) {
        if (this.origin.lat && this.origin.lng && this.destination.lat && this.destination.lng) {
          if (that.widthBuffer) {
            that.loaderService.display(true);
          }
          this.gmapsApi.getNativeMap().then(map => {
            if (typeof this.directionsDisplay === 'undefined') {
              this.directionsDisplay = new google.maps.DirectionsRenderer(this.renderOptions);
              this.directionsDisplay.setMap(map);
            }
            if (typeof this.directionsService === 'undefined') {
              this.directionsService = new google.maps.DirectionsService;
            }
            if (typeof this.panel === 'undefined') {
              this.directionsDisplay.setPanel(null);
            } else {
              this.directionsDisplay.setPanel(this.panel);
            }



            this.directionsService.route({
              origin: this.origin,
              destination: this.destination,
              waypoints: this.waypoints,
              optimizeWaypoints: this.optimizeWaypoints,
              travelMode: this.travelMode
            }, (response: any, status: any) => {
              if (status === 'OK') {

                this.directionsDisplay.setDirections(response);


                async function loaddirection() {
                  try {
                    var w = [], wp;
                    var route = response.routes[0];
                    /* Get leg !ONLY 1 LEG! because in all waypoints "stopover: false".
                      If you want "stopover: true" you must edit cicle for (dowm) */
                    var rleg = that.directionsDisplay.directions.routes[0].legs;//[0];
                    var overviewPathGeo = [];
                    let start, end, distance = 0, duration = 0;
                    for (let i = 0; i < rleg.length; i++) {
                      distance = distance + parseInt(rleg[i].distance.value);
                      duration = duration + parseInt(rleg[i].duration.value);

                      if (i == 0) {
                        start = { 'lat': rleg[i].start_location.lat(), 'lng': rleg[i].start_location.lng() }
                      }

                      if (rleg.length - 1 == i) {
                        end = { 'lat': rleg[i].end_location.lat(), 'lng': rleg[i].end_location.lng() }
                      }
                      if (i > 0 && i < rleg.length - 1) {
                        w.push({
                          location: { lat: rleg[i].start_location.lat(), lng: rleg[i].start_location.lng() },
                          stopover: true
                        });
                      }
                      for (let j = 0; j < rleg[i].steps.length; j++) {
                        var nextSegment = rleg[i].steps[j].path;
                        for (let k = 0; k < nextSegment.length; k++) {
                          overviewPathGeo.push(
                            [nextSegment[k].lng(), nextSegment[k].lat()]
                          );
                        }
                      }
                    }

                    async function generatePolygon(overviewPath: any[]) {
                      try {
                        if (overviewPath && overviewPath.length >= 0) {
                          if (that.widthBuffer) {
                            //console.log(" loaderService start .. ");
                            that.loaderService.display(true);
                          }
                          // var last_width_long = 200; //var
                          var distance = that.widthBuffer / 1000;  //en km.
                          distance = (distance / 111.12);
                          var geoInput = {
                            type: "LineString",
                            coordinates: overviewPath
                          };

                          //console.log("JSTS Calling ...." + new Date());
                          /* BLACK MAGIC IN JSTS! THANKS YOU! */
                          var geometry = await new jsts.io.GeoJSONReader().read(geoInput).buffer(distance);
                          var polygon = await new jsts.io.GeoJSONWriter().write(geometry);
                          //console.log("JSTS Completed ...." + new Date());
                          // if (that.widthBuffer) {
                          //console.log(" loaderService End .. ");
                          that.loaderService.display(false);
                          // }

                          var areaCoordinates = []; // Array for output of ploygon's coordinates
                          /* SWAP PARAMETERS from "lng,lat" to "lat,lng" and make type "LatLngLiteral" */
                          for (let i = 0; i < polygon.coordinates[0].length; i++) {
                            var coordinate = polygon.coordinates[0][i];
                            areaCoordinates.push({ lat: coordinate[1], lng: coordinate[0] });
                          }
                          return areaCoordinates;
                        } else return false;

                      } catch (err) {
                        that.loaderService.display(false);
                        //console.log(" Error in catch generatePolygon() .. " + err.message);
                      }
                    };

                    function getTime(seconds) {
                      var days = Math.floor(seconds / (3600 * 24));
                      seconds -= days * 3600 * 24;
                      var hrs = Math.floor(seconds / 3600);
                      seconds -= hrs * 3600;
                      var mnts = Math.floor(seconds / 60);
                      seconds -= mnts * 60;
                      return (days >= 1 ? days + " day " : "") + (hrs >= 1 ? hrs + " hour " : "") + mnts + " mins"
                    }

                    let distanceKms = (distance / 1000).toFixed(1) + " kms";
                    let durationTime = getTime(duration);


                    that.data = { // Generate response
                      start: start,
                      end: end,
                      waypoints: w,
                      polygon: await generatePolygon(overviewPathGeo),
                      direction: overviewPathGeo,
                      distance: {
                        text: distanceKms, value: distance
                      },
                      duration: {
                        text: durationTime, value: duration
                      }
                    };
                    that.getWaypoints.emit(that.data);
                  } catch (err) {
                    that.loaderService.display(false);
                    //console.log(" Error in catch loaddirection() .. " + err.message);
                  }
                }
                // loaddirection();

                if (that.listenCustom) that.listenCustom.remove();
                that.listenCustom = this.directionsDisplay.addListener('directions_changed', function () {
                  //console.log("directions_changed")
                  loaddirection();
                });


              }
            });


          });
        }
      }
    }
    catch (err) {
      that.loaderService.display(false);
      //console.log(" Error in catch directionDraw() .. " + err.message);
    }
  }
}
