import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BaseService, JioMotiveService, LoaderService } from 'src/app/services';

@Component({
  selector: 'app-epod-doc',
  templateUrl: './epod-doc.component.html',
  styleUrls: ['./epod-doc.component.scss']
})
export class EpodDocComponent implements OnInit {

  @Input() tripObj: any = [];
  @Input() contype: number = 1;
  constructor(private jioMotiveService: JioMotiveService,
    private toastrService: ToastrService,
    private loaderService: LoaderService,
    private baseService: BaseService) { }

  async ngOnInit() {
    await this.getEPODDocs();
    if (this.multFilesList.length > 0) {
      this.openDocView(this.multFilesList[0]);
    }
  }

  getEPODDocs() {
    this.loaderService.display(true);

    let data = {
      "bookingid": this.tripObj.bookingid
    }
    try {
      return new Promise(async (resolve, reject) => {
        this.baseService.doRequest('customer/booking/v1/getEpodDocs', 'post', data).subscribe((res) => {
          let result: any = res;
          this.multFilesList = [];

          if (result.status == 200) {
            let res: any = result.data.data;
            if (res.length > 0) {
              this.multFilesList = res[0].epod[0].docs;
            }
          }
          resolve(true);
          this.loaderService.display(false);
        }, error => {
          resolve(true);
          this.loaderService.display(false);
        });
      });
    } catch (ex) {
      ex = null;
      this.loaderService.display(false);
    }

  }

  MAX_COUNT = 10;
  multFilesList: any = [];
  async onSelectFileAndImage(event) {
    let total_size = this.multFilesList.length + event.target.files.length;
    if (total_size <= this.MAX_COUNT) {
      let arr: any = event.target.files;
      for (var i = 0; i < arr.length; i++) {
        try {
          let file = arr[i];
          let extension = file.type;
          let filename = file.name.toString();
          let isDup = this.multFilesList.filter(x => x.fname == filename);
          if (isDup.length == 0) {
            if (file.size <= this.jioMotiveService.MAX_FILE_SIZE_10MB) {
              if (extension == "image/png" || extension == "image/jpeg" || extension == "image/jpg" || extension == "application/pdf") {

                let formData = new FormData();
                formData.append("multi_docs", file);

                let resArray: any = await this.multipleFilesUpload(formData);

                if (resArray.length > 0) {
                  let obj = this.formatEPODdocObject(file, resArray[0])

                  ///API call to insert file in MongoDb against tripID

                  let cargoRes: any = await this.saveFiletoCargoTrip(obj);
                  if (cargoRes.status == 200) {

                    await this.getEPODDocs();

                    this.openDocView(this.multFilesList[this.multFilesList.length - 1]);
                    this.toastrService.success(cargoRes.msg);
                  } else {
                    this.toastrService.warning(cargoRes.msg);
                  }
                }

              } else {
                this.toastrService.warning('File format is not allowed');
              }
            }
            else {
              this.toastrService.warning(this.jioMotiveService.MAX_FILE_MSG_10MB);
            }
          } else {
            this.toastrService.warning('Duplicate file found');
          }
        } catch (e) {
          e = null;
        }
      }
    } else {
      this.toastrService.warning("Can not upload more than 10 documents");
      event.srcElement.value = "";
    }
  }
  formatEPODdocObject(file: any, chunckRes) {
    let currentts = new Date(new Date()).getTime();

    let obj: epodDetails = {
      "bookingid": this.tripObj.bookingid,
      "adminid": sessionStorage.getItem('ADMIN_EMAIL_ID'),
      "createdby": sessionStorage.getItem('USEREMAIL'),
      "createdts": currentts,
      "epod": [{
        "loctype": 1,
        "tch_id": "",
        "docs": [{
          "fname": file.name,
          "fsize": file.size,
          "ftype": chunckRes.filetype,
          "fpath": chunckRes.filename,
          "comm": "",
          "fsts": 1,
          "autoaprv": 1,
          "utype": sessionStorage.getItem('USER_TYPE'),
          "updby": sessionStorage.getItem('USEREMAIL'),
          "driver": {
            "mno": "",
            "name": ""
          },
          "aprv": {
            "name": sessionStorage.getItem('USEREMAIL'),
            "ts": currentts
          },
          "rjct": {
            "name": "",
            "ts": ""
          },
          "updts": currentts
        }
        ]
      }]
    }

    return obj;
  }
  saveFiletoCargoTrip(obj: any): any {
    return new Promise(async (resolve, reject) => {
      try {
        this.baseService.doRequest('customer/booking/v1/insertEpodDoc', 'post', obj).subscribe((res) => {
          if (res) {
            this.responseArray = res;
            resolve(this.responseArray);
          }
        }, error => {
          this.toastrService.error(error.message);
          resolve([]);
        });
      } catch (ex) {
        ex = null;
      }
    })
  }

  responseArray: any;
  multipleFilesUpload(req) {
    return new Promise(async (resolve, reject) => {
      try {

        this.baseService.doRequestFormData('customer/booking/v1/upload-multiple-images', 'post', req).subscribe(async (res) => {
          this.responseArray = res;
          if (this.responseArray.status == 200) {
            resolve(this.responseArray.data);
          } else {
            resolve([]);
          }
          this.loaderService.display(false);
        }, error => {
          this.loaderService.display(false);
        });
      } catch (e) {
        e = null;
        this.loaderService.display(false);
      }

    })
  }

  selectedDoc: any;
  fileType = '';
  fileSRC = '';
  openDocView(doc) {
    this.actiontype = 0;
    this.fileType = doc.ftype === '.pdf' ? 'PDF' : 'IMG';
    this.fileSRC = this.jioMotiveService.UPLOADS_COLLECTION_URL_ASSET + doc.fpath;
    this.selectedDoc = doc;
  }

  actiontype = 0;
  actionOnDoc(doc, val) {
    this.actiontype = val;
    if (val == 2 && doc.comm == '') {
      this.toastrService.warning('Please enter comment')
      return false;
    }
    let currentts = new Date(new Date()).getTime();

    let obj = {
      "name": sessionStorage.getItem('USEREMAIL'),
      "ts": currentts
    }

    let empty_obj = {
      "name": '',
      "ts": ''
    }

    doc.fsts = val;
    doc.aprv = val == 1 ? obj : empty_obj;
    doc.rjct = val == 2 ? obj : empty_obj;

    this.loaderService.display(true);

    let data = {
      "bookingid": this.tripObj.bookingid,
      "driverMno": this.tripObj.driver.mno,
      "vehicle": this.tripObj.vehicle.regno,
      "source": this.tripObj.source.addr,
      "destination": this.tripObj.destination.addr,
      "docID": doc._id,
      "fsts": doc.fsts,
      "aprv": doc.aprv,
      "rjct": doc.rjct,
      "tch_id": this.touchpt_id,
      "comm": doc.comm,
      "docName":doc.fname
    }
    try {
      this.baseService.doRequest('customer/booking/v1/actionOnEpodDocs', 'post', data).subscribe(async (res) => {
        let result: any = res;
        this.multFilesList = [];

        if (result.status == 200) {
          await this.getEPODDocs();
          this.openDocView(doc);
          this.toastrService.success(result.msg);
        }
        else {
          this.toastrService.warning(result.msg);
        }
        this.loaderService.display(false);
      }, error => {
        this.loaderService.display(false);
      });
    } catch (e) {
      e = null;
      this.loaderService.display(false);
    }
  }

  @ViewChild('pdfviewModel', { static: true }) pdfviewModel: any;
  openPDFonModel() {
    this.pdfviewModel.show();
  }

  @ViewChild('deleteModel') deleteModel: any;
  deletedObj: any;
  deleteConformation(obj) {
    this.deletedObj = obj;
    this.deleteModel.show();
  }

  touchpt_id: any = "";
  removeDoc() {
    this.loaderService.display(true);
    let data = {
      "bookingid": this.tripObj.bookingid,
      "docID": this.deletedObj._id,
      "loctype": this.touchpt_id == "" ? 1 : 2,
      "tch_id": this.touchpt_id
    }
    try {
      this.baseService.doRequest('customer/booking/v1/removeEpodDoc', 'post', data).subscribe(async (res) => {
        let result: any = res;
        this.multFilesList = [];

        if (result.status == 200) {
          this.toastrService.success(result.msg);
          this.fileType = '';
          this.fileSRC = '';
          this.selectedDoc = undefined;
          await this.getEPODDocs();

          if (this.multFilesList.length > 0) {
            this.openDocView(this.multFilesList[0]);
          }
        }
        else {
          this.toastrService.warning(result.msg);
        }

        this.loaderService.display(false);
      }, error => {
        this.loaderService.display(false);
      });
    } catch (e) {
      e = null;
      this.loaderService.display(false);
    }
  }
}


export class epodDetails {
  "bookingid": string;
  "adminid": string;
  "createdby": string;
  "createdts": number;
  "epod": [{
    "loctype": number;
    "tch_id": string;
    "docs": [{
      "fname": string;
      "fsize": string;
      "ftype": string;
      "fpath": string;
      "comm": string;
      "fsts": number;
      "autoaprv": number;
      "utype": string;
      "updby": string;
      "driver": {
        "mno": string;
        "name": string;
      },
      "aprv": {
        "name": string;
        "ts": any;
      },
      "rjct": {
        "name": string;
        "ts": any;
      },
      "updts": number;
    }
    ]
  }]
}