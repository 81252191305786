import { Injectable } from '@angular/core';
import * as Excel from "exceljs/dist/exceljs.min.js";
import * as ExcelProper from "exceljs";
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common';
import { merge } from 'rxjs';
import { JioMotiveService } from './jio-motive.service';
import { async } from 'rxjs/internal/scheduler/async';
import moment from 'moment';
import { align } from '@progress/kendo-drawing';
import { getCenter } from 'geolib';
const datePipe = new DatePipe('en-US');

@Injectable({
  providedIn: 'root'
})
export class ExportExcelService {

  name: string;
  sName: string;
  fileName: string;
  excelfileName: string;
  footer: string = "@2019 kunta naresh"
  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  colArray = [];
  constructor(private commonService: JioMotiveService) {
  }

  applyRowStyle(sheet) {
    sheet.eachRow(function (row, rowNumber) {
      if (rowNumber > 3) {
        row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
          sheet.getCell(cell.address.toString()).alignment = { wrapText: true, vertical: 'middle', horizontal: 'middle' }
          sheet.getCell(cell.address.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
          sheet.getCell(cell.address.toString()).font = {
            name: 'Tahoma',
            family: 2,
            size: 9
          }
        });
      }
    });
    return sheet;
  }

  applyRowStyleWithRange(sheet) {
    sheet.eachRow(function (row, rowNumber) {
      if (rowNumber > 4) {
        row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
          sheet.getCell(cell.address.toString()).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
          sheet.getCell(cell.address.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
          sheet.getCell(cell.address.toString()).font = {
            name: 'Tahoma',
            family: 2,
            size: 9
          }
        });
      }
    });
    return sheet;
  }

  exportExcelFile(fileName: string, headerText: any, columnKeys: any, columnHeaders: any, jsonDataObj: any) {

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    // if (dd < 10) {
    //   dd = '0' + dd;
    // }
    // if (mm < 10) {
    //   mm = '0' + mm;
    // }
    var newtoday = dd + '/' + mm + '/' + yyyy;

    this.excelfileName = fileName +' '+ newtoday;
    this.sName = fileName;
    var workbook = new Excel.Workbook();
    workbook.creator = "AssetTL";
    workbook.lastModifiedBy = "AssetTL";
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })
    var sheet = workbook.getWorksheet(1);
    var data1 = headerText;//["Export data"]
    sheet.addRow(data1);
    var reportDate = ['Report Time : ' + this.commonService.dateConvertToClientFormat(new Date())]
    sheet.addRow(reportDate);
    sheet.getRow(3).values = columnHeaders;
    sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }]
    this.colArray = this.colArrayFunction(columnHeaders);// ['A3', 'B3', 'C3', 'D3', 'E3']
    sheet.addRows(jsonDataObj);
    sheet = this.applyRowStyle(sheet);

    //Row 1
    sheet.getCell('A1', 'B1', 'C1').font = {
      name: 'Tahoma',
      family: 2,
      size: 12,
      bold: true,
      color: { argb: '1E90FF' }
    }

    sheet.getCell('A1', 'B1', 'C1').alignment = {
      indent: 45
    }
    sheet.getCell('A3').border = {
      top: { style: 'double', color: { argb: '1E90FF' } },
      left: { style: 'double', color: { argb: '1E90FF' } },
      bottom: { style: 'double', color: { argb: '1E90FF' } },
      right: { style: 'double', color: { argb: '1E90FF' } }
    };
    //Row 2
    sheet.getCell('A2', 'B2', 'C2').font = {
      name: 'Tahoma',
      family: 2,
      size: 9,
      bold: true,
      color: { argb: '1E90FF' },
    }
    sheet.getCell('A2', 'B2', 'C2').alignment = {
      indent: 65
    }
    this.colArray.map(key => {
      // sheet.getCell(key).fill={
      //   type:'gradient',
      //   gradient:'angle',
      //   degree:0,
      //   stops:[
      //     {position:0, color:{argb:'#454545'}},
      //     {position:0.5, color:{argb:'#454545'}},
      //     {position:1, color:{argb:'#454545'}},
      //   ]
      // }
      sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }
    });
    //sheet.addRow([this.footer])
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, this.excelfileName + '.xlsx', true);
    });
  }

  exportExcelFrFuelGraph(fileName: string, headerText: any, columnKeys: any, columnHeaders: any, jsonDataObj: any, vehicledata: any, graphDate: any) {
    var today = new Date();
    var dd: any = today.getDate();
    var mm: any = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    var hh = today.getHours();
    var mins = today.getMinutes();
    var sec = today.getSeconds();
    var newtoday = '_' + dd + '_' + mm + '_' + yyyy + '_' + hh + '_' + mins + '_' + sec;
    this.excelfileName = fileName + newtoday + '.xlsx';
    this.sName = fileName;
    var workbook = new Excel.Workbook();
    workbook.creator = "AssetTL";
    workbook.lastModifiedBy = "AssetTL";
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })
    var sheet = workbook.getWorksheet(1);
    var data1 = headerText;
    sheet.addRow(data1);
    var reportDate = ['Report Time : ' + this.commonService.dateConvertToClientFormat(new Date())]
    sheet.addRow(reportDate);
    sheet.addRow(['Report For : ' + moment(graphDate).format("DD/MM/YYYY")]);
    sheet.addRow([
      `Vehicle Details : `, `Vehicle Name : ${vehicledata.name}`, `Reg.No : ${vehicledata.regno}`, `Type : ${vehicledata.type}`, `Make : ${vehicledata.make}`, `Model : ${vehicledata.model}`,
    ]);
    sheet.getRow(5).values = columnHeaders;
    sheet.columns = columnKeys;
    this.colArray = ['A5', 'B5', 'C5']
    sheet.addRows(jsonDataObj);
    sheet = this.applyRowStyle(sheet);

    sheet.getCell('A1', 'B1', 'C1').font = {
      name: 'Tahoma',
      family: 2,
      size: 12,
      bold: true,
      color: { argb: '1E90FF' }
    }

    sheet.getCell('A1', 'B1', 'C1').alignment = {
      indent: 45
    }

    sheet.getCell('A2', 'B2', 'C2').font = {
      name: 'Tahoma',
      family: 2,
      size: 9,
      bold: true,
      color: { argb: '1E90FF' },
    }
    sheet.getCell('A2', 'B2', 'C2').alignment = {
      indent: 65
    }
    sheet.getCell('A3', 'B3', 'C3').alignment = {
      indent: 65
    }
    sheet.getCell('A3', 'B3', 'C3').font = {
      name: 'Tahoma',
      family: 2,
      size: 9,
      bold: true,
      color: { argb: '1E90FF' },
    }
    sheet.getCell('A4').font = {
      name: 'Tahoma',
      family: 2,
      size: 8,
      bold: true
    }
    this.colArray.map(key => {
      sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }
    });

    sheet.getRow(4).eachCell((cell, colNumber) => {
      let column = sheet.getColumn(colNumber);
      const lengths = column.values.map(v => v.toString().length);
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'));
      column.width = maxLength;
    });

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, this.excelfileName + '.xlsx', true);
    });
  }

  exportExcelFileWithourHeaders(fileName: string, columnKeys: any, columnHeaders: any, filedata: any, headercolor: any) {
    let workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet();
    let columns = [];

    if (columnHeaders.length === columnKeys.length) {
      columnKeys.forEach((ele, index) => {
        columns.push(
          { header: columnHeaders[index], key: ele.key, width: ele.width },
        )
      })
    }

    worksheet.columns = columns;
    worksheet.addRows(filedata);

    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: headercolor }
    }

    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, fileName + '.xlsx', true);
    });
  }

  exportUserData(fileName: string, headerText: any, columnKeys: any, columnHeaders: any, jsonDataObj: any, moduleCnt: any) {

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    // if (dd < 10) {
    //   dd = '0' + dd;
    // }
    // if (mm < 10) {
    //   mm = '0' + mm;
    // }
    var newtoday = dd + '/' + mm + '/' + yyyy;

    this.excelfileName = fileName + newtoday;
    this.sName = fileName;
    var workbook = new Excel.Workbook();
    workbook.creator = "AssetConnect";
    workbook.lastModifiedBy = "AssetConnect";
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })
    var sheet = workbook.getWorksheet(1);
    var data1 = headerText;//["Export data"]
    sheet.addRow(data1);
    var reportDate = ['Report Time : ' + this.commonService.dateConvertToClientFormat(new Date())]
    sheet.addRow(reportDate);
    sheet.getRow(3).values = columnHeaders;
    sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }];
    this.colArray = this.colArrayFunction(columnHeaders);// ['A3', 'B3', 'C3', 'D3', 'E3']
    sheet.addRows(jsonDataObj);
    sheet = this.applyRowStyle(sheet);

    //Row 1
    sheet.getCell('A1', 'B1', 'C1').font = {
      name: 'Tahoma',
      family: 2,
      size: 12,
      bold: true,
      color: { argb: '1E90FF' }
    }

    sheet.getCell('A1', 'B1', 'C1').alignment = {
      indent: 45
    }
    sheet.getCell('A3').border = {
      top: { style: 'double', color: { argb: '1E90FF' } },
      left: { style: 'double', color: { argb: '1E90FF' } },
      bottom: { style: 'double', color: { argb: '1E90FF' } },
      right: { style: 'double', color: { argb: '1E90FF' } }
    };
    //Row 2
    sheet.getCell('A2', 'B2', 'C2').font = {
      name: 'Tahoma',
      family: 2,
      size: 9,
      bold: true,
      color: { argb: '1E90FF' },
    }
    sheet.getCell('A2', 'B2', 'C2').alignment = {
      indent: 65
    }

    let reportsCnt = 0; let tripsCnt = 0; let settingsCnt = 0; let configureCnt = 0; 
    let dashboardCnt = 0; let trackingCnt = 0;let fleetCnt = 0;let aisCnt = 0;
    let billCnt = 0; let fieldopCnt = 0;let alertCnt = 0;
    if (moduleCnt.length != 0) {
      moduleCnt.forEach(ele => {
        if (ele.ModuleName == "Reports")
          reportsCnt = ele.ModuleCount;
        if (ele.ModuleName == "Planning")
          tripsCnt = ele.ModuleCount;
        if (ele.ModuleName == "Settings")
          settingsCnt = ele.ModuleCount;
        if (ele.ModuleName == "Configure")
          configureCnt = ele.ModuleCount;
        if (ele.ModuleName == "Dashboard")
          dashboardCnt = ele.ModuleCount;
        if (ele.ModuleName == "Tracking")
          trackingCnt = ele.ModuleCount;
        if(ele.ModuleName == "My Fleet")
          fleetCnt = ele.ModuleCount;
        if(ele.ModuleName == "AIS140")
          aisCnt = ele.ModuleCount;
        if(ele.ModuleName == "Billing")
          billCnt = ele.ModuleCount;  
        if(ele.ModuleName == "Field Operation")
          fieldopCnt = ele.ModuleCount;
        if(ele.ModuleName == "Alerts")
          alertCnt = ele.ModuleCount;  
      });
    }

    //**Name and role wise Cell mergeing */
    let groupedNamWise = this.groupBy(jsonDataObj, pet => pet.email)
    let itemsLength = groupedNamWise.size;
    let groups = jsonDataObj.map(i => i.email);
    let uniqueNameGroups = Array.from(new Set(groups));
    var cnt = 4;
    var tCount = 0
    var rCnt = 0;
    var cCnt = 0;
    let incCount = 1;
    let mdlCnt = 3
    let rc = 0; let tc = 0; let sc = 0; let cc = 0; let dc = 0; let trkc = 0; 
    let fc = 0; let ac = 0; let bc = 0; let fic = 0; let alc = 0;
    let rr = 0; let tr = 0; let sr = 0; let cr = 0; let dr = 0; let trkr = 0; 
    let fr = 0; let ar = 0; let br = 0; let fir = 0; let alr = 0;

    for (let i = 0; i < itemsLength; i++) {
      try {
        let UName = uniqueNameGroups[i];
        let featureLength = groupedNamWise.get(UName).length - 1;
        let j = incCount++;
        let r = reportsCnt
        if (i == 0) {
          tCount = cnt + featureLength
          sheet.mergeCells(4, 1, tCount, 1);
          sheet.mergeCells(4, 2, tCount, 2);
          sheet.mergeCells(4, 3, tCount, 3);
          sheet.mergeCells(4, 4, tCount, 4);
          sheet.mergeCells(4, 5, tCount, 5);
          sheet.mergeCells(4, 6, tCount, 6);
          //         Dashboard
          // Tracking
          // Trips
          // Configure
          // Settings
          // Reports
          dc = 4  //dashboard clm
          dr = dc + dashboardCnt - 1;

          fc = dr + 1;  //My Fleet clm
          fr = fc + fleetCnt - 1;

          trkc = fr + 1;  //tracking
          trkr = trkc + trackingCnt - 1;

          tc = trkr + 1  //trip clm
          tr = tc + tripsCnt - 1;

          rc = tr + 1  //reports clm
          rr = rc + reportsCnt - 1

          cc = rr + 1  //confogure clm
          cr = cc + configureCnt - 1;
          sc = cr + 1;  //setting clm
          sr = sc + settingsCnt - 1;

          ac = sr + 1  //AIS140 clm
          ar = ac + aisCnt - 1;
          bc = ar + 1;  //billing clm
          br = bc + billCnt - 1;          
          fic = br + 1  //FieldOP
          fir = fic + fieldopCnt - 1;
          alc = fir + 1;  //Alert
          alr = alc + alertCnt - 1;

          sheet.mergeCells(4, 7, dr, 7); //dashbord 1
          sheet.mergeCells(fc, 7, fr, 7); //myfleet 1
          sheet.mergeCells(trkc, 7, trkr, 7); //tracking 3
          sheet.mergeCells(tc, 7, tr, 7);  //trips 2
          sheet.mergeCells(cc, 7, cr, 7);  // configure 7
          sheet.mergeCells(sc, 7, sr, 7);  //settings 3
          sheet.mergeCells(rc, 7, rr, 7);   //reports 8
          sheet.mergeCells(ac, 7, ar, 7);  //AIS140 
          sheet.mergeCells(bc, 7, br, 7);   //billing 
          sheet.mergeCells(fic, 7, fir, 7);  //field operation
          sheet.mergeCells(alc, 7, alr, 7);   //Alert 
          cCnt = tCount + 1;
          rCnt = cCnt + featureLength;
        } else {
          let k = j - i
          sheet.mergeCells(cCnt, 1, rCnt, 1);
          sheet.mergeCells(cCnt, 2, rCnt, 2);
          sheet.mergeCells(cCnt, 3, rCnt, 3);
          sheet.mergeCells(cCnt, 4, rCnt, 4);
          sheet.mergeCells(cCnt, 5, rCnt, 5);
          sheet.mergeCells(cCnt, 6, rCnt, 6);

          dc = cCnt //dashboard clm
          dr = dc + dashboardCnt - 1;
          fc = dr + 1;  //My Fleet clm
          fr = fc + fleetCnt - 1;
          trkc = fr + 1;  //tracking
          trkr = trkc + trackingCnt - 1;
          tc = trkr + 1  //trip clm
          tr = tc + tripsCnt - 1;
          rc = tr + 1  //reports clm
          rr = rc + reportsCnt - 1
          cc = rr + 1  //confogure clm
          cr = cc + configureCnt - 1;
          sc = cr + 1;  //setting clm
          sr = sc + settingsCnt - 1;
          ac = sr + 1  //AIS140 clm
          ar = ac + aisCnt - 1;
          bc = ar + 1;  //billing clm
          br = bc + billCnt - 1;
          fic = br + 1  //FieldOP
          fir = fic + fieldopCnt - 1;
          alc = fir + 1;  //Alert
          alr = alc + alertCnt - 1;
          sheet.mergeCells(dc, 7, dr, 7); //dashbord 1
          sheet.mergeCells(fc, 7, fr, 7); //myfleet 1
          sheet.mergeCells(trkc, 7, trkr, 7); //tracking 3
          sheet.mergeCells(tc, 7, tr, 7);  //trips 2
          sheet.mergeCells(cc, 7, cr, 7);  // configure 7
          sheet.mergeCells(sc, 7, sr, 7);  //settings 3
          sheet.mergeCells(rc, 7, rr, 7);   //reports 8
          sheet.mergeCells(ac, 7, ar, 7);  //AIS140 3
          sheet.mergeCells(bc, 7, br, 7);   //billing 8
          sheet.mergeCells(fic, 7, fir, 7);  //field operation
          sheet.mergeCells(alc, 7, alr, 7);   //Alert 

          cCnt = rCnt + k;
          rCnt = rCnt + featureLength + k;
        }
      } catch (err) {
        //console.log("errorin excel " + err.message);
      }
    }

    // sheet.mergeCells(4, 1, 5, 1);//Line 1 Column 2 Merge into row 5 of row 1

    this.colArray.map(key => {
      sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }
    });
    //sheet.addRow([this.footer])
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, this.excelfileName + '.xlsx', true);
    });
  }

  col: any = [];
  exportAsExcelFile(fileName: string, headerText: any, columnKeys: any, columnHeaders: any, jsonData: any) {

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();

    var newtoday = dd + '/' + mm + '/' + yyyy;

    this.excelfileName = fileName + newtoday;
    this.sName = fileName;
    var workbook = new Excel.Workbook();

    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })
    var sheet = workbook.getWorksheet(1);
    var data1 = headerText;//["Export data"]
    //sheet.addRow(data1);

    sheet.getRow(3).values = columnHeaders;
    sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }];
    this.col = this.colArrayFunction(columnHeaders);// ['A3', 'B3', 'C3', 'D3', 'E3']
    //sheet.addRows(jsonDataObj);
    sheet = this.applyRowStyle(sheet);


    let no = 2;
    let maxNo = 2000;
    let contractArr = '';
    jsonData.forEach(element => {
      contractArr += `${element.name + ","}`;
    });
    contractArr = contractArr.substr(0, contractArr.length > 255 ? 255 : contractArr.length - 1);
    while (no < maxNo) {
      sheet.getCell(this.getSpreadSheetCellNumber(no, 2)).dataValidation = {
        type: 'list',
        allowBlank: true,
        formulae: ['\"' + contractArr + '\"']
      };
      no++;
    }
    // ['"Selected,Rejected,On-hold"']

    this.col.map(key => {
      sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }
    });
    //Generate Excel File with given name
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, this.excelfileName + '.xlsx', true);
    });
  }
 
  private getSpreadSheetCellNumber(row, column) {
    let result = "";

    // Get spreadsheet column letter
    let n = column;
    while (n >= 0) {
      result = String.fromCharCode((n % 26) + 65) + result;
      n = Math.floor(n / 26) - 1;
    }

    // Get spreadsheet row number
    result += `${row + 1}`;

    return result;
  }

  exportRouteData(fileName: string, headerText: any, columnKeys: any, columnHeaders: any, jsonDataObj: any) {

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    var newtoday = dd + '/' + mm + '/' + yyyy;

    this.excelfileName = fileName + newtoday;
    this.sName = fileName;
    var workbook = new Excel.Workbook();
    workbook.creator = "AssetConnect";
    workbook.lastModifiedBy = "AssetConnect";
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })
    var sheet = workbook.getWorksheet(1);
    var data1 = headerText;//["Export data"]
    sheet.addRow(data1);
    var reportDate = ['Report Time : ' + this.commonService.dateConvertToClientFormat(new Date())]
    sheet.addRow(reportDate);
    sheet.getRow(3).values = columnHeaders;
    sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }];
    this.colArray = this.colArrayFunction(columnHeaders);// ['A3', 'B3', 'C3', 'D3', 'E3']
    sheet.addRows(jsonDataObj);
    sheet = this.applyRowStyle(sheet);

    //Row 1
    sheet.getCell('A1', 'B1', 'C1').font = {
      name: 'Tahoma',
      family: 2,
      size: 12,
      bold: true,
      color: { argb: '1E90FF' }
    }

    sheet.getCell('A1', 'B1', 'C1').alignment = {
      indent: 45
    }
    sheet.getCell('A3').border = {
      top: { style: 'double', color: { argb: '1E90FF' } },
      left: { style: 'double', color: { argb: '1E90FF' } },
      bottom: { style: 'double', color: { argb: '1E90FF' } },
      right: { style: 'double', color: { argb: '1E90FF' } }
    };
    //Row 2
    sheet.getCell('A2', 'B2', 'C2').font = {
      name: 'Tahoma',
      family: 2,
      size: 9,
      bold: true,
      color: { argb: '1E90FF' },
    }
    sheet.getCell('A2', 'B2', 'C2').alignment = {
      indent: 65
    }
    let reportsCnt = 0;
    //**Name and role wise Cell mergeing */
    let groupedNamWise = this.groupBy(jsonDataObj, pet => pet.routename)
    let itemsLength = groupedNamWise.size;
    let groups = jsonDataObj.map(i => i.routename);
    let uniqueNameGroups = Array.from(new Set(groups));
    var cnt = 4;
    var tCount = 0
    var rCnt = 0;
    var cCnt = 0;
    let incCount = 1;

    // for (let i = 0; i < itemsLength; i++) {
    //   let UName = uniqueNameGroups[i];
    //   let featureLength = groupedNamWise.get(UName).length - 1;
    //   let j = incCount++;
    //   let r = reportsCnt
    //   if (i == 0) {
    //     tCount = cnt + featureLength
    //     sheet.mergeCells(4, 1, tCount, 1);
    //     sheet.mergeCells(4, 2, tCount, 2);
    //     sheet.mergeCells(4, 3, tCount, 3);
    //     sheet.mergeCells(4, 4, tCount, 4);
    //     sheet.mergeCells(4, 5, tCount, 5);
    //     sheet.mergeCells(4, 6, tCount, 6);
    //     sheet.mergeCells(4, 7, tCount, 7);
    //     sheet.mergeCells(4, 8, tCount, 8);
    //     sheet.mergeCells(4, 9, tCount, 9);

    //     cCnt = tCount + 1;
    //     rCnt = cCnt + featureLength ;
    //   } else {

    //     let k = j - i
    //     sheet.mergeCells(cCnt, 1, rCnt, 1);
    //     sheet.mergeCells(cCnt, 2, rCnt, 2);
    //     sheet.mergeCells(cCnt, 3, rCnt, 3);
    //     sheet.mergeCells(cCnt, 4, rCnt, 4);
    //     sheet.mergeCells(cCnt, 5, rCnt, 5);
    //     sheet.mergeCells(cCnt, 6, rCnt, 6);
    //     sheet.mergeCells(cCnt, 7, rCnt, 7);
    //     sheet.mergeCells(cCnt, 8, rCnt, 8);
    //     sheet.mergeCells(cCnt, 9, rCnt, 9);

    //     cCnt = rCnt + k;
    //     rCnt = rCnt + featureLength + k;
    //   }
    // }

    this.colArray.map(key => {
      sheet.getCell(key).alignment = { vertical: 'top', horizontal: 'left', wrapText: true }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }
    });
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, this.excelfileName + '.xlsx', true);
    });
  }

  groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  // example usage
  // const pets = [
  //     {type:"Dog", name:"Spot"},
  //     {type:"Cat", name:"Tiger"},
  //     {type:"Dog", name:"Rover"}, 
  //     {type:"Cat", name:"Leo"}
  // ];

  exportExcelFileWithRange(fileName: string, headerText: any, columnKeys: any, columnHeaders: any, jsonDataObj: any, FrmDateReq: any, toDateReq: any, BranchName?: any, HubName?: any, status?: any) {

    var today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    let newtoday = dd + '/' + mm + '/' + yyyy;
    this.excelfileName = fileName + newtoday;
    this.sName = fileName;
    var workbook = new Excel.Workbook();
    workbook.creator = "AssetConnect";
    workbook.lastModifiedBy = "AssetConnect";
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })
    let sheet = workbook.getWorksheet(1);
    let data1 = headerText;//["Export data"]
    sheet.addRow(data1);

    let rowCount = 2;
    if (BranchName != null) {
      var branch = 'Branch Name : ' + BranchName;
      sheet.addRow([branch]);
      rowCount++;
    }
    if (HubName != null) {
      var hub = 'Hub Name : ' + HubName;
      sheet.addRow([hub]);
      rowCount++;
    }
    if (status != null) {
      var status1 = 'Status : ' + status;
      sheet.addRow([status1]);
      rowCount++;
    }


    let reportRange: any = '';
    if (toDateReq) {
      reportRange = 'Report Range' + ' : ' + FrmDateReq + ' - ' + toDateReq;
    }
    else if (FrmDateReq) {
      reportRange = 'Report Date' + ' : ' + this.commonService.dateTimeNoTime(FrmDateReq);
    }
    if (reportRange != '') {
      sheet.addRow([reportRange]);
      rowCount++;
    }

    let reportDate = ['Report Time : ' + this.commonService.dateConvertToClientFormat(new Date())]
    sheet.addRow(reportDate);
    rowCount++;

    sheet.getRow(rowCount).values = columnHeaders;
    sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }];
    // this.colArray = this.colArrayFunction(columnHeaders);// ['A3', 'B3', 'C3', 'D3', 'E3']
    if (columnKeys.length == 4) {
      this.colArray = ['A' + rowCount, 'B' + rowCount, 'C' + rowCount, 'D' + rowCount]
    }
    if (columnKeys.length == 5) {
      this.colArray = ['A' + rowCount, 'B' + rowCount, 'C' + rowCount, 'D' + rowCount, 'E' + rowCount]
    }
    if (columnKeys.length == 6) {
      this.colArray = ['A' + rowCount, 'B' + rowCount, 'C' + rowCount, 'D' + rowCount, 'E' + rowCount, 'F' + rowCount]
    }
    if (columnKeys.length == 7) {
      this.colArray = ['A' + rowCount, 'B' + rowCount, 'C' + rowCount, 'D' + rowCount, 'E' + rowCount, 'F' + rowCount, 'G' + rowCount]
    }

    if (columnKeys.length == 8) {
      this.colArray = ['A' + rowCount, 'B' + rowCount, 'C' + rowCount, 'D' + rowCount, 'E' + rowCount, 'F' + rowCount, 'G' + rowCount, 'H' + rowCount]
    }

    if (columnKeys.length == 9) {
      this.colArray = ['A' + rowCount, 'B' + rowCount, 'C' + rowCount, 'D' + rowCount, 'E' + rowCount, 'F' + rowCount, 'G' + rowCount, 'H' + rowCount, 'I' + rowCount]
    }

    if (columnKeys.length == 10) {
      this.colArray = ['A' + rowCount, 'B' + rowCount, 'C' + rowCount, 'D' + rowCount, 'E' + rowCount, 'F' + rowCount, 'G' + rowCount, 'H' + rowCount, 'I' + rowCount, 'J' + rowCount]
    }

    if (columnKeys.length == 11) {
      this.colArray = ['A' + rowCount, 'B' + rowCount, 'C' + rowCount, 'D' + rowCount, 'E' + rowCount, 'F' + rowCount, 'G' + rowCount, 'H' + rowCount, 'I' + rowCount, 'J' + rowCount, 'K' + rowCount]
    }
    if (columnKeys.length == 12) {
      this.colArray = ['A' + rowCount, 'B' + rowCount, 'C' + rowCount, 'D' + rowCount, 'E' + rowCount, 'F' + rowCount, 'G' + rowCount, 'H' + rowCount, 'I' + rowCount, 'J' + rowCount, 'K' + rowCount, 'L' + rowCount]
    }

    sheet.addRows(jsonDataObj);
    sheet = this.applyRowStyleWithRange(sheet);

    //Row 1--title
    sheet.getCell('A1', 'B1', 'C1').font = {
      name: 'Tahoma',
      family: 2,
      size: 12,
      bold: true,
      color: { argb: '1E90FF' }
    }

    sheet.getCell('A1', 'B1', 'C1', 'D1', 'E1').alignment = {
      indent: 45
    }
    if (rowCount >= 3) {
      //Row 2
      sheet.getCell('A2', 'B2', 'C2', 'D2', 'E2').font = {
        name: 'Tahoma',
        family: 2,
        size: 9,
        bold: true,
        color: { argb: '1E90FF' },
      }
      sheet.getCell('A2', 'B2', 'C2', 'D2', 'E2').alignment = {
        indent: 65
      }
    }
    if (rowCount >= 4) {
      //Row 3
      sheet.getCell('A3', 'B3', 'C3', 'D3', 'E3').font = {
        name: 'Tahoma',
        family: 2,
        size: 9,
        bold: true,
        color: { argb: '1E90FF' },
      }
      sheet.getCell('A3', 'B3', 'C3', 'D3', 'E3').alignment = {
        indent: 65
      }
    }
    if (rowCount >= 5) {
      //Row 4
      sheet.getCell('A4', 'B4', 'C4', 'D4', 'E4').font = {
        name: 'Tahoma',
        family: 2,
        size: 9,
        bold: true,
        color: { argb: '1E90FF' },
      }
      sheet.getCell('A4', 'B4', 'C4', 'D4', 'E4').alignment = {
        indent: 65
      }
    }
    if (rowCount >= 6) {
      //Row 5
      sheet.getCell('A5', 'B5', 'C5', 'D5', 'E5').font = {
        name: 'Tahoma',
        family: 2,
        size: 9,
        bold: true,
        color: { argb: '1E90FF' },
      }
      sheet.getCell('A5', 'B5', 'C5', 'D5', 'E5').alignment = {
        indent: 65
      }
    }
    if (rowCount >= 7) {
      //Row 6
      sheet.getCell('A6', 'B6', 'C6', 'D6', 'E6').font = {
        name: 'Tahoma',
        family: 2,
        size: 9,
        bold: true,
        color: { argb: '1E90FF' },
      }
      sheet.getCell('A6', 'B6', 'C6', 'D6', 'E6').alignment = {
        indent: 65
      }
    }

    this.colArray.map(key => {
      sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }
    });
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, this.excelfileName + '.xlsx', true);
    });
  }

  exportExcelPassengersTrips(fileName: string, headerText: any, columnKeys: any, columnHeaders: any, jsonDataObj: any) {

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();

    var newtoday = dd + '/' + mm + '/' + yyyy;

    this.excelfileName = fileName   + ' - ' +  newtoday;
    this.sName = fileName;
    var workbook = new Excel.Workbook();
    workbook.creator = "AssetConnect";
    workbook.lastModifiedBy = "AssetConnect";
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })
    var sheet = workbook.getWorksheet(1);
    var data1 = headerText;//["Export data"]
    sheet.addRow(data1);
    var reportDate = ['Report Time : ' + this.commonService.dateConvertToClientFormat(new Date())]
    sheet.addRow(reportDate);
    sheet.getRow(3).values = columnHeaders;
    sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }];
    this.colArray = this.colArrayFunction(columnHeaders);// ['A3', 'B3', 'C3', 'D3', 'E3']
    sheet.addRows(jsonDataObj);
    sheet = this.applyRowStyle(sheet);

    //Row 1
    sheet.getCell('A1', 'B1', 'C1').font = {
      name: 'Tahoma',
      family: 2,
      size: 12, //18
      bold: true,
      color: { argb: '1E90FF' }
    }

    sheet.getCell('A1', 'B1', 'C1').alignment = {
      indent: 70 //45
    }
    sheet.getCell('A3').border = {
      top: { style: 'double', color: { argb: '1E90FF' } },
      left: { style: 'double', color: { argb: '1E90FF' } },
      bottom: { style: 'double', color: { argb: '1E90FF' } },
      right: { style: 'double', color: { argb: '1E90FF' } }
    };
    //Row 2
    sheet.getCell('A2', 'B2', 'C2').font = {
      name: 'Tahoma',
      family: 2,
      size: 9,
      bold: true,
      color: { argb: '1E90FF' },
    }
    sheet.getCell('A2', 'B2', 'C2').alignment = {
      indent: 65
    }
    this.colArray.map(key => {

      sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }
    });
    //sheet.addRow([this.footer])
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, this.excelfileName + '.xlsx', true);
    });
  }

  colArrayFunction(columnHeadersObj: any) {
    switch (columnHeadersObj.length - 1) {
      case 1: return this.colArray = ['A3', 'B3']
      case 2: return this.colArray = ['A3', 'B3', 'C3']
      case 3: return this.colArray = ['A3', 'B3', 'C3', 'D3']
      case 4: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3']
      case 5: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3']
      case 6: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3']
      case 7: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3']
      case 8: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3']
      case 9: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3']
      case 10: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3']
      case 11: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3']
      case 12: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3']
      case 13: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3']
      case 14: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3']
      case 15: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3']
      case 16: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3']
      case 17: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3']
      case 18: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3']
      case 19: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3', 'T3']
      case 20: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3', 'T3', 'U3']
      case 21: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3', 'T3', 'U3', 'V3']
      case 22: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3', 'T3', 'U3', 'V3', 'W3']
      case 23: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3', 'T3', 'U3', 'V3', 'W3', 'X3']
      case 24: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3', 'T3', 'U3', 'V3', 'W3', 'X3', 'Y3']
      case 25: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3', 'T3', 'U3', 'V3', 'W3', 'X3', 'Y3', 'Z3']
      case 26: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3', 'T3', 'U3', 'V3', 'W3', 'X3', 'Y3', 'Z3', 'AA3']
      case 27: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3', 'T3', 'U3', 'V3', 'W3', 'X3', 'Y3', 'Z3', 'AA3', 'AB3']
      case 28: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3', 'T3', 'U3', 'V3', 'W3', 'X3', 'Y3', 'Z3', 'AA3', 'AB3', 'AC3']
      case 29: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3', 'T3', 'U3', 'V3', 'W3', 'X3', 'Y3', 'Z3', 'AA3', 'AB3', 'AC3', 'AD3']
      case 30: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3', 'T3', 'U3', 'V3', 'W3', 'X3', 'Y3', 'Z3', 'AA3', 'AB3', 'AC3', 'AD3', 'AE3']
      case 31: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3', 'T3', 'U3', 'V3', 'W3', 'X3', 'Y3', 'Z3', 'AA3', 'AB3', 'AC3', 'AD3', 'AE3','AF3']
      case 32: return this.colArray = ['A3', 'B3', 'C3', 'D3', 'E3', 'F3', 'G3', 'H3', 'I3', 'J3', 'K3', 'L3', 'M3', 'N3', 'O3', 'P3', 'Q3', 'R3', 'S3', 'T3', 'U3', 'V3', 'W3', 'X3', 'Y3', 'Z3', 'AA3', 'AB3', 'AC3', 'AD3', 'AE3','AF3','AG3']

    }
  }


  exportExcelGroupRowsFile(fileName: string, headerText: any, columnKeys: any, columnHeaders: any, mergeColumnKeys: any, mergeColumns: any, jsonDataObj: any, FrmDateReq: any, toDateReq: any, BranchName?: any, HubName?: any, status?: any) {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    var newtoday = dd + '/' + mm + '/' + yyyy;
    this.excelfileName = fileName + newtoday;
    this.sName = fileName;
    var workbook = new Excel.Workbook();
    workbook.creator = "AssetConnect ";
    workbook.lastModifiedBy = "AssetConnect ";
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName,
      {
        views: [
          {
            state: 'frozen',
            xSplit: 1,
            activeCell: 'A1',
            showGridLines: false
          }
        ]
      })


    var sheet = workbook.getWorksheet(1);
    var data1 = headerText;
    sheet.addRow(data1);


    if (BranchName != null) {
      var branch = 'Branch Name : ' + BranchName;
      sheet.addRow([branch]);
    }

    var reportRange = 'ReportRange ' + ' : ' + this.commonService.dateTimeNoTime(FrmDateReq) + ' - ' + this.commonService.dateTimeNoTime(toDateReq);
    sheet.addRow([reportRange]);

    var reportDate = ['Report Time : ' + this.commonService.dateConvertToClientFormat(new Date())]

    sheet.addRow(reportDate);
    sheet.getRow(5).values = columnHeaders;


    sheet.columns = columnKeys;
    this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5']// this.colArrayFunction(columnHeaders);


    var dupKey = "";
    var mergeRow = {};
    var mergeRowNumber = [];
    var mergeRowNextNumber = [];

    jsonDataObj.forEach(element => {
      if (dupKey === "" || dupKey != element[mergeColumnKeys[0]]) {
        dupKey = element[mergeColumnKeys[0]];

        for (let i = 0; i < columnKeys.length; i++) {
          mergeRow[columnKeys[i]['key']] = mergeColumns[i];
        }
        sheet.addRow(mergeRow);
        mergeRowNumber.push(sheet["rowCount"]);

        for (let i = 0; i < columnKeys.length; i++) {
          mergeRow[columnKeys[i]['key']] = element[mergeColumnKeys[i]];
        }
        sheet.addRow(mergeRow);
        mergeRowNextNumber.push(sheet["rowCount"]);
      }
      sheet.addRow(element);
    });

    sheet = this.applyRowAndMergeRowStyle(sheet, mergeRowNumber, mergeRowNextNumber);

    //Row 1
    sheet.getCell('A1', 'B1', 'C1').font = {
      name: 'Tahoma',
      family: 2,
      size: 12,
      bold: true,
      color: { argb: '1E90FF' }
    }

    sheet.getCell('A1', 'B1', 'C1').alignment = {
      indent: 50
    }
    sheet.getCell('A4').border = {
      top: { style: 'double', color: { argb: '1E90FF' } },
      left: { style: 'double', color: { argb: '1E90FF' } },
      bottom: { style: 'double', color: { argb: '1E90FF' } },
      right: { style: 'double', color: { argb: '1E90FF' } }
    };
    //Row 2
    sheet.getCell('A2', 'B2', 'C2').font = {
      name: 'Tahoma',
      family: 2,
      size: 9,
      bold: true,
      color: { argb: '1E90FF' },
    }
    sheet.getCell('A2', 'B2', 'C2').alignment = {
      indent: 65
    }

    // Row 3
    sheet.getCell('A3', 'B3', 'C3').font = {
      name: 'Tahoma',
      family: 2,
      size: 9,
      bold: true,
      color: { argb: '1E90FF' },
    }
    sheet.getCell('A3', 'B3', 'C3').alignment = {
      indent: 65
    }
    //Row 4
    sheet.getCell('A4', 'B4', 'C4').font = {
      name: 'Tahoma',
      family: 2,
      size: 9,
      bold: true,
      color: { argb: '1E90FF' },
    }
    sheet.getCell('A4', 'B4', 'C4').alignment = {
      indent: 65
    }

    this.colArray.map(key => {
      sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }
    });
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, this.excelfileName + '.xlsx', true);
    });
  }

  applyRowAndMergeRowStyle(sheet, mergeRowNumber, mergeRowNextNumber) {
    sheet.eachRow(function (row, rowNumber) {
      if (rowNumber > 5) {
        row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
          sheet.getCell(cell.address.toString()).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
          sheet.getCell(cell.address.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
          sheet.getCell(cell.address.toString()).font = {
            name: 'Tahoma',
            family: 2,
            size: 9
          }

          if (mergeRowNumber.includes(rowNumber)) {
            sheet.getCell(cell.address.toString()).font["bold"] = true;
            sheet.getCell(cell.address.toString()).font["color"] = { argb: '50b0d5' };//E3F0F1  
            sheet.getCell(cell.address.toString()).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'none' },
              right: { style: 'thin' },
            }
          }
          if (mergeRowNextNumber.includes(rowNumber)) {
            sheet.getCell(cell.address.toString()).font["bold"] = false;
            sheet.getCell(cell.address.toString()).font["color"] = { argb: '50b0d5' };//E3F0F1  
            sheet.getCell(cell.address.toString()).border = {
              top: { style: 'none' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            }
          }
        });
      }
    });
    return sheet;
  }

  exportExcelParentChild(fileName: string, headerText: any, columnKeys: any, columnHeaders: any, mergeColumnKeys: any, mergeColumns: any, jsonDataObj: any, FrmDateReq: any, toDateReq: any, BranchName?: any, HubName?: any, status?: any) {

    var today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    let newtoday = dd + '/' + mm + '/' + yyyy;
    this.excelfileName = fileName + newtoday;
    this.sName = fileName;
    var workbook = new Excel.Workbook();
    workbook.creator = "AssetConnect";
    workbook.lastModifiedBy = "AssetConnect";
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', xSplit: 1, ySplit: 5, activeCell: 'A1', showGridLines: false }] });

    let sheet = workbook.getWorksheet(1);
    let data1 = headerText;//["Export data"]
    sheet.addRow(data1);

    let rowCount = 2;
    if (BranchName != null) {
      var branch = 'Branch Name : ' + BranchName;
      sheet.addRow([branch]);
      rowCount++;
    }
    if (HubName != null) {
      var hub = 'Hub Name : ' + HubName;
      sheet.addRow([hub]);
      rowCount++;
    }
    if (status != null) {
      var status1 = 'Status : ' + status;
      sheet.addRow([status1]);
      rowCount++;
    }


    let reportRange: any = '';
    if (toDateReq) {
      reportRange = 'Report Range' + ' : ' + this.commonService.dateTimeNoTime(FrmDateReq) + ' - ' + this.commonService.dateTimeNoTime(toDateReq);
    }
    else if (FrmDateReq) {
      reportRange = 'Report Date' + ' : ' + this.commonService.dateTimeNoTime(FrmDateReq);
    }
    if (reportRange != '') {
      sheet.addRow([reportRange]);
      rowCount++;
    }

    let reportDate = ['Report Time : ' + this.commonService.dateConvertToClientFormat(new Date())]
    sheet.addRow(reportDate);
    rowCount++;

    sheet.getRow(rowCount).values = columnHeaders;
    sheet.columns = columnKeys;
    this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5']// this.colArrayFunction(columnHeaders);


    var dupKey = "";
    var mergeRow = [];
    var mergeRowNumber = [];
    var mergeRowNextNumber = [];

    jsonDataObj.forEach(element => {
      sheet.addRow(element);
      if (element.cargoList.length > 0) {
        sheet.addRow(mergeColumns);
        mergeRowNumber.push(sheet["rowCount"]);
        for (let i = 0; i < element.cargoList.length; i++) {
          mergeRow = [];
          mergeColumnKeys.forEach(col => {
            mergeRow.push(element.cargoList[i][col]);
          });
          sheet.addRow(mergeRow);
        }
      }
      sheet.addRow();
    });

    sheet = this.applyChildTableStyles(sheet, mergeRowNumber);

    //Row 1--title
    sheet.getCell('A1', 'B1', 'C1').font = {
      name: 'Tahoma',
      family: 2,
      size: 12,
      bold: true,
      color: { argb: '1E90FF' }
    }

    sheet.getCell('A1', 'B1', 'C1', 'D1', 'E1').alignment = {
      indent: 45
    }
    if (rowCount >= 3) {
      //Row 2
      sheet.getCell('A2', 'B2', 'C2', 'D2', 'E2').font = {
        name: 'Tahoma',
        family: 2,
        size: 9,
        bold: true,
        color: { argb: '1E90FF' },
      }
      sheet.getCell('A2', 'B2', 'C2', 'D2', 'E2').alignment = {
        indent: 65
      }
    }
    if (rowCount >= 4) {
      //Row 3
      sheet.getCell('A3', 'B3', 'C3', 'D3', 'E3').font = {
        name: 'Tahoma',
        family: 2,
        size: 9,
        bold: true,
        color: { argb: '1E90FF' },
      }
      sheet.getCell('A3', 'B3', 'C3', 'D3', 'E3').alignment = {
        indent: 65
      }
    }
    if (rowCount >= 5) {
      //Row 4
      sheet.getCell('A4', 'B4', 'C4', 'D4', 'E4').font = {
        name: 'Tahoma',
        family: 2,
        size: 9,
        bold: true,
        color: { argb: '1E90FF' },
      }
      sheet.getCell('A4', 'B4', 'C4', 'D4', 'E4').alignment = {
        indent: 65
      }
    }
    if (rowCount >= 6) {
      //Row 5
      sheet.getCell('A5', 'B5', 'C5', 'D5', 'E5').font = {
        name: 'Tahoma',
        family: 2,
        size: 9,
        bold: true,
        color: { argb: '1E90FF' },
      }
      sheet.getCell('A5', 'B5', 'C5', 'D5', 'E5').alignment = {
        indent: 65
      }
    }
    if (rowCount >= 7) {
      //Row 6
      sheet.getCell('A6', 'B6', 'C6', 'D6', 'E6').font = {
        name: 'Tahoma',
        family: 2,
        size: 9,
        bold: true,
        color: { argb: '1E90FF' },
      }
      sheet.getCell('A6', 'B6', 'C6', 'D6', 'E6').alignment = {
        indent: 65
      }
    }
    ///headers
    this.colArray.map(key => {
      sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }

      sheet.getCell(key).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: {
          argb: "50b0d5"
        },
        bgColor: {
          argb: "50b0d5"
        }
      }
    });
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, this.excelfileName + '.xlsx', true);
    });
  }

  applyChildTableStyles(sheet, mergeRowNumber) {
    sheet.eachRow(function (row, rowNumber) {
      if (rowNumber > 5) {
        row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
          sheet.getCell(cell.address.toString()).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
          sheet.getCell(cell.address.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
          sheet.getCell(cell.address.toString()).font = {
            name: 'Tahoma',
            family: 2,
            size: 9
          }

          if (mergeRowNumber.includes(rowNumber)) {
            sheet.getCell(cell.address.toString()).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'none' },
              right: { style: 'thin' },
            }

            sheet.getCell(cell.address.toString()).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: {
                argb: "D3D3D3"
              },
              bgColor: {
                argb: "D3D3D3"
              }
            }
          }
        });
      }
    });
    return sheet;
  }

  exportVehicleStatuseData(fileName: string, headerText: any, columnKeys: any, columnHeaders: any, jsonDataObj: any) {

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    var newtoday = dd + '/' + mm + '/' + yyyy;

    this.excelfileName = fileName + newtoday;
    this.sName = fileName;
    var workbook = new Excel.Workbook();
    workbook.creator = "AssetTL";
    workbook.lastModifiedBy = "AssetTL";
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })
    var sheet = workbook.getWorksheet(1);
    var data1 = headerText;//["Export data"]
    sheet.addRow(data1);
    var reportDate = ['Report Time : ' + this.commonService.dateConvertToClientFormat(new Date())]
    sheet.addRow(reportDate);
    sheet.getRow(3).values = columnHeaders;
    sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }];
    this.colArray = this.colArrayFunction(columnHeaders);// ['A3', 'B3', 'C3', 'D3', 'E3']
    sheet.addRows(jsonDataObj);
    sheet = this.applyRowStyle(sheet);

    //Row 1
    sheet.getCell('A1', 'B1', 'C1').font = {
      name: 'Tahoma',
      family: 2,
      size: 12,
      bold: true,
      color: { argb: '1E90FF' }
    }

    sheet.getCell('A1', 'B1', 'C1').alignment = {
      indent: 45
    }
    sheet.getCell('A3').border = {
      top: { style: 'double', color: { argb: '1E90FF' } },
      left: { style: 'double', color: { argb: '1E90FF' } },
      bottom: { style: 'double', color: { argb: '1E90FF' } },
      right: { style: 'double', color: { argb: '1E90FF' } }
    };
    //Row 2
    sheet.getCell('A2', 'B2', 'C2').font = {
      name: 'Tahoma',
      family: 2,
      size: 9,
      bold: true,
      color: { argb: '1E90FF' },
    }
    sheet.getCell('A2', 'B2', 'C2').alignment = {
      indent: 65
    }
    this.colArray.map(key => {
      sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }
    });
    //sheet.addRow([this.footer])
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, this.excelfileName + '.xlsx', true);
    });
  }


  exportOrgCustData(fileName: string, headerText: any, columnKeys: any, columnHeaders: any, jsonDataObj: any) {

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    var newtoday = dd + '/' + mm + '/' + yyyy;

    this.excelfileName = fileName + newtoday;
    this.sName = fileName;
    var workbook = new Excel.Workbook();
    workbook.creator = "AssetConnect";
    workbook.lastModifiedBy = "AssetConnect";
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })
    var sheet = workbook.getWorksheet(1);
    var data1 = headerText;//["Export data"]
    sheet.addRow(data1);
    var reportDate = ['Report Time : ' + this.commonService.dateConvertToClientFormat(new Date())]
    sheet.addRow(reportDate);
    sheet.getRow(3).values = columnHeaders;
    sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }];
    this.colArray = this.colArrayFunction(columnHeaders);// ['A3', 'B3', 'C3', 'D3', 'E3']
    sheet.addRows(jsonDataObj);
    sheet = this.applyRowStyle(sheet);

    //Row 1
    sheet.getCell('A1', 'B1', 'C1').font = {
      name: 'Tahoma',
      family: 2,
      size: 12,
      bold: true,
      color: { argb: '1E90FF' }
    }

    sheet.getCell('A1', 'B1', 'C1').alignment = {
      indent: 45
    }
    sheet.getCell('A3').border = {
      top: { style: 'double', color: { argb: '1E90FF' } },
      left: { style: 'double', color: { argb: '1E90FF' } },
      bottom: { style: 'double', color: { argb: '1E90FF' } },
      right: { style: 'double', color: { argb: '1E90FF' } }
    };
    //Row 2
    sheet.getCell('A2', 'B2', 'C2').font = {
      name: 'Tahoma',
      family: 2,
      size: 9,
      bold: true,
      color: { argb: '1E90FF' },
    }
    sheet.getCell('A2', 'B2', 'C2').alignment = {
      indent: 65
    }
    let reportsCnt = 0;
    //**orgname wise Cell mergeing */
    let groupedNamWise = this.groupBy(jsonDataObj, pet => pet.orgname)
    let itemsLength = groupedNamWise.size;
    let groups = jsonDataObj.map(i => i.orgname);
    let uniqueNameGroups = Array.from(new Set(groups));
    var cnt = 4;
    var tCount = 0
    var rCnt = 0;
    var cCnt = 0;
    let incCount = 1;

    for (let i = 0; i < itemsLength; i++) {
      let UName = uniqueNameGroups[i];
      let featureLength = groupedNamWise.get(UName).length - 1;
      let j = incCount++;
      if (i == 0) {
        tCount = cnt + featureLength
        sheet.mergeCells(4, 1, tCount, 1);
        sheet.mergeCells(4, 2, tCount, 2);
        sheet.mergeCells(4, 3, tCount, 3);

        cCnt = tCount + 1;
        //rCnt = cCnt + featureLength ;
      } else {

        rCnt = cCnt + featureLength;
        let k = j - i
        sheet.mergeCells(cCnt, 1, rCnt, 1);
        sheet.mergeCells(cCnt, 2, rCnt, 2);
        sheet.mergeCells(cCnt, 3, rCnt, 3);

        cCnt = rCnt + k;
        // rCnt = rCnt + featureLength + k;
      }
    }

    this.colArray.map(key => {
      sheet.getCell(key).alignment = { vertical: 'top', horizontal: 'left', wrapText: true }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }
    });
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, this.excelfileName + '.xlsx', true);
    });
  }

  exportExcelFile_BillingInvoice(obj) {

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    var newtoday = dd + '/' + mm + '/' + yyyy;

    this.excelfileName = 'Billing Module' + newtoday;
    var workbook = new Excel.Workbook();
    workbook.creator = "AssetConnect";
    workbook.lastModifiedBy = "AssetConnect";
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet('Summary', { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })
    workbook.addWorksheet('SIM', { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })
    workbook.addWorksheet('Device-Jio', { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })
    workbook.addWorksheet('Device-NonJio', { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })
    workbook.addWorksheet('ePOD', { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })
    workbook.addWorksheet('EWB', { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })

    this.getSheetSummary(workbook, obj);
    var columnKeys1 = [
      { key: 'creationDate', width: 20 },
      { key: 'creationTime', width: 20 },
      { key: 'closedDate', width: 20 },
      { key: 'closedTime', width: 20 },
      { key: 'phoneNumber', width: 20 },
      { key: 'origin', width: 20 },
      { key: 'destination', width: 20 },
      { key: 'distance', width: 20 },
      { key: 'distanceTravelled', width: 20 },
      { key: 'consentStatus', width: 20 },
      { key: 'trackingStatus', width: 20 },
      { key: 'transitTime', width: 20 },
      { key: 'expectedPings', width: 20 },
      { key: 'actualNoOfPings', width: 20 },
      { key: 'upTime', width: 20 },
      { key: 'chargedFor', width: 20 }
    ];
    var columnHeaders1 = ["Creation Date", "Creation Time", "Closed Date", "Closed Time", "Phone Number", "Origin", "Destination", "Distance", "Distance Travelled", "Consent Status", "Tracking Status", "Transit Time", "Expected Pings", "Actual Number of Pings", "Uptime %", "Charged for"]
    this.getSheetBillingModules(workbook, 2, columnHeaders1, columnKeys1, obj.SIM);

    var columnKeys2 = [
      { key: 'deviceid', width: 20 },
      { key: 'nooftrips', width: 20 }
    ];
    var columnHeaders2 = ["Device Id", "Number of Trips"]
    this.getSheetBillingModules(workbook, 3, columnHeaders2, columnKeys2, obj.deviceJio);

    var columnKeys3 = [
      { key: 'tripid', width: 20 },
      { key: 'deviceid', width: 20 },
      { key: 'cdate', width: 20 },
      { key: 'ctime', width: 20 },
      { key: 'closedate', width: 20 },
      { key: 'closetime', width: 20 },
      { key: 'phonenumber', width: 20 },
      { key: 'origin', width: 20 },
      { key: 'destination', width: 20 },
      { key: 'trackingstatus', width: 20 },
      { key: 'distancetravel', width: 20 },
      { key: 'transittime', width: 20 }
    ];
    var columnHeaders3 = ["Trip Id/transaction id", "Device ID", "Creation Date", "Creation Time", "Closed Date", "Closed Time", "Phone Number", "Origin", "Destination", "Tracking Status", "Distance Travelled", "Transit Time"]
    this.getSheetBillingModules(workbook, 4, columnHeaders3, columnKeys3, obj.nonDeviceJio);

    var columnKeys4 = [
      { key: 'tripid', width: 20 },
      { key: 'cdate', width: 20 },
      { key: 'ctime', width: 20 },
      { key: 'closedate', width: 20 },
      { key: 'closetime', width: 20 },
      { key: 'origin', width: 20 },
      { key: 'destination', width: 20 },
      { key: 'noofuploads', width: 20 }
    ];
    var columnHeaders4 = ["Trip Id/transaction id", "Creation Date", "Creation Time", "Closed Date", "Closed Time", "Origin", "Destination", "Number Of Uploads"]
    this.getSheetBillingModules(workbook, 5, columnHeaders4, columnKeys4, obj.epod);

    var columnKeys5 = [
      { key: 'tripid', width: 20 },
      { key: 'cdate', width: 20 },
      { key: 'ctime', width: 20 },
      { key: 'closedate', width: 20 },
      { key: 'closetime', width: 20 },
      { key: 'origin', width: 20 },
      { key: 'destination', width: 20 },
      { key: 'ewbnumber', width: 20 },
      { key: 'shippername', width: 20 },
      { key: 'shipperdstin', width: 20 },
      { key: 'transportername', width: 20 },
      { key: 'transportergstin', width: 20 }
    ];
    var columnHeaders5 = ["Trip Id/transaction id", "Creation Date", "Creation Time", "Closed Date", "Closed Time", "Origin", "Destination", "EWB Number", "EWB Expiry", "Shipper Name", "Shipper GSTIN", "Transporter Name", "Transporter GSTIN"]
    this.getSheetBillingModules(workbook, 6, columnHeaders5, columnKeys5, obj.ewb);
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, this.excelfileName + '.xlsx', true);
    });
  }

  getSheetSummary(workbook, obj) {
    var sheet = workbook.getWorksheet(1);
    let summaryobj = obj.summary;
    //sheet.addRow(['Summary']);
    sheet.getRow(1).values = ['SIM'];
    sheet.getRow(2).values = ['Total Number of Trips', 'Total Tracked Trips', 'Rate Ptpd', 'Amount'];
    sheet.getRow(3).values = [summaryobj?.sim?.noOfTrips, summaryobj?.sim?.trackedTrips, summaryobj?.sim?.rate, summaryobj?.sim?.amount];

    var colArray = this.colArrayFunction_BillingModule(4, 2);
    colArray.map(key => {
      sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }
    });

    ///4-- values, 5--empty row

    sheet.getRow(5).values = ['Device - Jio'];
    sheet.getRow(6).values = ['Total Number of Devices', 'Total Number of Trips', 'Rate per device', 'Amount'];
    sheet.getRow(7).values = [summaryobj?.deviceJIO?.noOfDevices, summaryobj?.deviceJIO?.noOfTrips, summaryobj?.deviceJIO?.rate, summaryobj?.deviceJIO?.amount];

    var colArray1 = ['A6', 'B6', 'C6', 'D6']// this.colArrayFunction_BillingModule(4, 6);
    colArray1.map(key => {
      sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }
    });
    ///7-- values, 8--empty row

    sheet.getRow(9).values = ['Device - Non Jio'];
    sheet.getRow(10).values = ['Total Number of Trips', 'Total Tracked Trips', 'Rate Per Tracked Trip', 'Amount'];
    sheet.getRow(11).values = [summaryobj?.deviceNonJIO?.noOfTrips, summaryobj?.deviceNonJIO?.trackedTrips, summaryobj?.deviceNonJIO?.rate, summaryobj?.deviceNonJIO?.amount];
    var colArray2 = this.colArrayFunction_BillingModule(4, 10);
    colArray2.map(key => {
      sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }
    });
    ///11-- values, 12--empty row

    sheet.getRow(13).values = ['EPOD'];
    sheet.getRow(14).values = ['Total Number of Trips', 'Total Uploads', 'Rate per Trip', 'Amount'];
    sheet.getRow(15).values = [summaryobj?.epod?.noOfTrips, summaryobj?.epod?.uploadsCount, summaryobj?.epod?.rate, summaryobj?.epod?.amount];
    var colArray3 = this.colArrayFunction_BillingModule(4, 14);
    colArray3.map(key => {
      sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }
    });
    ///15-- values, 16--empty row

    sheet.getRow(17).values = ['EWB'];
    sheet.getRow(18).values = ['Total Number of Trips', 'Total Transactions', 'Rate per EWB', 'Amount'];
    sheet.getRow(19).values = [summaryobj?.ewb?.noOfTrips, summaryobj?.ewb?.transactions, summaryobj?.ewb?.rate, summaryobj?.ewb?.amount];
    var colArray4 = this.colArrayFunction_BillingModule(4, 18);
    colArray4.map(key => {
      sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }
    });
    ///19-- values, 20--empty row
   
  }


  getSheetBillingModules(workbook, sheetNo, columnHeaders, columnKeys, jsonDataObj) {
    var sheet = workbook.getWorksheet(sheetNo);

    sheet.getRow(1).values = columnHeaders;
    sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }];
    sheet.addRows(jsonDataObj);
    sheet = this.applyRowStyle_BillingModule(sheet);
    let colArray = this.colArrayFunction_BillingModule(columnHeaders.length, 1);
    colArray.map(key => {
      sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      sheet.getCell(key).font = {
        name: 'Tahoma',
        family: 2,
        size: 8,
        bold: true
      }
    });

    // sheet.getCell('A3').border = {
    //   top: { style: 'double', color: { argb: '1E90FF' } },
    //   left: { style: 'double', color: { argb: '1E90FF' } },
    //   bottom: { style: 'double', color: { argb: '1E90FF' } },
    //   right: { style: 'double', color: { argb: '1E90FF' } }
    // };

  }


  applyRowStyle_BillingModule(sheet) {
    sheet.eachRow(function (row, rowNumber) {
      row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
        sheet.getCell(cell.address.toString()).alignment = { wrapText: true, vertical: 'middle', horizontal: 'middle' }
        sheet.getCell(cell.address.toString()).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        sheet.getCell(cell.address.toString()).font = {
          name: 'Tahoma',
          family: 2,
          size: 9
        }
      });
    });
    return sheet;
  }

  colArrayFunction_BillingModule(len: any, val) {
    switch (len - 1) {
      case 1: return ['A' + val, 'B' + val]
      case 2: return ['A' + val, 'B' + val, 'C' + val]
      case 3: return ['A' + val, 'B' + val, 'C' + val, 'D' + val]
      case 4: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val]
      case 5: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val]
      case 6: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val]
      case 7: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val]
      case 8: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val]
      case 9: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val]
      case 10: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val]
      case 11: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val]
      case 12: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val]
      case 13: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val]
      case 14: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val]
      case 15: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val, 'P' + val]
      case 16: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val, 'P' + val, 'Q' + val]
      case 17: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val, 'P' + val, 'Q' + val, 'R' + val]
      case 18: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val, 'P' + val, 'Q' + val, 'R' + val, 'S' + val]
      case 19: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val, 'P' + val, 'Q' + val, 'R' + val, 'S' + val, 'T' + val]
      case 20: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val, 'P' + val, 'Q' + val, 'R' + val, 'S' + val, 'T' + val, 'U' + val]
      case 21: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val, 'P' + val, 'Q' + val, 'R' + val, 'S' + val, 'T' + val, 'U' + val, 'V' + val]
      case 22: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val, 'P' + val, 'Q' + val, 'R' + val, 'S' + val, 'T' + val, 'U' + val, 'V' + val, 'W' + val]
      case 23: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val, 'P' + val, 'Q' + val, 'R' + val, 'S' + val, 'T' + val, 'U' + val, 'V' + val, 'W' + val, 'X' + val]
      case 24: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val, 'P' + val, 'Q' + val, 'R' + val, 'S' + val, 'T' + val, 'U' + val, 'V' + val, 'W' + val, 'X' + val, 'Y' + val]
      case 25: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val, 'P' + val, 'Q' + val, 'R' + val, 'S' + val, 'T' + val, 'U' + val, 'V' + val, 'W' + val, 'X' + val, 'Y' + val, 'Z' + val]
      case 26: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val, 'P' + val, 'Q' + val, 'R' + val, 'S' + val, 'T' + val, 'U' + val, 'V' + val, 'W' + val, 'X' + val, 'Y' + val, 'Z' + val, 'AA' + val]
      case 27: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val, 'P' + val, 'Q' + val, 'R' + val, 'S' + val, 'T' + val, 'U' + val, 'V' + val, 'W' + val, 'X' + val, 'Y' + val, 'Z' + val, 'AA' + val, 'AB' + val]
      case 28: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val, 'P' + val, 'Q' + val, 'R' + val, 'S' + val, 'T' + val, 'U' + val, 'V' + val, 'W' + val, 'X' + val, 'Y' + val, 'Z' + val, 'AA' + val, 'AB' + val, 'AC' + val]
      case 29: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val, 'P' + val, 'Q' + val, 'R' + val, 'S' + val, 'T' + val, 'U' + val, 'V' + val, 'W' + val, 'X' + val, 'Y' + val, 'Z' + val, 'AA' + val, 'AB' + val, 'AC' + val, 'AD' + val]
      case 30: return ['A' + val, 'B' + val, 'C' + val, 'D' + val, 'E' + val, 'F' + val, 'G' + val, 'H' + val, 'I' + val, 'J' + val, 'K' + val, 'L' + val, 'M' + val, 'N' + val, 'O' + val, 'P' + val, 'Q' + val, 'R' + val, 'S' + val, 'T' + val, 'U' + val, 'V' + val, 'W' + val, 'X' + val, 'Y' + val, 'Z' + val, 'AA' + val, 'AB' + val, 'AC' + val, 'AD' + val, 'AE' + val]

    }
  }
  getMulCargoAlertsData(data,alertsData){
    var workbook = new Excel.Workbook();
    workbook.properties.date1904 = true;
    workbook.creator = "AssetConnect";
    workbook.lastModifiedBy = "AssetConnect";
    workbook.created = new Date();
    workbook.modified = new Date();
    const sheet = workbook.addWorksheet('Cargo Trip - Alert Summary', { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: true }] })
    // const sheet = workbook.addWorksheet('My Sheet', {views: [{showGridLines: true}]});
    const rows = [
      [,,,,'Cargo Trip - Alert Summary'],
      [,,,,'Report Time:' + moment(new Date()).format("DD MMM YYYY hh:mm A").toString()],
      [],
      ['Trip Id',data.bookingid,'Vehicle Name',data.vehicle.tagname,'Driver Name',data.driver.name,'Driver MobileNumber',data.driver.mno], // row by array
      ['Vehicle RegNo',data.vehicle.regno],
      ['Origin', data.source.addr,'Destination',data.destination.addr],
      ['Start time', data.tripstarttime,'End Time',data.tripendtime,'trip Type','Cargo Trip'],
      [,,,,,]
    ];
    sheet.addRows(rows);
    sheet.mergeCells('d1:e1')
    sheet.mergeCells('d2:e2')
    sheet.getCell('D6').alignment = { wrapText: true };
    sheet.getCell('D1').font = {
      name: 'Tahoma',
      family: 2,
      size: 12,
      bold: true,
      color:{ argb: 'FF0000FF'}
    };
    sheet.getCell('D2').font = {
      name: 'Tahoma',
      family: 2,
      size: 12,
      bold: true,
      color:{ argb: 'FF0000FF'}
     
    };
    sheet.getCell('A4').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('A5').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('A6').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('A7').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true,
      align:getCenter
    };
    sheet.getCell('C4').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('C6').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('C7').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('E4').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('G4').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('E7').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true,
    };
    sheet.getCell('F4').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
     
    };
    sheet.getCell('F7').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
     
    };
    var columnKeys = [
      { key: 'type', width: 20 },
      { key: 'cat', width: 20 },
      { key: 'time', width: 25 },
      { key: 'lat', width: 20 },
      { key: 'lng', width: 20 },
      { key: 'name', width: 25 },
      { width: 25 }
    ];
    var columnHeaders = ["Alert Type","Alert Category", "Alert Time", "Latitude", "Longitude", "Description"]
    sheet.getRow(9).values = columnHeaders;
    sheet.getCell('E9').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true,
      width:40
    };
    sheet.getRow(9).font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }];
    sheet.addRows(alertsData);
    sheet.getColumn(5).alignment = { vertical: 'bottom', wrapText: true };
    sheet.getColumn(2).alignment = { vertical: 'bottom', wrapText: true };
    sheet.getColumn(6).alignment = { vertical: 'bottom',  wrapText: true };
    sheet.getColumn(7).alignment = { vertical: 'bottom'};
    sheet.getColumn(7).width = 20;
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, 'Cargo Trip - Alert Summary.xlsx', true);

    });
  }

  getMulCargoSimAlertsData(data,alertsData){
 
    alertsData.forEach(function(a) {
      a.time =datePipe.transform(a.time, 'dd/MM/yyyy hh:mm:ss a' , '$1 ');
  });
    
      var workbook = new Excel.Workbook();
      workbook.properties.date1904 = true;
      workbook.creator = "AssetConnect";
      workbook.lastModifiedBy = "AssetConnect";
      workbook.created = new Date();
      workbook.modified = new Date();
      const sheet = workbook.addWorksheet('Cargo Trip - SIM Alert Summary', { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: true }] })
      // const sheet = workbook.addWorksheet('My Sheet', {views: [{showGridLines: true}]});
      const rows = [
        [,,,,'Cargo Trip - SIM Alert Summary'],
        [,,,,'Report Time:' + moment(new Date()).format("DD MMM YYYY hh:mm A").toString()],
        [],
        ['Trip Id',data.bookingid,'Vehicle Name',data.vehicle.tagname,'Driver Name',data.driver.name,'Driver MobileNumber',data.driver.mno], // row by array
        ['Vehicle RegNo',data.vehicle.regno],
        ['Origin', data.source.addr,'Destination',data.destination.addr],
        ['Start time', data.tripstarttime,'End Time',data.tripendtime,'trip Type','Cargo Trip'],
        [,,,,,]
      ];
      sheet.addRows(rows);
      sheet.mergeCells('d1:e1')
      sheet.mergeCells('d2:e2')
      sheet.getCell('D6').alignment = { wrapText: true };
  
      sheet.getCell('D1').font = {
        name: 'Tahoma',
        family: 2,
        size: 12,
        bold: true,
        color:{ argb: 'FF0000FF'}
      };
      sheet.getCell('D2').font = {
        name: 'Tahoma',
        family: 2,
        size: 12,
        bold: true,
        color:{ argb: 'FF0000FF'}
       
      };
      sheet.getCell('A4').font = {
        name: 'Tahoma',
        family: 2,
        size: 10,
        bold: true
      };
      sheet.getCell('A5').font = {
        name: 'Tahoma',
        family: 2,
        size: 10,
        bold: true
      };
      sheet.getCell('A6').font = {
        name: 'Tahoma',
        family: 2,
        size: 10,
        bold: true
      };
      sheet.getCell('A7').font = {
        name: 'Tahoma',
        family: 2,
        size: 10,
        bold: true,
        align:getCenter
      };
      sheet.getCell('C4').font = {
        name: 'Tahoma',
        family: 2,
        size: 10,
        bold: true
      };
      sheet.getCell('C6').font = {
        name: 'Tahoma',
        family: 2,
        size: 10,
        bold: true
      };
      sheet.getCell('C7').font = {
        name: 'Tahoma',
        family: 2,
        size: 10,
        bold: true
      };
      sheet.getCell('E4').font = {
        name: 'Tahoma',
        family: 2,
        size: 10,
        bold: true
      };
      sheet.getCell('G4').font = {
        name: 'Tahoma',
        family: 2,
        size: 10,
        bold: true
      };
      sheet.getCell('E7').font = {
        name: 'Tahoma',
        family: 2,
        size: 10,
        bold: true,
      };
      var columnKeys = [
        { key: 'type', width: 20 },
        { key: 'cat', width: 20 },
        { key: 'time', width: 25 },
        { key: 'lat', width: 20 },
        { key: 'lon', width: 20 },
        { key: 'name', width: 25 }
        //{ key: 'address', width: 25 }
      ];
      var columnHeaders = ["Alert Type","Alert Category", "Alert Time", "Latitude", "Longitude", "Name"]
      sheet.getRow(9).values = columnHeaders;
      sheet.getCell('E9').font = {
        name: 'Tahoma',
        family: 2,
        size: 10,
        bold: true,
        width:40
      };
      sheet.getRow(9).font = {
        name: 'Tahoma',
        family: 2,
        size: 10,
        bold: true
      };
      sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }];
      sheet.addRows(alertsData);
      sheet.getColumn(5).alignment = { vertical: 'middle', wrapText: true };
      sheet.getColumn(2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      sheet.getColumn(6).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      sheet.getColumn(7).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      workbook.xlsx.writeBuffer().then(data => {
        const blob = new Blob([data], { type: this.blobType });
        FileSaver.saveAs(blob, 'Cargo Trip - SIM Alert Summary.xlsx', true);
  
      });
    }
    
    getIgnAlertsData(data,alertsData){

      let dataArray = []
      dataArray = alertsData;
      dataArray.forEach(function(a) {
  
        if(typeof(a.created) == 'number') {
          let created = moment(a.created).format("DD/MM/YYYY hh:mm A").toString();
          a.created = created;
        }
        
    });
      
      let starttime = moment(data.tripStartTime).format("DD/MM/YYYY hh:mm A").toString() //datePipe.transform(data.tripStartTime, 'dd/MM/yyyy hh:mm:ss a' )
      let endtime = moment(data.tripEndTime).format("DD/MM/YYYY hh:mm A").toString() //datePipe.transform(data.tripEndTime, 'dd/MM/yyyy hh:mm:ss a' )
            
      var workbook = new Excel.Workbook();
        workbook.properties.date1904 = true;
        workbook.creator = "AssetConnect";
        workbook.lastModifiedBy = "AssetConnect";
        workbook.created = new Date();
        workbook.modified = new Date();
        const sheet = workbook.addWorksheet('Ignition Trip - Alert Summary', { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: true }] })
        // const sheet = workbook.addWorksheet('My Sheet', {views: [{showGridLines: true}]});
        const rows = [
          [,,,,'Ignition Trip - Alert Summary'],
          [,,,,'Report Time:' + moment(new Date()).format("DD MMM YYYY hh:mm A").toString()],
          [],
          ['Vehicle Name',data.assetName,'Driver Name',data.driverName,'Driver MobileNumber',data.driverMobileNo], // row by array
          ['Vehicle RegNo',data.regNo],
          ['Origin', data.fromAddress,'Destination',data.toAddress],
          ['Start time', starttime,'End Time',endtime,'trip Type','Ignition Trip'],
          [,,,,,]
        ];
        sheet.addRows(rows);
        sheet.mergeCells('d1:e1')
        sheet.mergeCells('d2:e2')
        sheet.getCell('D6').alignment = { wrapText: true };
    
        sheet.getCell('D1').font = {
          name: 'Tahoma',
          family: 2,
          size: 12,
          bold: true,
          color:{ argb: 'FF0000FF'}
        };
        sheet.getCell('D2').font = {
          name: 'Tahoma',
          family: 2,
          size: 12,
          bold: true,
          color:{ argb: 'FF0000FF'}
         
        };
        sheet.getCell('A4').font = {
          name: 'Tahoma',
          family: 2,
          size: 10,
          bold: true
        };
        sheet.getCell('A5').font = {
          name: 'Tahoma',
          family: 2,
          size: 10,
          bold: true
        };
        sheet.getCell('A6').font = {
          name: 'Tahoma',
          family: 2,
          size: 10,
          bold: true
        };
        sheet.getCell('A7').font = {
          name: 'Tahoma',
          family: 2,
          size: 10,
          bold: true,
          align:getCenter
        };
        sheet.getCell('C4').font = {
          name: 'Tahoma',
          family: 2,
          size: 10,
          bold: true
        };
        sheet.getCell('C6').font = {
          name: 'Tahoma',
          family: 2,
          size: 10,
          bold: true
        };
        sheet.getCell('C7').font = {
          name: 'Tahoma',
          family: 2,
          size: 10,
          bold: true
        };
        sheet.getCell('E4').font = {
          name: 'Tahoma',
          family: 2,
          size: 10,
          bold: true
        };
        sheet.getCell('E7').font = {
          name: 'Tahoma',
          family: 2,
          size: 10,
          bold: true
        };
     
        var columnKeys = [
          { key: 'type', width: 20 },
          { key: 'created', width: 25 },
          { key: 'lat', width: 20 },
          { key: 'lon', width: 20 },
          { key: 'name', width: 25 },
          {width: 25 }
          
        ];
        var columnHeaders = ["Alert Type", "Alert Time", "Latitude", "Longitude", "Description"]
        sheet.getRow(9).values = columnHeaders;
        sheet.getCell('E9').font = {
          name: 'Tahoma',
          family: 2,
          size: 10,
          bold: true,
          width:40
        };
        sheet.getRow(9).font = {
          name: 'Tahoma',
          family: 2,
          size: 10,
          bold: true
        };
        sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }];
        sheet.addRows(dataArray);
        sheet.getColumn(5).alignment = { vertical: 'middle', wrapText: true };
        sheet.getColumn(2).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        workbook.xlsx.writeBuffer().then(data => {
          const blob = new Blob([data], { type: this.blobType });
          FileSaver.saveAs(blob, 'Ignition Trip - Alert Summary.xlsx', true);
    
        });
      }

    // exportExcelInstanceFile(fileName: string, headerText: any, columnKeys: any, columnHeaders: any, jsonDataObj: any) {

  //   var today = new Date();
  //   var dd = today.getDate();
  //   var mm = today.getMonth() + 1; //January is 0!
  //   var yyyy = today.getFullYear();
  //   var newtoday = dd + '/' + mm + '/' + yyyy;

  //   this.excelfileName = fileName +' '+ newtoday;
  //   this.sName = fileName;
  //   var workbook = new Excel.Workbook();
  //   workbook.creator = "AssetTL";
  //   workbook.lastModifiedBy = "AssetTL";
  //   workbook.created = new Date();
  //   workbook.modified = new Date();
  //   workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })
  //   var sheet = workbook.getWorksheet(1);
  //   var data1 = headerText;//["Export data"]
  //   sheet.addRow(data1);
  //   var reportDate = ['Report Time : ' + this.commonService.dateConvertToClientFormat(new Date())]
  //   sheet.addRow(reportDate);
  //   sheet.getRow(3).values = columnHeaders;
  //   sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }];
  //   this.colArray = this.colArrayFunction(columnHeaders);// ['A3', 'B3', 'C3', 'D3', 'E3']
  //   sheet.addRows(jsonDataObj);
  //   sheet = this.applyRowStyle(sheet);

  //   //Row 1
  //   sheet.getCell('A1', 'B1', 'C1').font = {
  //     name: 'Tahoma',
  //     family: 2,
  //     size: 12,
  //     bold: true,
  //     color: { argb: '1E90FF' }
  //   }

  //   sheet.getCell('A1', 'B1', 'C1').alignment = {
  //     indent: 45
  //   }
  //   sheet.getCell('A3').border = {
  //     top: { style: 'double', color: { argb: '1E90FF' } },
  //     left: { style: 'double', color: { argb: '1E90FF' } },
  //     bottom: { style: 'double', color: { argb: '1E90FF' } },
  //     right: { style: 'double', color: { argb: '1E90FF' } }
  //   };
  //   //Row 2
  //   sheet.getCell('A2', 'B2', 'C2').font = {
  //     name: 'Tahoma',
  //     family: 2,
  //     size: 9,
  //     bold: true,
  //     color: { argb: '1E90FF' },
  //   }
  //   sheet.getCell('A2', 'B2', 'C2').alignment = {
  //     indent: 65
  //   }
  //   this.colArray.map(key => {
  //     // sheet.getCell(key).fill={
  //     //   type:'gradient',
  //     //   gradient:'angle',
  //     //   degree:0,
  //     //   stops:[
  //     //     {position:0, color:{argb:'#454545'}},
  //     //     {position:0.5, color:{argb:'#454545'}},
  //     //     {position:1, color:{argb:'#454545'}},
  //     //   ]
  //     // }
  //     sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
  //     sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
  //     sheet.getCell(key).font = {
  //       name: 'Tahoma',
  //       family: 2,
  //       size: 8,
  //       bold: true
  //     }
  //   });
  //   //sheet.addRow([this.footer])
  //   workbook.xlsx.writeBuffer().then(data => {
  //     const blob = new Blob([data], { type: this.blobType });
  //     FileSaver.saveAs(blob, this.excelfileName + '.xlsx', true);
  //   });
  // }
  exportExcelInstanceFile(data,alertsData){
    var workbook = new Excel.Workbook();
    workbook.properties.date1904 = true;
    workbook.creator = "AssetConnect";
    workbook.lastModifiedBy = "AssetConnect";
    workbook.created = new Date();
    workbook.modified = new Date();
    const sheet = workbook.addWorksheet('Instance Report', { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: true }] })
    // const sheet = workbook.addWorksheet('My Sheet', {views: [{showGridLines: true}]});
    const rows = [
      [,,,'Instance Report'],
      [,,,'Report Time:' + moment(new Date()).format("DD MMM YYYY hh:mm A").toString()],
      [],
      ['RegNo',data.vehielRegno,'Alert Type', data.alerttype], // row by array
      ['Date Time',datePipe.transform(data.datetime, 'dd/MM/yyyy hh:mm:ss a' ), 'Trip Id',data.tripid],
      ['Latitude',data.lat ,'Longitude', data.lng],
      [,,,,,]
    ];
    sheet.addRows(rows);
    sheet.mergeCells('d1:e1')
    sheet.mergeCells('d2:e2')
    sheet.mergeCells('C1:D1')
    sheet.mergeCells('C2:D2')

    sheet.getCell('D6').alignment = { wrapText: true };
    sheet.getCell('C1').font = {
      name: 'Tahoma',
      family: 2,
      size: 12,
      bold: true,
      color:{ argb: '1E90FF'}
    };
    sheet.getCell('C2').font = {
      name: 'Tahoma',
      family: 1,
      size: 8,
      bold: true,
      color:{ argb: '1E90FF'}
     
    };
    sheet.getCell('A4').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('A5').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('A6').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('A7').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true,
      align:getCenter
    };
    sheet.getCell('C4').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('C5').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('C6').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('C7').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('E4').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('G4').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('E7').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true,
    };
    sheet.getCell('F4').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
     
    };
    sheet.getCell('F7').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
     
    };
    var columnKeys = [
      { key: 'datetime', width: 40 },
      { key: 'latitude', width: 20 },
      { key: 'longitude', width: 20 },
      { key: 'speed', width: 20 },
      { width: 25 }
    ];
    let columnHeaders = ['Date', 'Latitude', 'Longitude', 'Speed'];
    sheet.getRow(9).values = columnHeaders;
    sheet.getCell('E9').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true,
      width:40
    };
    sheet.getRow(9).font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }];
    sheet.addRows(alertsData);
    sheet.getColumn(5).alignment = { vertical: 'bottom', wrapText: true };
    sheet.getColumn(2).alignment = { vertical: 'bottom', wrapText: true };
    sheet.getColumn(6).alignment = { vertical: 'bottom',  wrapText: true };
    sheet.getColumn(7).alignment = { vertical: 'bottom'};
    sheet.getColumn(7).width = 20;
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, 'InstanceReport.xlsx', true);

    });
  }


  exportExcelLogsHistory(headers,logsData){
    var workbook = new Excel.Workbook();
    workbook.properties.date1904 = true;
    workbook.creator = "AssetConnect";
    workbook.lastModifiedBy = "AssetConnect";
    workbook.created = new Date();
    workbook.modified = new Date();
    const sheet = workbook.addWorksheet('Wagon Logs History', { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: true }] })
    const rows = [
      [,,,'Wagon Logs History'],
      [,,,'Report Time:' + moment(new Date()).format("DD MMM YYYY hh:mm A").toString()],
      [],
      ['Device ID',headers.deviceId,], // row by array
      ['Wagon Number',headers.wagonNumber],
      [,,,,,]
    ];
    sheet.addRows(rows);
    sheet.mergeCells('d1:e1')
    sheet.mergeCells('d2:e2')
    sheet.mergeCells('C1:D1')
    sheet.mergeCells('C2:D2')

    sheet.getCell('D6').alignment = { wrapText: true };
    sheet.getCell('C1').font = {
      name: 'Tahoma',
      family: 2,
      size: 12,
      bold: true,
      color:{ argb: '1E90FF'}
    };
    sheet.getCell('C2').font = {
      name: 'Tahoma',
      family: 1,
      size: 8,
      bold: true,
      color:{ argb: '1E90FF'}
     
    };
    sheet.getCell('A4').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('A5').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('A6').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('A7').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true,
      align:getCenter
    };
    sheet.getCell('C4').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('C5').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('C6').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('C7').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('E4').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('G4').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.getCell('E7').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true,
    };
    sheet.getCell('F4').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
     
    };
    sheet.getCell('F7').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
     
    };
    var columnKeys = [
      { key: 'pincount', width: 20 },
      { key: 'dateTime', width: 20 },
      { key: 'latlon', width: 20 },
      { key: 'speed', width: 20 },
      { key: 'batteryRemaining', width: 20 },
      { key: 'operator', width: 20 },
      { key: 'gsmStrength', width: 20 },
      { key: 'locaionfix', width: 20 },
      { key: 'accuracy', width: 20 }
    ];
    var columnHeaders = [
      "Ping Count",
      "Date Timestamp",
      "Lat, Long",
      'Speed(Kmph)',
      'Battery Remaining(%)',
      'Operator',
      'GSM Strength',
      'Location Fix',
      'Accuracy'
    ]
    sheet.getRow(9).values = columnHeaders;
    sheet.getCell('E9').font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true,
      width:40
    };
    sheet.getRow(9).font = {
      name: 'Tahoma',
      family: 2,
      size: 10,
      bold: true
    };
    sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }];
    sheet.addRows(logsData);
    sheet.getColumn(5).alignment = { vertical: 'bottom', wrapText: true };
    sheet.getColumn(2).alignment = { vertical: 'bottom', wrapText: true };
    sheet.getColumn(6).alignment = { vertical: 'bottom',  wrapText: true };
    sheet.getColumn(7).alignment = { vertical: 'bottom'};
    sheet.getColumn(7).width = 20;
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, 'WagonLogsReport.xlsx', true);

    });
  }

}
