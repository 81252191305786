import { Injectable } from '@angular/core';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common';
const datePipe = new DatePipe('en-US');


@Injectable({
  providedIn: 'root'
})
export class ExcelExportFleetService {
  name: string;
  sName: string;
  fileName: string;
  excelfileName: string;
  footer: string = "@2019 kunta naresh"
  blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  colArray = [];
  constructor() {

  }
  applyRowStyle(sheet) {
    sheet.eachRow(function (row, rowNumber) {
      if (rowNumber > 1) {
        row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
          sheet.getCell(cell.address.toString()).alignment = {
            warpText: true,
            vertical: 'middle',
            horizontal: 'middle',
          }
          // sheet.getCell(cell.address.toString()).border = {
          //   top: { style: 'thin' },
          //   left: { style: 'thin' },
          //   bottom: { style: 'thin' },
          //   right: { style: 'thin' },
          // }
          sheet.getCell(cell.address.toString()).font = {
            name: 'Calibri',
            family: 2,
            size: 0
          }
        });
      }
    });
    return sheet;
  }

 
  exportExcelFileWithRange(fileName: string, headerText: any, columnKeys: any, columnHeaders: any, jsonDataObj: any, fromDate?: any, toDate?: any, branchName?: string) {

    var today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    let newtoday = dd + '/' + mm + '/' + yyyy;
    this.excelfileName = fileName + newtoday;
    this.sName = fileName;
    var workbook = new Excel.Workbook();
    workbook.creator = "Fleet Dispatch";
    workbook.lastModifiedBy = "Fleet Dispatch";
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, { views: [{ state: 'frozen', xSplit: 1, activeCell: 'A1', showGridLines: false }] })
    let sheet = workbook.getWorksheet(1);
    let data1 = headerText;//["Export data"]
    sheet.addRow(data1);
    let reportDate = ['Report Date Time' + ': ' + datePipe.transform(new Date(), 'dd/MM/yyyy hh:mm:ss a')]
    let reportRange: any;
    if (fromDate && toDate) {
      reportRange = 'Report Range' + ': ' + fromDate + ' - ' + toDate;
    }
    else if (fromDate) {
      reportRange = 'Report Date' + ': ' + fromDate;
    } else {
      reportRange = 'Report Range' + ': ' + 'NA';
    }
    // if (branchName) {
    //   let branName = 'Branch Name' + ': ' + branchName;
    //   sheet.addRow([branName]);
    // }

    sheet.addRow([reportRange]);
    sheet.addRow(reportDate);
    sheet.addRow();

    //sheet.addRow(reportDate);

    sheet.getRow(5).values = columnHeaders;
    sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }];
    // this.colArray = this.colArrayFunction(columnHeaders);// ['A3', 'B3', 'C3', 'D3', 'E3']
    if (columnKeys.length == 4) {
      this.colArray = ['A5', 'B5', 'C5', 'D5']
    }
    if (columnKeys.length == 5) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5']
    }
    if (columnKeys.length == 6) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5']
    }
    if (columnKeys.length == 7) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5',]
    }
    if (columnKeys.length == 8) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5',]
    }
    if (columnKeys.length == 9) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5']
    }
    if (columnKeys.length == 10) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5']
    }
    if (columnKeys.length == 11) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5']
    }
    if (columnKeys.length == 12) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5']
    }
    if (columnKeys.length == 13) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5']
    }
    if (columnKeys.length == 14) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5']
    }
    if (columnKeys.length == 15) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5']
    }
    if (columnKeys.length == 16) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5','P5']
    }
    if (columnKeys.length == 17) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5','P5','Q5']
    }
    if (columnKeys.length == 18) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5','P5','Q5','R5']
    }
    if (columnKeys.length == 19) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5','P5','Q5','R5','S5']
    }
    if (columnKeys.length == 20) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5','P5','Q5','R5','S5','T5']
    }

    if (columnKeys.length == 21) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5','P5','Q5','R5','S5','T5','U5']
    }
    if (columnKeys.length == 22) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5','P5','Q5','R5','S5','T5','U5','V5']
    }
    if (columnKeys.length == 23) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5','P5','Q5','R5','S5','T5','U5','V5','W5']
    }
    if (columnKeys.length == 24) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5','P5','Q5','R5','S5','T5','U5','V5','W5','X5']
    }
    if (columnKeys.length == 25) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5','P5','Q5','R5','S5','T5','U5','V5','W5','X5','Y5']
    }
    if (columnKeys.length == 26) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5','P5','Q5','R5','S5','T5','U5','V5','W5','X5','Y5','Z5']
    }
    if (columnKeys.length == 27) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5','P5','Q5','R5','S5','T5','U5','V5','W5','X5','Y5','Z5','AA5']
    }
    if (columnKeys.length == 41) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5','P5','Q5','R5','S5','T5','U5','V5','W5','X5','Y5','Z5','AA5','AB5','AC5','AD5','AE5','AF5','AG5','AH5','AI5','AJ5','AK5','AL5','AM5','AN5','AO5']
    }
    if (columnKeys.length == 42) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5','P5','Q5','R5','S5','T5','U5','V5','W5','X5','Y5','Z5','AA5','AB5','AC5','AD5','AE5','AF5','AG5','AH5','AI5','AJ5','AK5','AL5','AM5','AN5','AO5','AP5']
    }
    if (columnKeys.length == 43) {
      this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5','P5','Q5','R5','S5','T5','U5','V5','W5','X5','Y5','Z5','AA5','AB5','AC5','AD5','AE5','AF5','AG5','AH5','AI5','AJ5','AK5','AL5','AM5','AN5','AO5','AP5','AQ5']
    }

    // if (columnKeys.length == 40) {
    //   this.colArray = ['A5', 'B5', 'C5', 'D5', 'E5', 'F5', 'G5', 'H5','I5','J5','K5','L5','M5','N5','O5','P5','Q5','R5','S5','T5','U5','V5','W5','X5','Y5','Z5','AA5','AB5','AC5','AD5','AE5','AF5','AG5','AH5','AI5','AJ5','AK5','AL5','AM5','AN5','AO5']
    // }
 
// alert(columnKeys.length)
    sheet.addRows(jsonDataObj);
    sheet = this.applyRowStyleWithRange(sheet);

    //Row 1
    sheet.getCell('A1', 'B1', 'C1').font = {
      name: 'Calibri',
      family: 2,
      size: 18,
      bold: true,
      color: { argb: '1E90FF' }
    }

    sheet.getCell('A1', 'B1', 'C1').alignment = {
      indent: 60
    }

    //Row 2
    sheet.getCell('A2', 'B2', 'C2').font = {
      name: 'Calibri',
      family: 2,
      size: 9,
      bold: true,
      color: { argb: '1E90FF' },
    }
    sheet.getCell('A2', 'B2', 'C2').alignment = {
      indent: 1
    }
    //Row 3
    sheet.getCell('A3', 'B3', 'C3').font = {
      name: 'Calibri',
      family: 2,
      size: 9,
      bold: true,
      color: { argb: '1E90FF' },
    }
    sheet.getCell('A3', 'B3', 'C3').alignment = {
      indent: 1
    }
    //Row 4
    sheet.getCell('A4', 'B4', 'C4').font = {
      name: 'Calibri',
      family: 2,
      size: 9,
      bold: true,
      color: { argb: '1E90FF' },
    }
    sheet.getCell('A4', 'B4', 'C4').alignment = {
      indent: 1
    }
    //Row 5
    sheet.getCell('A5', 'B5', 'C5').font = {
      name: 'Calibri',
      family: 2,
      size: 9,
      bold: true,
      color: { argb: '1E90FF' },
    }
    sheet.getCell('A5', 'B5', 'C5').alignment = {
      indent: 65
    }
    if (columnHeaders.length > 0) {
      this.colArray.forEach(element => {
        sheet.getCell(element).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '000000' },
          bgColor: { argb: 'ffffff' }
        };
      });
    }
    sheet.getCell('A5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('B5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('C5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }

    sheet.getCell('D5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }

    sheet.getCell('E5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }

    sheet.getCell('F5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }

    sheet.getCell('G5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }

    sheet.getCell('H5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('I5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('J5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('K5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('L5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('M5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('N5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('O5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('P5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('Q5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('R5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('S5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('T5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('U5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('V5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('W5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('X5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('Y5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('Z5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AA5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AB5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AC5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AD5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AE5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AF5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AG5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AH5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AI5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AJ5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AK5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AL5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AM5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AN5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AO5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AP5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('AQ5').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }

    this.colArray.map(key => {
      sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
      sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
      // sheet.getCell(key).font = {
      //   name: 'Calibri',
      //   family: 2,
      //   size: 8,
      //   bold: true
      // }
    });
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, this.excelfileName + '.xlsx', true);
    });
  }

  applyRowStyleWithRange(sheet) {
    sheet.eachRow(function (row, rowNumber) {
      if (rowNumber > 5) {
        row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
          sheet.getCell(cell.address.toString()).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
          sheet.getCell(cell.address.toString()).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          }
          sheet.getCell(cell.address.toString()).font = {
            name: 'Calibri',
            family: 2,
            size: 9
          }
        });
      }
    });
    return sheet;
  }
  exportExcelFile(fileName: string, headerText: any, columnKeys: any, columnHeaders: any, jsonDataObj: any) {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    var newtoday = dd + '/' + mm + '/' + yyyy;
    this.excelfileName = fileName + newtoday;
    this.sName = fileName;
    var workbook = new Excel.Workbook();
    workbook.creator = "Fleet Dispatch";
    workbook.lastModifiedBy = "Fleet Dispatch";
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.addWorksheet(this.sName, {
      views: [{
        state: 'frozen',
        xSplit: 1,
        activeCell: 'A1',
        showGridLines: true
      }]
    });

    var sheet = workbook.getWorksheet(1);
    // var data1 = headerText;
    // sheet.addRow(data1);
    // var reportDate = ['Report Time : ' + datePipe.transform(new Date(), 'dd/MM/yyyy hh:mm:ss a')]
    // sheet.addRow(reportDate);
    sheet.getRow(1).values = columnHeaders;
    sheet.columns = columnKeys; //[{ key: 'col1' ,width:40},{ key: 'col2',width:25 },{ key: 'col3' },{ key: 'col4' },{ key: 'col5' }];
    this.colArray = this.colArrayFunction(columnHeaders);// ['A3', 'B3', 'C3', 'D3', 'E3']
    sheet.addRows(jsonDataObj);
    sheet = this.applyRowStyle(sheet);
    if (columnHeaders.length > 0) {
      this.colArray.forEach(element => {
        sheet.getCell(element).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '000000' },
          bgColor: { argb: 'FF0000' }
        };
      });
      
    }
    sheet.getCell('A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }

   
    sheet.getCell('B1').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('C1').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }

    sheet.getCell('D1').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }

    sheet.getCell('E1').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }

    sheet.getCell('F1').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }

    sheet.getCell('G1').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('H1').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('I1').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('J1').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('K1').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('L1').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('M1').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('N1').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('O1').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }
    sheet.getCell('P1').font = {
      name: 'Calibri',
      family: 2,
      size: 11,
      bold: true,
      color: { argb: 'ffffff' }
    }

    // this.colArray.map(key => {
    //   sheet.getCell(key).alignment = { warpText: true, vertical: 'middle', horizontal: 'middle' }
    //   sheet.getCell(key).border = { right: { style: 'thin' }, top: { style: 'thin' } }
    //   sheet.getCell(key).font = {
    //     name: 'Calibri',
    //     family: 2,
    //     size: 8,
    //     bold: true
    //   }
    // });
    //sheet.addRow([this.footer])
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: this.blobType });
      FileSaver.saveAs(blob, this.excelfileName + '.xlsx', true);
    });
  }



  colArrayFunction(columnHeadersObj: any) {
    switch (columnHeadersObj.length - 1) {
      case 1: return this.colArray = ['A1', 'B1']
      case 2: return this.colArray = ['A1', 'B1', 'C1']
      case 3: return this.colArray = ['A1', 'B1', 'C1', 'D1']
      case 4: return this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1']
      case 5: return this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1']
      case 6: return this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1']
      case 7: return this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1']
      case 8: return this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1']
      case 9: return this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1']
      case 10: return this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1']
      case 11: return this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1']
      case 12: return this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1']
      case 13: return this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1']
      case 14: return this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1', 'O1']
      case 15: return this.colArray = ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1', 'L1', 'M1', 'N1', 'O1', 'P1']
    }
  }
}
