import { Directive ,ElementRef, HostListener, OnInit} from '@angular/core';

@Directive({
  selector: '[ngxNoSpacesAllowedFirst]'
})
export class NoSpacesAllowedFirstDirective {

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];
  constructor(private el: ElementRef) { }
  @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (this.specialKeys.indexOf(event.key) !== -1) {
            return;
        }
        let current: string = this.el.nativeElement.value;
        let next: string = current.concat(event.key);
        if (next.length == 1 && String(next) == " ") {
            event.preventDefault();
          }

        }
        ngOnInit() {
        }

}
