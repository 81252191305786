import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[ngxCheckArrows]'
})
export class CheckArrowsDirective {
  
  constructor(private el: ElementRef, ) { }
  @HostListener('keydown', [ '$event' ])
  onKeyDown(event: KeyboardEvent) {
    

    
    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (event.keyCode == 188 || event.keyCode == 190) {
      event.preventDefault();
      return false;
    }
    return true;


  }

}
