import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[ngxNoNumberAllowedFirst]'
})
export class NoNumberAllowedFirstDirective implements OnInit {

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  //private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home', '-' ];
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End'];
  private regex2: RegExp = new RegExp(/^[0-9]+$/g);

  constructor(private el: ElementRef) {
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // debugger
    if (event.keyCode == 37 || event.keyCode == 36) {
      event.preventDefault();
      return false;
    }

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
    if (next.length > 0 && String(next) == " ") {
      event.preventDefault();
    }
    else if (next && String(next).match(this.regex2)) {
      event.preventDefault();
    }

  }
  ngOnInit() {
  }
}
