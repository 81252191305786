import { Directive, ElementRef, HostListener } from '@angular/core';
import  {JioMotiveService} from '../services/jio-motive.service';

@Directive({
  selector: '[ngxAcceptFirstCharacter]'
})
export class AcceptFirstCharacterDirective {
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-'];
  constructor(private el: ElementRef,private _CommanService:JioMotiveService) {
  }

  private regex: RegExp = this._CommanService.AcceptFirstChar_REGX;

  @HostListener('focusout')
  onFocusOut() {
  
    let current: string = this.el.nativeElement.value;
    if (current != "") {
      if (current && !String(current).match(this.regex)) { 

        this.el.nativeElement.style.borderLeft = "1px solid #f3120e";
      } else {
        this.el.nativeElement.style.borderLeft = "";
      }
    } else {
      this.el.nativeElement.style.borderLeft = "";
    }
  }


  ngOnInit() {
   
  }
}