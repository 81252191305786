import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
    selector: '[ngxCPAlphaNumericWithCommaCheck]'
})
export class CopyPasteAlphaNumericWithCommaDirective {

    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];
    private regex: RegExp = new RegExp(/^[a-zA-Z0-9]+(\,[a-zA-Z0-9]+)*$/g);
    constructor(private el: ElementRef, private model: NgModel) {
    }


    @HostListener('paste', ['$event']) blockPaste(event: ClipboardEvent) {

        let clipboardData = event.clipboardData;
        let pastedText = clipboardData.getData('text/plain').trim();
        if (pastedText.length > 0 && String(pastedText) == " ") {
            event.preventDefault();
        }
        else if (pastedText && !String(pastedText).match(this.regex)) {
            event.preventDefault();
        }
        else if (pastedText && String(pastedText).match(this.regex)) {
            this.model.control.setValue(pastedText);
        }
        return false;
    }
}
