import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-viewpopup',
  templateUrl: './viewpopup.component.html',
  styleUrls: ['./viewpopup.component.scss']
})
export class ViewpopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
