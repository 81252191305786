<div class="col-md-12 p-0">
    <!-- {{minDate}}
    {{maxDate}} -->
    <input type="text" ngxDaterangepickerMd [ranges]="ranges" [(ngModel)]="DateRange" [showCustomRangeLabel]="true"
        class="date-picker-styles form_input_theme" [timePicker]="showTime" [alwaysShowCalendars]="true"
        (datesUpdated)="datesUpdated($event)" (rangeClicked)="rangeClicked($event)" (click)="openDatepicker()"
        [locale]="{applyLabel: 'ok', format: format}" name="dateRange" [opens]="opens" 
        [maxDate]="maxDate" [minDate]="minDate">
    <span class="example-trigger" (click)="openDatepicker()" *ngIf="showCalIcon">
        <i (click)="openDatepicker()" class="fas fa-calendar-alt ngx-daterangepicker-action "></i>
    </span>
</div>