import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[ngxDateTimeFormatCheck]'
})
export class DateTimeFromatCheckDirective {

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Delete'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])

  onKeyDown(event: KeyboardEvent) {
    // 
    let selectionstart: Number = Number(this.el.nativeElement.selectionStart);
  
    if (this.specialKeys.indexOf(event.key) != -1 && selectionstart == 3 || this.specialKeys.indexOf(event.key) != -1 && selectionstart == 6 || this.specialKeys.indexOf(event.key) != -1 && selectionstart == 11 ||
      this.specialKeys.indexOf(event.key) != -1 && selectionstart == 17 ||
      this.specialKeys.indexOf(event.key) != -1 && selectionstart == 14) {
      event.preventDefault();
      return;
    }
    
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    var allowNumerics = [49, 50, 51, 52, 53, 54, 55, 56, 57, 48];
    var slashChar = 191;
    var SpaceChar = 32;
    var colonChar = 186;
    var APChar = [65, 80];
    var MChar = 77;

    var numericPos = [0, 1, 3, 4, 6, 7, 8, 9, 11, 12, 14, 15];
    var slashPos = [2, 5];
    var spacePos = [10, 16];
    var APPos = 17;
    var MPos = 18;
    var colonPos = 13;
    var isValidInput = false;

    var thirtyDays: any = ['04', '06', '09', '11']
    var thirtyOneDays: any = ['01', '03', '05', '07', '08', '10', '12']
    var febMonth: any = ['02']



    // 

    try {
      for (var i = numericPos.length - 1; i >= 0; i--) {
        if (numericPos[i] == selectionstart) {
          allowNumerics.forEach(element => {
            if (element == event.keyCode) {
              isValidInput = true;
            }
          });
          break;
        }
      };

      // 
      for (var i = slashPos.length - 1; i >= 0; i--) {
        if ((slashPos[i] == selectionstart) && (event.keyCode == slashChar)) {
          if (!(this.el.nativeElement.value.charAt(selectionstart) == '/')
            && !(this.el.nativeElement.value.charAt(Number(selectionstart) - 1) == '/')) {
            isValidInput = true;
          }
          break;
        }
      };

      for (var i = spacePos.length - 1; i >= 0; i--) {
        if ((spacePos[i] == selectionstart) && (event.keyCode == SpaceChar)) {
          if (!(this.el.nativeElement.value.charAt(selectionstart) == ' ')
            && !(this.el.nativeElement.value.charAt(Number(selectionstart) - 1) == ' ')) {
            isValidInput = true;
          }
          break;
        }
      };

      if ((colonPos == selectionstart) && (event.keyCode == colonChar)
        && !(this.el.nativeElement.value.charAt(selectionstart) == ':')
        && !(this.el.nativeElement.value.charAt(Number(selectionstart) - 1) == ':')) {
        isValidInput = true;
      }

      if (APPos == selectionstart) {
        APChar.forEach(element => {
          if (element == event.keyCode) {
            isValidInput = true;
          }
        });
      }

      if ((MPos == selectionstart) && (event.keyCode == MChar)
        && !(this.el.nativeElement.value.charAt(Number(selectionstart) - 1) == 'M')) {
        isValidInput = true;
      }

      // 
      // alert(this.el.nativeElement.value + "" + this.el.nativeElement.value.length)
      if (this.el.nativeElement.value.length > 18) {
        isValidInput = false;
      }
      else {

        let current: string = this.el.nativeElement.value;
        var data = String(current).split('/');

        if (data[2].length > 0) {

          var data3 = String(data[2]).split(' ');
          //console.log(data3[0]);

          var result;
          if (Number(data3[0]) % 400 === 0) {
            result = true
          }
          else if (Number(data3[0]) % 100 === 0) {
            result = false;
          }
          else if (Number(data3[0]) % 4 === 0) {
            result = true;
          }
          else {
            result = false;
          }
          //console.log(result);
        }


        if (data[2].length > 0) {
          if (Number(data[1]) < 10 && data[1].length == 1) {
            data[1] = String(0) + String(data[1]);
          }

          if (selectionstart == 0) {
            if (febMonth.includes(data[1])) {
              if (result == true) {
                if (Number(String(event.key) + String(data[0])) < 30) {
                  isValidInput = true;
                } else {
                  isValidInput = false;
                }
              }

              if (result == false) {
                if (Number(String(event.key) + String(data[0])) < 29) {
                  isValidInput = true;
                } else {
                  isValidInput = false;
                }
              }
            }
          }
          if (selectionstart == 1) {
            if (febMonth.includes(data[1])) {
              if (result == true) {
                if (Number(String(data[0]) + String(event.key)) < 30) {
                  isValidInput = true;
                } else {
                  isValidInput = false;
                }
              }

              if (result == false) {
                if (Number(String(data[0]) + String(event.key)) < 29) {
                  isValidInput = true;
                } else {
                  isValidInput = false;
                }
              }
            }
          }
        }

        if (data[1].length > 0) {
          if (Number(data[1]) < 10 && data[1].length == 1) {
            data[1] = String(0) + String(data[1]);
          }
          if (selectionstart == 0) {

            if (thirtyDays.includes(data[1])) {
              if (Number(String(event.key) + String(data[0])) < 31) {
                isValidInput = true;
              } else {
                isValidInput = false;

              }
            }

            if (thirtyOneDays.includes(data[1])) {
              if (Number(String(event.key) + String(data[0])) < 32) {
                isValidInput = true;
              } else {
                isValidInput = false;
              }
            }
          }

          if (selectionstart == 1) {

            if (thirtyDays.includes(data[1])) {
              if (Number(String(data[0]) + String(event.key)) < 31) {
                isValidInput = true;
              } else {
                isValidInput = false;
              }
            }

            if (thirtyOneDays.includes(data[1])) {
              if (Number(String(data[0]) + String(event.key)) < 32) {
                isValidInput = true;
              } else {
                isValidInput = false;
              }
            }

          }
        }


        if (data[1].length > 0) {

          if (Number(data[1]) < 10 && data[1].length == 1) {
            data[1] = String(0) + String(data[1]);
          }

          if (selectionstart == 3) {
            if (Number(String(event.key) + String(data[1])) > 12) {
              isValidInput = false;
            } else {
              isValidInput = true;
            }
          }

          if (selectionstart == 4) {
            if (Number(String(data[1]) + String(event.key)) > 12) {
              isValidInput = false;
            } else {
              isValidInput = true;
            }
          }
        }



        //isValidInput = false;


      }

      if (!isValidInput) {
        event.preventDefault();
        return;
      }
    } catch (e) {
      e = null;
    }
  }


}
