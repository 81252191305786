// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl: 'http://35.192.95.195/jiomotivewebapi/api/',
  apiUrl: 'http://34.71.59.30:4701/api/',
  jioUrl: 'https://api.sit.jvts.net/',
  defaultLat:"17.448294",
  defaultLng:"78.391487",
  mqtthost:"td.sit.jvts.net", // "jm.td.jio.com"
  mqttport:443
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
