<!--  
<app-header></app-header> -->
<!-- 
<div class="divisionHeight">
  <section class="content">-->
    <router-outlet></router-outlet>
 <!--  </section>
</div> -->

<!-- <router-outlet>
  <div *ngIf="showLoader" class="loaderBack">
    <div  class="loader">Loading...</div>
  </div>
</router-outlet> -->

<!-- footer -->
<!-- <app-footer></app-footer> -->