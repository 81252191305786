import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { JioMotiveService } from '../services/jio-motive.service';

@Directive({
  selector: '[ngxStrongPassword]'
})
export class StrongPasswordDirective {
  
  constructor(private el: ElementRef, private _commanService: JioMotiveService) { }
  private Lenregex: RegExp = this._commanService.STRONGPSWD_REGX;
  @HostListener('focusout')
  onFocusOut() {
    
    let current: string = this.el.nativeElement.value;
    if (current != "") {
      if (current && !String(current).match(this.Lenregex)) {

        this.el.nativeElement.style.borderLeft = "1px solid #f3120e";
      } else {
        this.el.nativeElement.style.borderLeft = "";
      }
    }else{
      this.el.nativeElement.style.borderLeft = "";
    } 
  }
  ngOnInit() {
    //
    // Removing SpaceBar by using Id
    var enterPwd : any = document.getElementById('confirmPassword');
    enterPwd.addEventListener('keydown', function (event) {
      if (event.which === 32) {
          event.preventDefault();
      }
  });
  var reEnterPwd : any = document.getElementById('Password');
  reEnterPwd.addEventListener('keydown', function (event) {
      if (event.which === 32) {
          event.preventDefault();
      }
  });
  }
  }
  

